/* eslint-disable no-constant-condition */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Card, Grid, Icon, useMediaQuery } from '@mui/material'
import { ArrowForwardIos, Check, Error, Place } from '@mui/icons-material'
import { CMS_PATH } from '../../../conf/basepath'
import HomeAction from '../../offline/actions/HomeAction'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import AgriAction from '../agri/actions/AgriAction'
import DtoDeclaration from '../agri/dto/enquete/DtoDeclaration'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import ModalEnquete from './ModalEnquete'
import { getDate, getDateAndHour, getDateLetter } from '../../../utils/DateUtil'
import { orderBy } from 'lodash'
import DtoArrest from '../../offline/dto/DtoArrest'
import { TextButton } from '../components/styled/buttons'
import { ACTU_TO_HIDE, LAST_TIME_HOME } from '../../offline/constants/HomeConstants'
import moment from 'moment'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import DtoManagementUnitRestriction from '../referencials/dto/DtoManagementUnitRestriction'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoWaterTurnsSlot from '../agri/dto/DtoWaterTurnsSlot'
import ModalActualities from './ModalActualities'
import { getSettingInt } from '../../../utils/FormUtils'
import DtoDroughtSectorRestriction from '../agri/dto/DtoDroughtSectorRestriction'
import { RESTRICTION_RESOURCE_TYPE, SAMPLE_TYPES } from '../agri/constants/AgriConstants'
import { getSandreLabel } from '../../../utils/StringUtil'
import { mainColor } from '../components/styled/theme'
import { convertToRGB } from '../../../utils/ColorUtil'
import { LightCard } from '../components/styled/grid'
import { isOnline } from '../../../utils/StoreUtils'

const Home = ({ }) => {
    const [openModal, setOpenModal] = useState(false)
    const [actuToHide, setActuToHide] = useState(JSON.parse(localStorage.getItem(ACTU_TO_HIDE)) || [])
    const [openModalInfo, setOpenModalInfo] = useState(false)

    const {
        declaration,
        surveys,
        exploitation,
        actualities,
        managementUnitsRestrictions,
        waterTurnsRestrictions,
        installations,
        applicationSettings,
        cmsEvents,
        droughtSectorsRestrictions,
        managementUnits,
        codesSandre,
    } = useSelector(store => ({
        declaration: store.AgriReducer.declaration,
        surveys: store.AgriReducer.surveys,
        exploitation: store.AgriReducer.exploitation,
        actualities: store.CmsReducer.actualities,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        installations: store.InstallationsReducer.installations,
        applicationSettings: store.HomeReducer.applicationSettings,
        cmsEvents: store.CmsReducer.cmsEvents,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        managementUnits: store.ReferencialReducer.managementUnits,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)

    const dispatch = useDispatch()

    const online = isOnline()

    const navigate = (path) => {
        dispatch(push(path))
        window.scrollTo(0, 0)
    }

    const onNavigateEnquete = () => {
        const updateDeclaration = {
            ...declaration,
            statusCode: declaration.statusCode || 1,
        }
        dispatch(AgriAction.updateDeclaration(updateDeclaration)).then(() => {
            setOpenModal(false)
            navigate('/enquete')
        })
    }

    const idCmsInfo = useMemo(() => getSettingInt(applicationSettings, 'iryquaCmsInformation'), [applicationSettings])
    const idCmsAccueil = useMemo(() => getSettingInt(applicationSettings, 'iryquaCmsFondAccueil'), [applicationSettings])

    const { cmsInfo, cmsHome } = useMemo(() => {
        return {
            cmsInfo: idCmsInfo ? cmsEvents.find((c) => c.id === idCmsInfo) : undefined,
            cmsHome: idCmsAccueil ? cmsEvents.find((c) => c.id === idCmsAccueil) : undefined,
        }
    }, [idCmsInfo, cmsEvents])

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.actualities,
            href: '/home',
        }]))
        localStorage.removeItem(LAST_TIME_HOME)
        localStorage.setItem(LAST_TIME_HOME, moment().valueOf())
    }, [])

    useEffect(() => {
        if (!exploitation.idExploitation && online) {
            dispatch(AgriAction.fetchExploitation())
        }
    }, [online])

    useEffect(() => {
        if (exploitation.idExploitation && online) {
            dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then((find) => {
                if (find && !find.statusCode) {
                    setOpenModal(true)
                }
            })
        }
    }, [exploitation])

    const toggleModal = () => {
        const updateDeclaration = {
            ...declaration,
            statusCode: -1,
        }
        dispatch(AgriAction.updateDeclaration(updateDeclaration)).then(() => {
            setOpenModal(!openModal)
        })
    }

    const enquete = useMemo(() => {
        return surveys.find((s) => s.idSurvey === declaration.idSurvey) || {}
    }, [surveys, declaration])

    const openDocument = (document) => {
        window.open(CMS_PATH + document.name, '_system')
    }

    const getArrestsCards = () => {
        const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
        const allPoints = exploitation?.link_samplings?.map((linkPoint) => {
            return installations.find((i) => i.id === linkPoint.idInstallation) || {}
        }) || []

        const restrictions = (() => {
            if (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS) {
                return droughtSectorsRestrictions.filter((dsR) => allPoints.find((point) => dsR.idSector === point.droughtSector && point.sampleType === dsR.resourceType))
            }
            return managementUnitsRestrictions.filter((ugeR) => allPoints.find((point) => ugeR.managementCode === point.subManagementCode && point.sampleType === ugeR.resourceType)) || managementUnitsRestrictions.filter((ugeR) => allPoints.find((point) => ugeR.managementCode === point.managementCode && point.sampleType === ugeR.resourceType))
        })()

        return restrictions.filter((r) => r.idRestriction !== -1 && !actuToHide.some((rHide) => rHide.idRestriction === r.idRestriction &&
            rHide.resourceType === r.resourceType &&
            (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS ? (rHide.idSector === r.idSector) : (rHide.managementCode === r.managementCode)) &&
            rHide.date > r.updateDate
        )).map((r) => {
            const resourceLabel = (() => {
                if (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS) {
                    return getSandreLabel(codesSandre, 'PREL_AGRI.SECTEUR_SECHERESSE', r.idSector)
                }
                return managementUnits.find((m) => m.managementCode === r.managementCode)?.codeWithLabel
            })()
            const waterTurnsRestriction = waterTurnsRestrictions.find((wtR) => wtR.id === r.idRestriction) || {}
            const actuToSave = {
                ...r,
                waterTurnsRestriction,
                resourceLabel,
                date: moment().valueOf(),
            }
            return {
                date: r.updateDate,
                content: (
                    <Card sx={{ backgroundColor: 'white', marginBottom: '10px', borderRadius: '15px' }}>
                        <Grid container justifyContent='space-between' alignItems='center' sx={{ backgroundColor: `rgba(${convertToRGB(waterTurnsRestriction.color)}, 0.3)`, padding: '16px 16px 0 16px', borderRadius: '15px' }}>
                            <Grid item xs={1}><Place htmlColor={waterTurnsRestriction.color} /></Grid>
                            <Grid item xs={10} className='bold'>{resourceLabel}</Grid>
                            <Grid item xs={1}><Error htmlColor={waterTurnsRestriction.color} /></Grid>
                            <Grid item xs={12} className='bold' sx={{ padding: '18px 0px 8px 0px', fontSize: '12px' }}>{`${getDate(r.updateDate)} - ` + `${waterTurnsRestriction.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial}`}</Grid>
                            <Grid item xs={12} className='bold' sx={{ fontSize: '16px' }}>{`${waterTurnsRestriction.label} - ${waterTurnsRestriction.description}`}</Grid>
                            <Grid item xs={12} sx={{ fontSize: '16px', paddingTop: '8px' }}>{waterTurnsRestriction.comment || ''}</Grid>
                            <Grid item xs={12} sx={{ fontSize: '16px' }}>{r.exemption || ''}</Grid>
                            <Grid item xs={12} container justifyContent='flex-end'>
                                <TextButton
                                    little
                                    bgColor={waterTurnsRestriction.color}
                                    onClick={() => {
                                        localStorage.setItem(ACTU_TO_HIDE, JSON.stringify([ ...actuToHide, actuToSave ]))
                                        setActuToHide([...actuToHide, actuToSave ])
                                    }}
                                    sx={{ width: 'fit-content' }}
                                    startIcon={<Check />}
                                >
                                    {i18n.iUnderstood}
                                </TextButton>
                            </Grid>
                        </Grid>
                    </Card>
                ),
            }
        })
    }

    const getActualitiesCards = () => {
        return actualities.filter((a) => a.status === 1 && (!a.dateDebut || a.dateDebut < moment().valueOf()) && (!a.dateFin || a.dateFin > moment().valueOf())).map((a) => ({
            date: a.dateDebut || a.updateDate,
            content: (
                <LightCard container direction='column' sx={{ marginBottom: '16px' }}>
                    <Grid item className='bold' fontSize={24}>{a.title}</Grid>
                    <Grid item sx={{ fontSize: '14px', marginTop: '10px' }}>{getDateLetter(a.dateDebut || a.updateDate)}</Grid>
                    <div dangerouslySetInnerHTML={{ __html: a.comment || '' }} style={{ width: 'fit-content' }}/>
                    {a.link && a.link !== '' && (
                        <p
                            className='blue-text clickable'
                            onClick={() => window.open(a.link, '_system')}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                wordBreak: 'break-all',
                            }}
                        >
                            {a.link}
                            <Icon>launch</Icon>
                        </p>
                    )}
                    {!!a?.document?.length && (
                        <Grid
                            item
                            onClick={() => openDocument(a.document[0])}
                            container
                            justifyContent='flex-end'
                            alignItems='center'
                            sx={{ color: mainColor }}
                            className='bold'
                        >
                            {i18n.seeDocument}<ArrowForwardIos fontSize='20px' sx={{ marginLeft: '10px' }} />
                        </Grid>
                    )}
                </LightCard>
            )
        }))
    }

    const lgMatches = useMediaQuery((t) => t.breakpoints.up('lg'))
    const arrestsContents = orderBy(getArrestsCards(), 'date', 'desc')

    return (
        <div style={{ padding: '8px 1.5rem', overflow: 'clip' }}>
            {lgMatches && declaration?.idDeclaration && (
                <LightCard sx={{ marginBottom: '16px' }}>
                    <Grid item className='bold' fontSize={24}>{`${i18n.enquete} ${enquete.year}`}</Grid>
                    <Grid item sx={{ fontSize: '14px', marginTop: '10px' }}>
                        La déclaration de vos prélèvements d'eau prévisionnels et réels est maintenant
                        disponible. Vous avez jusqu'au {getDateAndHour(enquete.endDate)} pour renseigner votre dossier.
                    </Grid>
                    <Grid
                        item
                        onClick={onNavigateEnquete}
                        container
                        justifyContent='flex-end'
                        alignItems='center'
                        sx={{ color: mainColor, marginTop: '16px' }}
                        className='bold'
                    >
                        {i18n.doMyDeclaration}<ArrowForwardIos fontSize='20px' sx={{ marginLeft: '10px' }} />
                    </Grid>
                </LightCard>
            )}
            {!lgMatches && (
                <div style={{ position: 'fixed', top: 75, width: 'calc(100% - 3rem)' }}>
                    {arrestsContents.map((a) => (a.content))}
                </div>
            )}
            {!!actualities.length && orderBy(getActualitiesCards(), 'date', 'desc').map((a) => (a.content))}
            {!arrestsContents.length && !actualities.length && (
                <Grid container direction='column' style={{ position: 'fixed', height: '100vh', top: 0, left: 0 }} alignItems='center' justifyContent='center'>
                    <Grid item>{cmsInfo?.document?.length ? <img src={CMS_PATH + cmsInfo.document[0]?.name} alt='home logo' height='70px' /> : ''}</Grid>
                    <Grid item className='bold' sx={{ width: '75%', paddingTop: '25px', textAlign: 'center' }}><div dangerouslySetInnerHTML={{ __html: cmsHome?.comment || '' }} /></Grid>
                </Grid>
            )}
            {openModal && (
                <ModalEnquete
                    open={openModal}
                    toggleModal={toggleModal}
                    onClick={onNavigateEnquete}
                    enquete={enquete}
                />
            )}
            {openModalInfo && cmsInfo && (
                <ModalActualities
                    open={openModalInfo}
                    toggleModal={() => setOpenModalInfo(!openModalInfo)}
                    cms={[cmsInfo]}
                />
            )}
        </div>
    )
}

Home.propTypes = {
    declaration: PropTypes.instanceOf(DtoDeclaration),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    arrests: PropTypes.arrayOf(PropTypes.instanceOf(DtoArrest)),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    surveys: PropTypes.arrayOf(PropTypes.shape({})),
    actualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    managementUnitsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnitRestriction)),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    droughtSectorsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoDroughtSectorRestriction)),
}

export default Home
