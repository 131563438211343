import React, { useEffect, useMemo } from 'react'
import { push } from 'react-router-redux'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { uniq } from 'lodash'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { getSetting, getSettingInt, sieauTooltip } from '../../../utils/FormUtils'
import AgriAction from '../agri/actions/AgriAction'
import HomeAction from '../../offline/actions/HomeAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import CityAction from '../referencials/city/actions/CityAction'
import CmsAction from '../cms/actions/CmsAction'
// import ModalActualities from '../home/ModalActualities'
import { loadWMSProjections } from '../../../utils/mapUtils/CoordinateUtils'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import { mainColor, lightColor } from './styled/theme'
import { AGRI, AGRI_RSEAU } from '../agri/constants/AgriConstants'
import Notification from './Notifications'
import { AccountCircle, ArrowBack, WifiOff } from '@mui/icons-material'
import aquasysLogoPath from '../../../ressources/pictures/favicon.png'
import ToastrAction from './toasters/ToastrAction'
import OfflineAction from '../../offline/actions/OfflineAction'
import { persistor } from '../../../store/AppStore'
import { isOnline } from '../../../utils/StoreUtils'
// import { CMS_PATH } from '../../../conf/basepath'
import AccountAction from '../account/actions/AccountAction'
import { getLogin } from '../../../utils/UserUtils'
import { isMobile } from 'react-device-detect'

const NavBar = () => {
    const {
        title,
        exploitation,
        // accountUser,
        // accountStatistics,
        // actualities,
        // cmsLogo,
        applicationSettings,
        accountHabilitations,
    } = useSelector(store => ({
        title: store.HomeReducer.title,
        exploitation: store.AgriReducer.exploitation,
        // accountUser: store.AccountReducer.accountUser,
        // accountStatistics: store.AccountReducer.accountStatistics,
        // actualities: store.CmsReducer.actualities,
        // cmsLogo: store.HomeReducer.cmsLogo,
        applicationSettings: store.HomeReducer.applicationSettings,
        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const dispatch = useDispatch()

    const hasHabilitation = hab => accountHabilitations.some(h => h.habilitation === hab)

    const online = isOnline()

    useEffect(() => {
        if (online) {
            dispatch(AccountAction.fetchUser(getLogin()))
            dispatch(AgriAction.fetchExploitation())
            dispatch(HomeAction.fetchApplicationSettings())
            dispatch(ReferencialAction.fetchBookmarks())
            dispatch(ReferencialAction.fetchWatersheds())
            dispatch(ReferencialAction.fetchCultures())
            dispatch(ReferencialAction.fetchCulturesFamilies())
            dispatch(ReferencialAction.fetchCodesSandre())
            dispatch(MaterielAction.fetchMatEventsTypes())
            dispatch(ReferencialAction.fetchTanksTypes())
            dispatch(ReferencialAction.fetchContributors())
            dispatch(ReferencialAction.fetchManagementUnitsRestrictions())
            dispatch(AgriAction.fetchDroughtSectorsRestrictions())
            dispatch(InstallationsAction.fetchAllVariousMatTypes())
            dispatch(AgriAction.fetchSurveys())
            dispatch(CmsAction.fetchCmsByCategory(5))
            dispatch(CmsAction.fetchCMSEvents())
            dispatch(InstallationsAction.fetchAllVariousMateriels())
            dispatch(InstallationsAction.fetchVariousMatSituations())
            dispatch(ReferencialAction.fetchManagementUnits())
            dispatch(AgriAction.fetchWaterTurnsRestrictions())
            dispatch(AgriAction.fetchWaterTurnsSlots())
            dispatch(CityAction.fetchCities())
            dispatch(CmsAction.fetchCmsActualities())
        }
        loadWMSProjections()
    }, [])

    const idCmsInfo = useMemo(() => getSettingInt(applicationSettings, 'iryquaCmsInformation'), [applicationSettings])

    useEffect(() => {
        if (online) {
            dispatch(HomeAction.fetchCMSLogo(idCmsInfo))
        }
    }, [idCmsInfo])

    // uncomment for actualities modal
    // useEffect(() => {
    //     const orderedStats = orderBy(accountStatistics.filter((s) => s.eventType === 'LOGIN'), 'statisticDate', 'desc')
    //     const lastConnection = orderedStats.length >= 2 ? orderedStats[1] : orderedStats[0]
    //     const actuNotSeen = !lastConnection ? [...actualities] : compact(actualities.map((c) => {
    //         if (lastConnection.statisticDate < c.updateDate) {
    //             if (c.status === 1 || accountUser.isAdmin === '1') {
    //                 return c
    //             }
    //         }
    //         return null
    //     }))
    //     const actuToShow = orderBy(actuNotSeen, 'updateDate')
    //     setOpenModalActu(!!actuToShow.length)
    // }, [accountUser, accountStatistics, actualities])

    useEffect(() => {
        const applicationName = applicationSettings?.find(({ parameter }) => parameter === 'applicationName') || {}
        if (applicationName.value) {
            document.title = applicationName.value
            $('#favicon').attr('href', getSetting(applicationSettings, 'applicationFavicon') || aquasysLogoPath)
        }
    }, [applicationSettings])

    useEffect(() => {
        const { idExploitation, operatorCode } = exploitation
        const dep = uniq(exploitation?.cities?.map((c) => c.slice(0, 2))).toString()
        if (idExploitation && online) {
            dispatch(AgriAction.fetchWaterTurnsExploitation(idExploitation))
            dispatch(InstallationsAction.fetchInstallationsByExploitationId(idExploitation))
            dispatch(AgriAction.fetchExploitationVolumes(idExploitation))
            dispatch(MaterielAction.fetchMatEventsByExploitation(idExploitation))
            dispatch(ReferencialAction.fetchContributor(operatorCode))
        }
        if (dep && dep !== '' && online) {
            dispatch(HomeAction.fetchArrests(dep))
        }
    }, [exploitation])

    useEffect(() => {
        if (hasHabilitation(AGRI) && hasHabilitation(AGRI_RSEAU) && online) {
            dispatch(AgriAction.fetchRSEauData())
        }
    }, [accountHabilitations])

    useEffect(() => {
        if (!online) {
            if (isMobile) {
                persistor.persist()
            }
            // dispatch(OfflineAction.setForceDisconnected(true))
            ToastrAction.warning(i18n.youAreOffline)
        } else {
            // dispatch(OfflineAction.setForceDisconnected(false))
            dispatch(OfflineAction.synceJournal())
        }
    }, [online])

    const getTitle = (showBackButton) => {
        const { title: pageTitle, subTitle, icon } = (title || [])[0] || {}
        // const titleFormatted = showLogo && cmsLogo?.document?.length && online ? <img src={CMS_PATH + cmsLogo.document[0]?.name} alt='home logo' height='45px' /> : pageTitle
        const offlineIcon = !online && (
            <WifiOff
                fontSize='large'
                className='clickable'
                sx={{ color: mainColor, paddingRight: icon ? '10px' : '' }}
            />
        )
        if (subTitle) {
            return (
                <Grid style={{ fontSize: 21 }} container justifyContent='space-between' alignItems='center'>
                    <Grid item container direction='column' alignItems='stretch'>
                        <Grid item style={{ fontSize: 21, borderBottom: '1px solid white', padding: '0 8 4 0' }} container justifyContent='space-between' alignItems='flex-end'>{pageTitle}{icon}</Grid>
                        <Grid item style={{ fontSize: 14, paddingTop: 10 }}>{subTitle}</Grid>
                    </Grid>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        {offlineIcon}
                        {!showBackButton && (
                            <AccountCircle
                                {...sieauTooltip(i18n.myAccount)}
                                fontSize='large'
                                className='clickable'
                                onClick={() => dispatch(push('/myaccount/preferences'))}
                                sx={{ color: mainColor, paddingLeft: '10px' }}
                            />
                        )}
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid style={{ fontSize: 21 }} container justifyContent='space-between' alignItems='center'>
                <Grid item style={{ fontSize: 21 }}>{pageTitle}</Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                    {offlineIcon}
                    {icon || !showBackButton && (
                        <AccountCircle
                            {...sieauTooltip(i18n.myAccount)}
                            fontSize='large'
                            className='clickable'
                            onClick={() => dispatch(push('/myaccount/preferences'))}
                            sx={{ color: mainColor, paddingLeft: '10px' }}
                        />
                    )}
                </Grid>
            </Grid>
        )
    }

    const back = () => {
        history.back()
        window.scrollTo(0, 0)
    }

    const showBackButton = useMemo(() => {
        return window.location.href.split('#/')[1].split('/').length !== 1 || (window.location.href.split('#/')[1].includes('enquete'))
    }, [window.location.href])

    if (isMobile) {
        return (
            <>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        position: 'fixed',
                        top: '0',
                        height: '70px',
                        backgroundColor: 'white',
                        color: mainColor,
                        fontWeight: 'bold',
                        fontSize: '1.3rem',
                        zIndex: '1000',
                        paddingLeft: '1.5rem',
                        paddingRight: '1.5rem',
                        ...title[0]?.style,
                    }}
                >
                    {showBackButton && (
                        <Grid item xs={1} className='clickable' sx={{ paddingRight: '8', color: lightColor }} onClick={back}>
                            <ArrowBack {...sieauTooltip(i18n.back)} sx={{ height: 25, color: mainColor }} />
                        </Grid>
                    )}
                    <Grid item xs={showBackButton ? 11 : 12}>
                        {getTitle(showBackButton)}
                    </Grid>
                </Grid>
                <Notification />
            </>
        )
    }
    return null
}

export default NavBar