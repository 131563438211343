'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_INSTALLATION,
    RECEIVE_ALL_VARIOUS_MATERIELS,
    RECEIVE_VARIOUS_MAT_SITUATIONS,
    RECEIVE_VARIOUS_MAT_TYPES,
    RECEIVE_ALL_INSTALLATIONS_GEO,
    RECEIVE_INSTALLATIONS,
    RESET_INSTALLATION,
    RECEIVE_MAP_SITUATIONS_STATION,
    RECEIVE_MAP_SITUATIONS_STATION_LAST,
    RECEIVE_LINKED_STATIONS,
    RECEIVE_MAP_SITUATIONS,
    RECEIVE_MAP_SITUATION_STATS,
    RECEIVE_ALL_LINKED_STATIONS,
    RECEIVE_MAP_SITUATIONS_RESULTS,
} from '../constants/InstallationsConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { aquaFetchV2, checkAuth, checkError, genericFetch, genericPromise, getAuthorization } from '../../../../../utils/ActionUtils'
import ToastrAction from '../../../components/toasters/ToastrAction'
import LogAction from '../../../../../utils/log/actions/LogAction'

const InstallationsAction = {
    receiveInstallationsWithGeo(installationsWithGeo) {
        return { type: RECEIVE_ALL_INSTALLATIONS_GEO, installationsWithGeo }
    },

    fetchInstallationsWithGeo() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.installation.getAllWithGeo(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().InstallationsReducer.installationsWithGeo })
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveInstallationsWithGeo(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.installations} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.installations)
                })
        }
    },

    updateInstallation(installation) {
        return () => {
            return fetch(ApplicationConf.installation.path(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(installation),
            })
                .then(checkAuth)
                .then(checkError)
                .then(json => {
                    if (json.update >= 0) {
                        ToastrAction.success(i18n.elementUpdateSuccess)
                    } else {
                        throw new Error()
                    }
                }).catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.installation} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.installation)
                })
        }
    },

    receiveInstallationsByExploitations(installations) {
        return { type: RECEIVE_INSTALLATIONS, installations }
    },

    fetchInstallationsByExploitationId(id) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.installation.getExploitationInstallations(id), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().InstallationsReducer.installations })
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveInstallationsByExploitations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.installations} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.installations)
                })
        }
    },

    receiveInstallation(installation) {
        return { type: RECEIVE_INSTALLATION, installation }
    },

    fetchInstallation(id) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.installation.get(id), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().InstallationsReducer.installations.find((i) => i.id === id) })
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveInstallation(json))
                    return json
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.installation} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.installation)
                })
        }
    },

    receiveAllVariousMateriels(variousMateriels) {
        return { type: RECEIVE_ALL_VARIOUS_MATERIELS, variousMateriels }
    },

    fetchAllVariousMateriels() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.materiel.variousMateriels(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().InstallationsReducer.variousMateriels })
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveAllVariousMateriels(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.variousMateriels} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.variousMateriels)
                })
        }
    },

    receiveAllVariousMatTypes(variousMatTypes) {
        return { type: RECEIVE_VARIOUS_MAT_TYPES, variousMatTypes }
    },

    fetchAllVariousMatTypes() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.materiel.variousMaterielTypes(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().InstallationsReducer.variousMatTypes })
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveAllVariousMatTypes(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.variousMatTypes} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.variousMatTypes)
                })
        }
    },

    receiveVariousMatSituations(variousMatSituations) {
        return { type: RECEIVE_VARIOUS_MAT_SITUATIONS, variousMatSituations }
    },

    fetchVariousMatSituations() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.materiel.variousMaterielsLastSituations(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().InstallationsReducer.variousMatSituations })
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveVariousMatSituations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.variousMateriels} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.variousMateriels)
                })
        }
    },

    promiseMapSituations(stationType) {
        return genericPromise(ApplicationConf.piezometer.mapSituation(stationType))
    },

    fetchMapSituations(stationType) {
        return genericFetch(InstallationsAction.promiseMapSituations(stationType), RECEIVE_MAP_SITUATIONS)
    },

    promiseMapSituationStation(stationType, mapId, stationId) {
        return genericPromise(ApplicationConf.piezometer.mapSituationStation(stationType, mapId, stationId))
    },

    fetchMapSituationsStation(stationType, mapId, stationId) {
        return genericFetch(InstallationsAction.promiseMapSituationStation(stationType, mapId, stationId), RECEIVE_MAP_SITUATIONS_STATION)
    },

    promiseMapSituationStationLast(stationType, stationId, maxDate = null) {
        return genericPromise(ApplicationConf.piezometer.mapSituationStationLast(stationType, stationId, maxDate))
    },

    fetchMapSituationsStationLast(stationType, stationId, maxDate) {
        return genericFetch(InstallationsAction.promiseMapSituationStationLast(stationType, stationId, maxDate), RECEIVE_MAP_SITUATIONS_STATION_LAST)
    },

    receiveLinkedStations(data) {
        return { type: RECEIVE_LINKED_STATIONS, data }
    },
    fetchLinkedStations(code, stationType) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.station.associatedLinksLight(code, stationType), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().InstallationsReducer.linkedStations })
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveLinkedStations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    promiseMapSituationStationStats(stationType, mapId) {
        return genericPromise(ApplicationConf.piezometer.mapSituationStats(stationType, mapId))
    },

    fetchMapSituationsStationStats(stationType, mapId) {
        return genericFetch(InstallationsAction.promiseMapSituationStationStats(stationType, mapId), RECEIVE_MAP_SITUATION_STATS)
    },

    promiseMapSituationResults(stationType, mapSituation, date) {
        return genericPromise(ApplicationConf.piezometer.mapSituationResults(stationType, mapSituation.id, date), 'POST', mapSituation)
    },
    fetchMapSituationsResults(stationType, mapSituation, date) {
        return genericFetch(InstallationsAction.promiseMapSituationResults(stationType, mapSituation, date), RECEIVE_MAP_SITUATIONS_RESULTS)
    },

    promiseAllLinkedStations(ids, stationType) {
        return genericPromise(ApplicationConf.station.getStationsLink(ids, stationType), 'POST', { ids, stationType })
    },

    fetchAllLinkedStations(ids, stationType) {
        return genericFetch(InstallationsAction.promiseAllLinkedStations(ids, stationType), RECEIVE_ALL_LINKED_STATIONS)
    },

    resetInstallation() {
        return { type: RESET_INSTALLATION }
    },
}

export default InstallationsAction
