import React, { createRef, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid, Input } from '@mui/material'
import Paper from '@mui/material/Paper'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { orderBy } from 'lodash'
import ToastrAction from '../components/toasters/ToastrAction'
import { formatPhone } from '../../../utils/StringUtil'
import { getLogin } from '../../../utils/UserUtils'
import HomeAction from '../../offline/actions/HomeAction'
import ContactAction from './actions/ContactAction'
import { mainColor, textColor, veryLightColor } from '../components/styled/theme'
import { getDate, getDateAndHour, getHourAndMinute } from '../../../utils/DateUtil'
import { ArrowCircleLeft } from '@mui/icons-material'
import LoadingCard from '../components/cards/LoadingCard'
import moment from 'moment'
import { getMenuHeight } from '../../../utils/StyleUtils'
import { isOnline } from '../../../utils/StoreUtils'
import OfflineCard from '../components/cards/OfflineCard'
import { isMobile } from 'react-device-detect'

const MessagesApp = () => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [message, setMessage] = useState('')
    const endMessages = createRef()

    const {
        listOfMessages,
        contacts,
        usersLight,
        exploitation,
        contributor,
    } = useSelector(store => ({
        listOfMessages: store.ContactReducer.listOfMessages,
        contacts: store.ContactReducer.contacts,
        usersLight: store.HomeReducer.usersLight,
        exploitation: store.AgriReducer.exploitation,
        contributor: store.ReferencialReducer.contributor,
    }), shallowEqual)

    const scrollToBottom = () => {
        endMessages.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const dispatch = useDispatch()

    const getAllMessages = () => {
        dispatch(ContactAction.getMessagesOf(getLogin())).then(() => {
            setDataLoaded(true)
        })
    }


    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.messaging,
        }]))
        if (isOnline()) {
            if (!contacts.length) {
                dispatch(ContactAction.fetchContacts())
            }
            if (!usersLight.length) {
                dispatch(HomeAction.fetchUsersLight())
            }
            getAllMessages()
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            getAllMessages()
        }, 30000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        scrollToBottom()
    }, [listOfMessages, dataLoaded])


    const handleChangeMessage = (e) => {
        const value = e.target.value
        setMessage(value)
    }

    const sendMessage = () => {
        if (message) {
            const login = getLogin()
            const userMessage = { login, message }
            dispatch(ContactAction.sendMessage(userMessage)).then(() => {
                getAllMessages()
                setMessage('')
            })
        } else {
            ToastrAction.warning(i18n.noMessagesToSend)
        }
    }

    if (!isOnline()) {
        return <OfflineCard />
    }
    if (!dataLoaded) {
        return <LoadingCard />
    }
    const date = getDate(moment().valueOf())
    if (isMobile) {
        return (
            <Grid container sx={{ padding: '10px', paddingBottom: 0 }}>
                <Grid item xs={12} sx={{ paddingBottom: '70px' }}>
                    {orderBy(listOfMessages, 'updateDate').map((m) => {
                        const user = usersLight.find((u) => u.login === m.updateLogin) || {}
                        const contact = contacts.find((c) => c.id === user.contactCode) || {}
                        const phone = contact.phoneTel ? `${formatPhone(contact.phoneTel)} - ` : ''
                        const mobile = contact.mobile ? `${formatPhone(contact.mobile)}` : ''
                        return (
                            <Grid
                                container
                                direction='column'
                                alignItems={m.updateLogin === m.login ? 'flex-end' : 'flex-start'}
                            >
                                <Grid
                                    container
                                    sx={{
                                        backgroundColor: m.updateLogin === m.login ? mainColor : veryLightColor,
                                        width: '80%',
                                        padding: '10px 16px',
                                        margin: '10px 0 10px 0',
                                        borderRadius: '22px',
                                    }}
                                    direction='column'
                                    alignItems='flex-start'
                                    justifyContent='flex-end'
                                >
                                    {m.updateLogin !== m.login && (
                                        <>
                                            <div className='bold padding-bottom-2'>{`${m.updateLogin} ${contact?.name ? `(${contact?.name})` : ''}`}</div>
                                            <div className='padding-bottom-2'>
                                                {contact.phoneTel || contact.mobile ? <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{phone} {mobile}</span> : null}
                                                {contact.email ? <span style={{ fontSize: '14px', fontStyle: 'italic', color: '#5691be' }}>{contact.email}</span> : null}
                                            </div>
                                        </>
                                    )}
                                    <Paper sx={{ background: 'none', boxShadow: 'none', whiteSpace: 'pre-wrap', color: m.updateLogin === m.login ? 'white' : 'black', width: '100%' }}>
                                        {m.message}
                                    </Paper>
                                </Grid>
                                <Paper
                                    sx={{
                                        background: 'none',
                                        boxShadow: 'none',
                                        color: 'grey',
                                        fontSize: '12px',
                                    }}
                                >
                                    {date === getDate(m.updateDate) ? getHourAndMinute(m.updateDate) : getDateAndHour(m.updateDate)}
                                </Paper>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item xs={12} sx={{ position: 'fixed', bottom: getMenuHeight(), width: 'calc(100vw - 20px)' }}>
                    <FormControl
                        style={{
                            textfield: '25ch',
                            width: '100%',
                        }}
                        variant='outlined'
                    >
                        <InputLabel htmlFor='renseignement'>{i18n.anyInformation}</InputLabel>
                        <OutlinedInput
                            id='renseignement'
                            type='text'
                            label={i18n.anyInformation}
                            value={message}
                            onChange={handleChangeMessage}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    sendMessage()
                                }
                            }}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={sendMessage}
                                    >
                                        <ArrowCircleLeft sx={{ fontSize: 45, rotate: '90deg', color: mainColor }} />
                                    </IconButton>
                                </InputAdornment>
                            }
                            sx={{ padding: 0, backgroundColor: veryLightColor }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
    return (
        <Grid item container width='100%' height='calc(100% - 12vh)' padding='4vh 3vw' justifyContent='center' alignItems='flex-start'>
            <Grid container item xs={12} paddingBottom='4vh' color={textColor} sx={{ fontSize: '22px', lineHeight: '28px', letterSpacing: '0.5px' }}>
                {i18n.messages}
            </Grid>
            <Grid container item xs={12} paddingBottom='4vh' color={textColor} sx={{ fontSize: '16px', lineHeight: '24px' }}>
                {contributor.internalIdentifier || contributor.name} - {exploitation.codification}
            </Grid>
            <Grid item container width='65%' height='100%' alignSelf= 'flex-end'>
                <Grid item container xs={12} height='80%' >
                    <Grid container item sx={{ paddingRight: '2vh', height: '100%', overflowY: 'auto', paddingBottom: '2vh' }}>
                        {orderBy(listOfMessages, 'updateDate').map((m) => {
                            const user = usersLight.find((u) => u.login === m.updateLogin) || {}
                            const contact = contacts.find((c) => c.id === user.contactCode) || {}
                            const phone = contact.phoneTel ? `${formatPhone(contact.phoneTel)} - ` : ''
                            const mobile = contact.mobile ? `${formatPhone(contact.mobile)}` : ''
                            return (
                                <Grid
                                    container
                                    direction='column'
                                    alignItems={m.updateLogin === m.login ? 'flex-end' : 'flex-start'}
                                >
                                    <Paper
                                        sx={{
                                            background: 'none',
                                            boxShadow: 'none',
                                            color: 'grey',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {date === getDate(m.updateDate) ? getHourAndMinute(m.updateDate) : getDateAndHour(m.updateDate)}
                                    </Paper>
                                    <Grid
                                        container
                                        sx={{
                                            backgroundColor: m.updateLogin === m.login ? veryLightColor : mainColor,
                                            width: '80%',
                                            padding: '10px 16px',
                                            margin: '10px 0 10px 0',
                                            borderRadius: '22px',
                                        }}
                                        direction='column'
                                        alignItems='flex-start'
                                        justifyContent='flex-end'
                                    >
                                        {m.updateLogin !== m.login && (
                                            <>
                                                <div className='bold padding-bottom-2' style={{ color: '#FFF' }}>{`${m.updateLogin} ${contact?.name ? `(${contact?.name})` : ''}`}</div>
                                                <div className='padding-bottom-2'>
                                                    {contact.phoneTel || contact.mobile ? <span style={{ fontSize: '14px', fontStyle: 'italic', color: '#FFF' }}>{phone} {mobile}<br/></span> : null}
                                                    {contact.email ? <span style={{ fontSize: '14px', fontStyle: 'italic', color: veryLightColor }}>{contact.email}</span> : null}
                                                </div>
                                            </>
                                        )}
                                        <Paper sx={{ background: 'none', boxShadow: 'none', whiteSpace: 'pre-wrap', color: m.updateLogin === m.login ? 'black' : 'white', width: '100%' }}>
                                            {m.message}
                                        </Paper>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <div ref={ endMessages }/>
                    </Grid>
                </Grid>
                <Grid container item sx={{ width: '100%', height: '10%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Input
                        id='renseignement'
                        type='text'
                        placeholder={i18n.anyInformation}
                        value={message}
                        onChange={handleChangeMessage}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                sendMessage()
                            }
                        }}
                        disableUnderline
                        sx={{ padding: '16px 20px', borderRadius: '32px', backgroundColor: veryLightColor, height: '100%', width: 'calc(100% - 56px)' }}
                    />
                    <IconButton
                        aria-label='toggle password visibility'
                        onClick={sendMessage}
                    >
                        <ArrowCircleLeft sx={{ fontSize: '40px', rotate: '90deg', color: mainColor }} />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MessagesApp
