import { isArray } from 'lodash'
import { MEDEAU_TOKEN } from '../constants/HomeConstants'

export default class DtoJournal {
    constructor(obj, id, sync, actionFailed = false) {
        const parsedBody = JSON.parse(obj.params.body)
        const stationType = obj.params.body.stationType || obj.offlineMetadata.stationType
        const formattedBody = isArray(parsedBody) ? parsedBody.map(pb => ({ ...pb, stationType })) : { ...parsedBody, stationType }
        this.id = id
        this.url = obj.url
        this.offlineMetadata = obj.offlineMetadata
        this.useOfflineAction = obj.useOfflineAction
        this.offlineActionType = obj.offlineActionType
        this.params = {
            ...obj.params,
            body: JSON.stringify(formattedBody),
            headers: {
                ...obj.params.headers,
                Authorization: `Bearer ${localStorage.getItem(MEDEAU_TOKEN)}`,
            },
        }
        this.sync = sync
        this.actionFailed = actionFailed
        this.callback = obj.callback
    }
}
