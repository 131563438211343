import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { Divider, Icon } from '@mui/material'
import { CMS_PATH } from '../conf/basepath'
import CmsAction from './online/cms/actions/CmsAction'
import { orderBy } from 'lodash'
import { sieauTooltip } from '../utils/FormUtils'
import LoadingCard from './online/components/cards/LoadingCard'
import { CancelOutlined } from '@mui/icons-material'
import { darkTextColor, mainColor } from './online/components/styled/theme'
import useApplicationSetting from '../utils/customHooks/useApplicationSetting'
import { CMS_STATUS } from './online/cms/constants/CmsConstants'

const Tutoriel = () => {
    const [isLoaded, setIsLoaded] = useState(false)

    const {
        cms
    } = useSelector((store) => ({
        cms: store.CmsReducer.cms
    }))

    const dispatch = useDispatch()

    const tutoCategId = useApplicationSetting('iryquaCategorieCmsTuto') ?? 4

    const tutos = useMemo(() => orderBy(cms, 'id', 'desc').filter((a) => a.status === CMS_STATUS.PUBLISHED && a.idCategory === tutoCategId), [cms])

    useEffect(() => {
        dispatch(CmsAction.fetchCmsByCategory(tutoCategId)).then(() => setIsLoaded(true))
    }, [tutoCategId])

    const openDocument = (document) => {
        window.open(CMS_PATH + document.name, '_system')
    }

    const openWebSite = (link) => {
        if (link) {
            window.open(link, '_blank')
        }
    }

    if (isLoaded) {
        return (
            <Grid container sx={{ height: '100%', overflow: 'hidden' }}>
                {tutos.length ?
                    tutos.map((c) => {
                        return (
                            <Grid key={c.id} item xs={12} sx={{ color: mainColor, padding: '2rem' }}>
                                <Grid container alignItems='center' justifyContent='space-between'>
                                    <Grid item sx={{ fontSize: '22px', lineHeight: '28px' }}>
                                        {c.title}
                                    </Grid>
                                    {!!c.document.length && (
                                        <Icon
                                            className='clickable'
                                            onClick={() => openDocument(c.document[0])}
                                            fontSize='large'
                                            {...sieauTooltip(i18n.openAttachedDoc)}
                                        >
                                                insert_drive_file
                                        </Icon>
                                    )}
                                </Grid>
                                <Grid style={{ backgroundColor: 'white', padding: 4, borderRadius: 10, border: '1px solid', fontSize: '14px', lineHeight: '20px' }} dangerouslySetInnerHTML={{ __html: c.comment || '' }} />
                                <p className='blue-text clickable' onClick={() => openWebSite(c.link)}>{c.link}</p>
                                <Divider style={{ margin: '20px 0' }} />
                            </Grid>
                        )
                    })
                    :
                    (
                        <Grid container direction='column' alignItems='center' justifyContent='center'>
                            <CancelOutlined sx={{ fontSize: '80px', color: mainColor }}/>
                            <Grid item sx={{ fontSize: '24px', color: darkTextColor, lineHeight: '32px', textAlign: 'center', padding: '2rem 8rem' }}>
                                {i18n.noTutoText}
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        )
    }
    return <LoadingCard />
}

export default Tutoriel
