/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { compact, groupBy, orderBy, uniq } from 'lodash'
import { styled, Grid, Divider, Icon } from '@mui/material'
import moment from 'moment'
import { TextButton } from '../components/styled/buttons'
import HomeAction from '../../offline/actions/HomeAction'
import AgriAction from '../agri/actions/AgriAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { formatMilliers } from '../../../utils/StringUtil'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import CmsAction from '../cms/actions/CmsAction'
import { getDate, getMiniDate, getMiniDateNoYear } from '../../../utils/DateUtil'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import { hasValue } from '../../../utils/NumberUtil'
import ModalConso from './ModalConso'
import { mainColor } from '../components/styled/theme'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import { getEvolValue } from '../../../utils/AgriUtils'
import { CHRONICLES_CONSTANTS } from '../agri/constants/AgriConstants'
import { PlayArrow, Star, StarBorder } from '@mui/icons-material'
import LoadingCard from '../components/cards/LoadingCard'
import { isOnline } from '../../../utils/StoreUtils'

const GridItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
    alignSelf: 'center',
})

class PointConso extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: !isOnline(),
            contactsLoaded: !isOnline(),
            idInstallation: parseInt(props.match.params.id),
            point: {},
            installation: {},
            openModalIndex: false,
            chroniclePumpId: null,
            chronicleCounterId: null,
            year: new Date().getFullYear(),
            realConso: null,
        }
    }

    componentDidMount() {
        const { exploitation } = this.props
        const { idInstallation, year } = this.state
        this.props.fetchCmsByCategory(5)
        this.props.fetchContacts().then(() => this.setState({ contactsLoaded: true }))
        this.props.fetchInstallation(idInstallation).then(() => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.fetchVolumes(idInstallation, year))
            } else {
                this.fetchVolumes(idInstallation, year)
            }
        })
        window.scrollTo(0, 0)
    }

    fetchVolumes = (idInstallation, year) => {
        const { exploitation } = this.props
        this.props.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf()).then((res) => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.setState({ realConso: res?.total, dataLoaded: true }))
            } else {
                this.setState({ realConso: res?.total, dataLoaded: true })
            }
            this.props.fetchBookmarks().then(() => this.setTitle(idInstallation))
        })
    }

    setTitle = (idInstallation) => {
        const { installation, bookmarks } = this.props
        const fav = bookmarks.find((b) => b.stationType === 7 && b.identifiant === `${idInstallation}`)
        const bookmark = {
            login: getLogin(),
            identifiant: `${idInstallation}`,
            stationType: 7,
        }
        this.props.setTitle([{
            title: installation.name || (installation.code ? `${i18n.point} ${installation.code || ''}` : ''),
            href: `/consommation/point/${installation.id}`,
            icon: fav ? <Star sx={{ color: mainColor }} onClick={() => this.deleteBookmark(fav, installation)} /> : <StarBorder sx={{ color: mainColor }} onClick={() => this.createBookmark(bookmark, installation)} />,
        }])
    }

    deleteBookmark = (fav) => {
        this.props.deleteBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
        })
    }

    createBookmark = (fav) => {
        this.props.createBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
        })
    }

    componentWillUnmount() {
        this.props.resetInstallation()
    }

    getMateriels = () => {
        const { variousMateriels, exploitation } = this.props
        const { idInstallation } = this.state
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((c) => [...pumpsIds, ...countersIds].includes(c.id) && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    editModalIndex = (type, id) => {
        this.setState({ selectedMaterial: id, consoType: type, openModalIndex: true })
    }

    getColorRealConso = (authorizedVolume, realConso) => {
        if (!hasValue(authorizedVolume) || !hasValue(realConso)) {
            return 'black'
        }
        if (realConso > authorizedVolume) {
            return 'red'
        }
        if (((realConso / authorizedVolume) * 100) >= 80) {
            return 'orange'
        }
        return 'green'
    }

    getChroniclesByType = (chroniclesByType, type, readingCoefficient = 1) => {
        if (chroniclesByType.length) {
            const { year } = this.state
            const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            const currentChronicles = orderedChronicles.filter((c) => moment(c.measureDate).year() >= year)
            const pastChronicles = orderedChronicles.filter((c) => moment(c.measureDate).year() < year)
            return {
                nbLines: currentChronicles.length,
                content: (
                    <>
                        <GridItem item className='bold' xs={3} style={{ textAlign: 'start' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                        </GridItem>
                        <GridItem item className='bold' xs={3} style={{ textAlign: 'start' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.endDate : ''}
                        </GridItem>
                        <GridItem item className='bold' xs={3} style={{ textAlign: 'end' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                        </GridItem>
                        <GridItem item className='bold' xs={3} style={{ textAlign: 'end' }}>
                            {`${type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? 'Cumul' : i18n.evolution} (m3)`}
                        </GridItem>
                        <Grid item xs={12} sx={{ borderBottom: `1px solid ${mainColor}`, margin: '10px 0' }} />
                        {currentChronicles.map((chronicle, i) => {
                            const valueEvol = i !== currentChronicles.length - 1 ? getEvolValue(type, currentChronicles, chronicle, i) : null
                            return (
                                <>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} style={{ textAlign: 'start' }}>
                                        {new Date(chronicle.measureDate).getFullYear() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} style={{ textAlign: 'start' }}>
                                        {chronicle.endDate ? (new Date(chronicle.endDate).getFullYear() === year ? getMiniDateNoYear(chronicle.endDate) : getMiniDate(chronicle.endDate)) : ''}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} style={{ textAlign: 'end' }}>
                                        {formatMilliers(chronicle.value) || 0}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} style={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                        {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                                    </GridItem>
                                </>
                            )
                        })}
                        {pastChronicles.length > 0 && <GridItem item xs={12}>...</GridItem>}
                        {pastChronicles.map((chronicle) => (
                            <>
                                <GridItem item xs={3} style={{ textAlign: 'start' }}>
                                    {getDate(chronicle.measureDate)}
                                </GridItem>
                                <GridItem item xs={3} style={{ textAlign: 'start' }}>
                                    {chronicle.endDate ? getDate(chronicle.endDate) : ''}
                                </GridItem>
                                <GridItem item xs={3} style={{ textAlign: 'end' }}>
                                    {`${formatMilliers(chronicle.value) || 0}${type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? 'm3' : ''}`}
                                </GridItem>
                                <GridItem item xs={3}>
                                    {moment(chronicle.measureDate).year() !== year && <Icon>access_time</Icon>}
                                </GridItem>
                            </>
                        ))}
                        <GridItem item xs={11}>
                            <Divider />
                        </GridItem>
                    </>
                )
            }
        }
        return { nbLines: 0, content: null }
    }

    getChronicles = (groupedChronicles) => {
        const { variousMateriels, exploitation } = this.props
        return compact(groupedChronicles.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (hasValue(variousMat.administrator) && variousMat.administrator !== exploitation.operatorCode) {
                return null
            }
            const indexChronicles = chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX)
            const estimateChronicles = chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM)
            const readingCoefficient = variousMat?.counter?.readingCoefficient || 1
            const indexs = this.getChroniclesByType(indexChronicles, CHRONICLES_CONSTANTS.TYPE_INDEX, readingCoefficient)
            const estimates = this.getChroniclesByType(estimateChronicles, CHRONICLES_CONSTANTS.TYPE_ESTIM)
            return {
                nbLines: indexs.nbLines + estimates.nbLines,
                content: (
                    <Grid
                        item
                        container
                        direction='row'
                        justifyContent='center'
                        className='clickable'
                    >
                        <GridItem item xs={12} className='bold' sx={{ textAlign: 'start', fontSize: '18px', margin: '15px 0' }}>
                            {variousMat.reference}{variousMat?.counter?.informative && ` ${i18n.informative}`}
                        </GridItem>
                        {indexs.content}
                        {estimates.content}
                    </Grid>
                )
            }
        }))
    }

    getEstimConso = (groupedChronicles) => {
        const { year } = this.state
        return groupedChronicles.map((chroniques) => {
            return chroniques.filter((c) => c.measureType === 1 && moment(c.measureDate).year() >= year).reduce((acc, c) => acc + c.value, 0) || 0
        }).reduce((acc, c) => acc + c, 0)
    }

    onChangeYear = (year) => {
        const { idInstallation } = this.state
        const { exploitation } = this.props
        this.props.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf()).then((res) => {
            this.setState({ year, realConso: res?.total })
        })
    }

    getContent = (pumpsIds, countersIds) => {
        const { exploitation, exploitationVolumes } = this.props
        const { idInstallation, year, realConso } = this.state
        const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === idInstallation) || {}
        const volumes = exploitationVolumes.filter((v) => v.idInstallation === idInstallation && v.askedYear === year)
        const askedVolume = volumes.reduce((acc, v) => acc + (v.askedVolume || 0), 0)
        const attributedVolume = volumes.reduce((acc, v) => acc + (v.attributedVolume || 0), 0)
        const authorizedVolume = volumes.reduce((acc, v) => acc + (v.authorizedVolume || 0), 0)
        const pointVolumes = {
            askedVolume,
            attributedVolume,
            authorizedVolume,
        }
        const chroniclesMat = exploitation.link_chronicles.filter(({ idMat }) => [...pumpsIds, ...countersIds].includes(idMat))
        const pointChronicles = chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year &&
            (!linkPoint.startDate || measureDate >= linkPoint.startDate) &&
            (!linkPoint.endDate || measureDate < linkPoint.endDate)
        )
        const pointChroniclePrevYear = orderBy(chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year - 1 &&
            (!linkPoint.startDate || measureDate >= linkPoint.startDate) &&
            (!linkPoint.endDate || measureDate < linkPoint.endDate)
        ), ['measureDate', 'endDate'], 'desc')[0]
        const chroniclesToShow = pointChroniclePrevYear ? [...pointChronicles, pointChroniclePrevYear] : [...pointChronicles]
        const groupedByMat = Object.values(groupBy(chroniclesToShow, 'idMat'))
        const estimConso = groupedByMat.length ? this.getEstimConso(groupedByMat) : null
        const chroniclesValues = this.getChronicles(groupedByMat)
        return (
            <>
                <Grid
                    item
                    xs={12}
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ borderBottom: `1px solid ${mainColor}` }}
                >
                    <Grid item>
                        <TextButton onClick={() => this.onChangeYear(year - 1)} startIcon={<PlayArrow sx={{ rotate: '180deg' }} />}>
                            {year - 1}
                        </TextButton>
                    </Grid>
                    <Grid item>
                        <b style={{ fontSize: '32px', fontWeight: 400 }}>{year}</b>
                    </Grid>
                    <Grid item>
                        <TextButton
                            onClick={() => this.onChangeYear(year + 1)}
                            className={year === new Date().getFullYear() ? 'hide' : ''}
                            endIcon={<PlayArrow />}
                        >
                            {year + 1}
                        </TextButton>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    sx={{ padding: '0 10 0 10', color: mainColor }}
                >
                    <Grid
                        item
                        xs={12}
                        container
                        direction='column'
                        alignItems='center'
                    >
                        {/* <GridItem item xs={7} style={{ textAlign: 'start' }}>
                            {i18n.requestedVolume}
                        </GridItem>
                        <GridVolume item xs={5} className='textRight valueLabel'>
                            {hasValue(pointVolumes.askedVolume) ? `${formatMilliers(pointVolumes.askedVolume)} m3` : '-'}
                        </GridVolume>
                        <GridItem item xs={7} style={{ textAlign: 'start' }}>
                            {i18n.allocatedVolume}
                        </GridItem>
                        <GridVolume item xs={5} className='textRight valueLabel'>
                            {hasValue(pointVolumes.attributedVolume) ? `${formatMilliers(pointVolumes.attributedVolume)} m3` : '-'}
                        </GridVolume> */}
                        <Grid item container justifyContent='space-between' sx={{ padding: '15px 0' }}>
                            <Grid item>
                                {i18n.authorizedVolume}
                            </Grid>
                            <Grid item>
                                {hasValue(pointVolumes.authorizedVolume) ? `${formatMilliers(pointVolumes.authorizedVolume)} m3` : '-'}
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent='space-between' sx={{ padding: '15px 0' }}>
                            <Grid item>
                                {i18n.estimConsumption}
                            </Grid>
                            <Grid item>
                                {hasValue(estimConso) ? `${formatMilliers(estimConso)} m3` : '-'}
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent='space-between' sx={{ padding: '15px 0' }}>
                            <Grid item>
                                {i18n.realConsumption}
                            </Grid>
                            <Grid item sx={{ color: this.getColorRealConso(pointVolumes.authorizedVolume, realConso) }}>
                                {hasValue(realConso) ? `${formatMilliers(realConso)} m3` : '-'}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: 10 }}>
                        {chroniclesValues.map(c => c.content)}
                    </Grid>
                </Grid>
            </>
        )
    }

    onCancelIndex = () => {
        this.setState({
            selectedMaterial: null,
            consoType: null,
            openModalIndex: false,
        })
    }

    onSaveChronicles = (link_chronicles) => {
        const { exploitation } = this.props
        const updateExploitation = {
            ...exploitation,
            link_chronicles,
        }
        this.setState({
            selectedMaterial: null,
            consoType: null,
            openModalIndex: false,
            dataLoaded: false,
        })
        this.props.updateExploitation(updateExploitation).then(() => {
            this.props.fetchExploitation().then(() => this.setState({ dataLoaded: true }))
        })
    }

    render() {
        const {
            openModalIndex,
            point,
            selectedMaterial,
            consoType,
            dataLoaded, contactsLoaded,
        } = this.state
        const { variousMateriels, variousMatSituations, exploitation } = this.props
        if (variousMatSituations.length && variousMateriels.length && dataLoaded && contactsLoaded && exploitation.idExploitation) {
            const { pumpsIds, countersIds, materiels } = this.getMateriels()
            const exploitationMatIds = materiels.map(({ id }) => id)

            return (
                <>
                    <Grid container alignItems='center' justifyContent='space-around' sx={{ padding: '8px 1.5rem' }}>
                        {this.getContent(pumpsIds, countersIds)}
                    </Grid>
                    {openModalIndex && (
                        <ModalConso
                            open={openModalIndex}
                            point={point}
                            pumpsIds={uniq(pumpsIds.filter((id) => exploitationMatIds.includes(id)))}
                            countersIds={uniq(countersIds.filter((id) => exploitationMatIds.includes(id)))}
                            selectedMaterial={selectedMaterial}
                            consoType={consoType}
                            onSave={this.onSaveChronicles}
                            onCancel={this.onCancelIndex}
                            chronicles={exploitation.link_chronicles}
                        />
                    )}
                </>
            )
        }
        return <LoadingCard />
    }
}

PointConso.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    fetchInstallation: PropTypes.func,
    fetchExploitation: PropTypes.func,
    updateExploitation: PropTypes.func,
    fetchCmsByCategory: PropTypes.func,
    calculPointVolumeReal: PropTypes.func,
    resetInstallation: PropTypes.func,
    fetchContacts: PropTypes.func,
    deleteBookmark: PropTypes.func,
    createBookmark: PropTypes.func,
    fetchBookmarks: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    bookmarks: PropTypes.arrayOf(PropTypes.shape({})),
    watersheds: PropTypes.arrayOf(PropTypes.shape({})),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
}

const mapStateToProps = (store) => {
    return {
        installations: store.InstallationsReducer.installations,
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        cms: store.CmsReducer.cms,
        bookmarks: store.ReferencialReducer.bookmarks,
        watersheds: store.ReferencialReducer.watersheds,
        citiesIndex: store.CityReducer.citiesIndex,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    updateExploitation: AgriAction.updateExploitation,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchInstallation: InstallationsAction.fetchInstallation,
    resetInstallation: InstallationsAction.resetInstallation,
    setTitle: HomeAction.setTitle,
    fetchCmsByCategory: CmsAction.fetchCmsByCategory,
    calculPointVolumeReal: AgriAction.calculPointVolumeReal,
    fetchContacts: ContactAction.fetchContacts,
    deleteBookmark: ReferencialAction.deleteBookmark,
    createBookmark: ReferencialAction.createBookmark,
    fetchBookmarks: ReferencialAction.fetchBookmarks,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointConso)
