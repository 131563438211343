import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import CmsAction from './online/cms/actions/CmsAction'
import LoadingCard from './online/components/cards/LoadingCard'
import { textColor } from './online/components/styled/theme'
import { LightCard } from './online/components/styled/grid'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { sortBy } from 'lodash'

const AboutDesktop = () => {
    const [cmsLoaded, setCmsLoaded] = useState(false)

    const {
        cmsEvents,
    } = useSelector(store => ({
        cmsEvents: store.CmsReducer.cmsEvents,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!cmsEvents.length) {
            dispatch(CmsAction.fetchCMSEvents()).then(() => {
                setCmsLoaded(true)
            })
        } else {
            setCmsLoaded(true)
        }
    }, [])

    const showCMS = (category) => {
        const filteredCms = sortBy(cmsEvents.filter((cms) => cms.idCategory === category), ['id'])

        return filteredCms.map((cms) => {
            return (
                <LightCard key={cms.id} item xs={12}>
                    <Grid item fontSize={22} sx={{ paddingBottom: '8px' }}>{cms.title}</Grid>
                    <div dangerouslySetInnerHTML={{ __html: cms.comment || '' }} />
                </LightCard>
            )
        })
    }

    if (!cmsLoaded) {
        return <LoadingCard />
    }

    return (
        <Grid
            sx={{
                padding: '5vh',
                maxHeight: 'calc(100% - 4vh)',
                width: '100%',
            }}
        >
            <Grid container item paddingBottom='2.5vh' alignItems='center' justifyContent='flex-start' color={textColor}>
                <Grid item
                    sx={{ paddingRight: '1.5vw' }}
                    className='clickable'
                    onClick={() => window.history.back()}
                >
                    <KeyboardArrowLeft sx={{ fontSize: '30px' }}/>
                </Grid>
                <Grid item sx={{ fontSize: '22px', lineHeight: '28px' }} >{i18n.cgu}</Grid>
            </Grid>
            <Grid
                container
                rowGap='2vh'
                sx={{
                    height: '100%',
                    overflowY: 'auto',
                }}
            >
                {showCMS(1)}
                {showCMS(3)}
            </Grid>
        </Grid>

    )
}

export default AboutDesktop
