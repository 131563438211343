import {
    FORCE_DISCONNECTED,
    ADD_JOURNAL,
    REMOVE_JOURNAL,
    REMOVE_ALL_JOURNAL,
    ADD_METADATA,
    CLEAR_METADATA,
    JOURNAL_SYNC,
    UPDATE_JOURNAL,
} from '../constants/OfflineConstant'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../online/components/toasters/ToastrAction'
import LogAction from '../../../utils/log/actions/LogAction'
import { aquaFetchV2, getAuthorization } from '../../../utils/ActionUtils'
import { persistor } from '../../../store/AppStore'
import { isMobile } from 'react-device-detect'

const OfflineAction = {
    synceJournal: () => (dispatch, getState) => {
        const actions = getState().OfflineReducer.journal
        const actionsToSync = actions.filter(o => o.sync !== true)
        const actionsFailed = []
        if (actionsToSync.length >= 1) {
            const promises = actionsToSync.map(action => {
                if (action.useOfflineAction) {
                    return OfflineAction.offlineFunction(action, dispatch)
                } return aquaFetchV2(action.url, { ...action.params, headers: getAuthorization() }, {
                    printErr: false,
                    crashOnOffline: true,
                    wait: false,
                    callback: action.callback,
                }).catch(err => {
                    LogAction.logError(err.message)
                    actionsFailed.push(action)
                })
            })
            return Promise.all(promises)
                .then(() => {
                    dispatch(OfflineAction.journalSync(actionsFailed))
                    ToastrAction.success(`${i18n.synchronisationSuccess} : ${actionsFailed.length} ${i18n.errors}`)
                })
        }
        return null
    },

    offlineFunction({ offlineMetadata, offlineActionType }, dispatch) {
        console.log(offlineMetadata, dispatch)
        switch (offlineActionType) {
            // case ADD_CONTACT_STATION_ACCESSIBILITY: dispatch(ReferentialAction.addContactAccessibility(offlineMetadata.contact, offlineMetadata.links,
            //     offlineMetadata.index, offlineMetadata.stationType))
            //     break
            // case ADD_CONTACT_STATION_CONTRIBUTOR: dispatch(ReferentialAction.addContributorLinkContact(offlineMetadata.contact, offlineMetadata.links,
            //     offlineMetadata.index, offlineMetadata.stationType))
            //     break
            // case ADD_CONTRIBUTOR_STATION_CONTRIBUTOR: dispatch(ReferentialAction.addContributorLinkContributor(offlineMetadata.contributor, offlineMetadata.links,
            //     offlineMetadata.index, offlineMetadata.stationType))
            //     break
            // case ADD_CONTACT_CONTRIBUTOR_STATION_CONTRIBUTOR: dispatch(ReferentialAction.addContributorLinkContactAndContributor(offlineMetadata.contact,
            //     offlineMetadata.contributor, offlineMetadata.links, offlineMetadata.index, offlineMetadata.stationType))
            //     break
            default:
                break
        }
    },

    receiveForceDisconnected(forceDisconnected) {
        return { type: FORCE_DISCONNECTED, forceDisconnected }
    },

    setForceDisconnected(forceDisconnected) {
        return (dispatch) => {
            dispatch(OfflineAction.receiveForceDisconnected(forceDisconnected))
        }
    },

    journalSync(actionsFailed) {
        return { type: JOURNAL_SYNC, actionsFailed }
    },

    addJournal(journal) {
        return { type: ADD_JOURNAL, journal }
    },

    updateJournal(journal) {
        return { type: UPDATE_JOURNAL, journal }
    },

    removeJournal(journalId) {
        return { type: REMOVE_JOURNAL, journalId }
    },
    removeAllJournal() {
        return { type: REMOVE_ALL_JOURNAL }
    },
    addMetadata(metadata) {
        return { type: ADD_METADATA, metadata }
    },
    clearMetadata() {
        return { type: CLEAR_METADATA }
    },
    purgePersistor: () => {
        if (isMobile) {
            persistor.pause()
            persistor.flush().then(() => persistor.purge())
        }
    },
}

export default OfflineAction
