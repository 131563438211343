export const mainColor = '#004A77' // '#005078'
export const textColor = '#003355'
export const darkTextColor = '#001D35'
export const darkColor = '#002D41'
export const secondaryColor = '#1964A8'
export const menuColor = '#C2E7FF'
export const lightColor = '#00C9FF'
export const lighterColor = '#D6F6FF'
export const veryLightColor = '#EFF9FF'
export const screenColor = '#FFF'
export const mainBlue = '#3f51b5'
export const mainRed = '#f32525'
export const darkRed = '#B3261E'
export const mainGrey = '#e5e3e3'
export const managerBlue = '#161832'
export const otherBlue = '#00639B'
export const notifBlue = '#5AB3F0'
export const lightOrange = '#FFC03E'
export const lightRed = '#FFD8E4'
export const lightGrey = '#001D3550'
export const veryLightGrey = '#001D3515'
export const lightGreen = '#B8F397'
export const toDoOrange = '#FB8E00'
export const ongoingWhite = '#FFFBE9'
export const validateGreen = '#B8F397'
export const accordionSeparatorGrey = '#D3DBE0'