import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Divider, Icon, LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import { CMS_PATH } from '../conf/basepath'
import { isRunningApp } from '../utils/LocalStorageUtils'
import CmsAction from './online/cms/actions/CmsAction'
import DtoCMSEvent from './online/cms/dto/DtoCMSEvent'
import HomeAction from './offline/actions/HomeAction'
import { orderBy } from 'lodash'
import { sieauTooltip } from '../utils/FormUtils'
import { isOnline } from '../utils/StoreUtils'

const styles = () => ({
    div: {
        padding: '35px',
    },
    text: {
        fontSize: '16px',
        boxShadow: 'none',
        textAlign: 'justify',
        width: '100%',
        border: 'none',
        font: 'unset',
        resize: 'none',
        color: 'black',
        opacity: 'unset',
    },
})

class Tutoriel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cmsLoaded: !isOnline(),
        }
    }

    componentDidMount() {
        this.props.fetchCmsByCategory(4).then(() => {
            this.setState({ cmsLoaded: true })
        })
        this.props.setTitle([
            {
                title: i18n.tuto,
                href: '/tutoriel',
            },
        ])
    }

    openDocument = (document) => {
        window.open(CMS_PATH + document.name, '_system')
    }

    openWebSite = (link) => {
        if (!isRunningApp() && link) {
            window.open(link, '_blank')
        }
    }

    showTutos = () => {
        const { cms = [] } = this.props
        return orderBy(cms, 'id', 'desc').filter((a) => a.status === 1).map((c) => {
            return (
                <Grid key={c.id} item xs={12}>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        <h2 id='actu-title'>
                            {c.title}
                        </h2>
                        {!!c.document.length && (
                            <Icon
                                className='clickable'
                                onClick={() => this.openDocument(c.document[0])}
                                fontSize='large'
                                {...sieauTooltip('Ouvrir le document joint')}
                            >
                                insert_drive_file
                            </Icon>
                        )}
                    </Grid>
                    <div style={{ backgroundColor: 'white', padding: 4, borderRadius: 10, border: '1px solid' }} dangerouslySetInnerHTML={{ __html: c.comment || '' }} />
                    <p className='blue-text' onClick={() => this.openWebSite(c.link)}>{c.link}</p>
                    <Divider style={{ margin: '20px 0' }} />
                </Grid>
            )
        })
    }

    render() {
        const { cmsLoaded } = this.state
        const { classes } = this.props

        if (cmsLoaded) {
            return (
                <div className={classes.div}>
                    <Grid container spacing={3}>
                        {this.showTutos()}
                    </Grid>
                </div>
            )
        }
        return <LinearProgress />
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchCmsByCategory: CmsAction.fetchCmsByCategory,
}

const mapStateToProps = (store) => {
    return {
        cms: store.CmsReducer.cms,
    }
}

Tutoriel.propTypes = {
    fetchCmsByCategory: PropTypes.func,
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    setTitle: PropTypes.func,
    classes: PropTypes.instanceOf(PropTypes.shape({})),
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Tutoriel))
