import React from 'react'
import { CircularProgress, Grid } from '@mui/material'
import { mainColor } from '../styled/theme'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'

const LoadingCard = ({ sx, progress, ...otherProps }) => {
    if (isMobile) {
        return (
            <Grid container direction='column' sx={{ position: 'fixed', height: '100vh', top: 0, left: 0 }} alignItems='center' justifyContent='center'>
                <CircularProgress sx={{ color: mainColor }} />
            </Grid>
        )
    }
    return (
        <Grid item sx={{ height: '100%', ...sx }} container alignItems='center' justifyContent='center'>
            <CircularProgress variant={progress ? 'determinate' : 'indeterminate'} value={progress} sx={{ color: mainColor }} { ...otherProps }/>
        </Grid>
    )
}

LoadingCard.propTypes = {
    sx: PropTypes.shape({}),
    progress: PropTypes.number,
}

export default LoadingCard