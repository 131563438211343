/* eslint-disable max-nested-callbacks */
/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { styled, Grid, LinearProgress, Divider, Icon } from '@mui/material'
import { compact, groupBy, keys, orderBy, uniq, uniqBy } from 'lodash'
import { MainButton, OrangeButton } from '../../components/styled/buttons'
import HomeAction from '../../../offline/actions/HomeAction'
import DtoDeclaration from '../../agri/dto/enquete/DtoDeclaration'
import DtoSamplingPointDecla from '../../agri/dto/exploitation/DtoSamplingPointDecla'
import DtoCulture from '../../referencials/dto/DtoCulture'
import ReferencialAction from '../../referencials/actions/ReferencialAction'
import AgriAction from '../../agri/actions/AgriAction'
import DtoInstallation from '../../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../../referencials/installations/actions/InstallationsAction'
import DtoUsageCategory from '../../referencials/dto/DtoUsageCategory'
import Accordeon from '../../components/Accordeon'
import CmsAction from '../../cms/actions/CmsAction'
import DtoExploitation from '../../agri/dto/exploitation/DtoExploitation'
import { formatMilliers } from '../../../../utils/StringUtil'
import DtoVariousMatSituation from '../../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../../referencials/installations/dto/DtoVariousMateriel'
import { isLargeWindow } from '../../../../utils/LocalStorageUtils'
import { getMiniDate, getMiniDateNoYear } from '../../../../utils/DateUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import { getSetting } from '../../../../utils/FormUtils'
import DtoEnquete from '../../agri/dto/enquete/DtoEnquete'
import ModalMatEvent from '../../consommation/ModalMatEvent'
import MaterielAction from '../../referencials/materiels/actions/MaterielAction'
import DtoMatEvent from '../../referencials/materiels/dto/DtoMatEvent'
import DtoMatEventsType from '../../referencials/materiels/dto/DtoMatEventsType'
import { HelpTooltip } from '../../components/styled/texts'
import ModalIndex from './modals/ModalIndex'
import ModalUsage from './modals/ModalUsage'
import { getEvolValue } from '../../../../utils/AgriUtils'
import StyledCard from '../../components/cards/StyledCard'
import { CHRONICLES_CONSTANTS } from '../../agri/constants/AgriConstants'
import moment from 'moment'

const ChronicleItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
})

const TYPE_ESTIM = 1
const TYPE_INDEX = 2

class PointUsages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            real: props?.match?.params?.type === 'reel',
            idInstallation: parseInt(props?.match?.params?.id),
            declaration: {},
            openModalUsage: false,
            usageToUpdate: false,
            point: {},
            installation: {},
            usages: [],
            usagesCurrentYear: [],
            dataLoaded: false,
            declarationLoaded: false,
            openModalIndex: false,
            selectedMaterial: null,
            consoType: null,
            readMode: false,
            openModalEvent: false,
            selectedEvent: null,
        }
    }

    componentDidMount() {
        this.props.fetchCmsByCategory(5)
        this.props.fetchCulturesFamilies().then(() => {
            this.props.fetchCultures().then(() => {
                this.props.fetchModesIrrigations().then(() => {
                    // eslint-disable-next-line max-nested-callbacks
                    this.props.fetchUsages().then(() => {
                        this.setState({ dataLoaded: true })
                    })
                })
            })
        })
        const { exploitation } = this.props
        if (!exploitation.idExploitation) {
            this.props.fetchExploitation().then(() => {
                this.setDeclaration()
            })
        } else {
            this.setDeclaration()
        }
        window.scrollTo(0, 0)
    }

    componentWillUnmount() {
        this.props.resetInstallation()
    }

    getMateriels = () => {
        const { declaration, variousMateriels, exploitation } = this.props
        const { idInstallation } = this.state
        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === idInstallation).link_equipments || []
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode !== 'd')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')
        const deletedCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode === 'd')
        const exploitationPumps = declaration.link_exploitationMateriel.filter((m) => m.siteType === 7 &&
            m.siteCode === idInstallation &&
            !deletedMats.find((mLink) => m.idVarious === mLink.idMat)
        ) || []
        const mergedPumpsIds = uniqBy([
            ...exploitationPumps.map((m) => m.idVarious),
            ...newMats.map((m) => m.idMat)
        ], obj => obj)
        const exploitationCountersId = compact(mergedPumpsIds.flatMap((pumpId) => {
            const pumpSitu = declaration.link_exploitationMateriel.filter((situ) => situ.siteType === 8 &&
                situ.siteCode === pumpId &&
                !deletedCounters.find((mLink) => pumpId === mLink.idMat)
            )
            if (pumpSitu) {
                return pumpSitu.map((s) => s.idVarious)
            }
            return null
        }))
        const newCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode !== 'd' && mergedPumpsIds.includes(mLink.idMat))
        const mergedCountersIds = uniqBy([
            ...exploitationCountersId,
            ...newCounters.map((m) => m.idMatAttachment)
        ], obj => obj)
        const materiels = variousMateriels.filter((m) => [...mergedPumpsIds, ...mergedCountersIds].includes(m.id) &&
            (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode)
        )
        return { pumpsIds: mergedPumpsIds, countersIds: mergedCountersIds, materiels }
    }

    setDeclaration = () => {
        const { exploitation, survey } = this.props
        const { real, idInstallation } = this.state
        this.props.fetchDeclarationByExploitationId(exploitation.idExploitation).then(() => {
            const { declaration } = this.props
            if (declaration.idDeclaration) {
                this.props.fetchSurvey(declaration.idSurvey).then(() => {
                    this.setState({ declaration })
                    this.props.fetchInstallation(idInstallation).then(() => {
                        const { installation } = this.props
                        const point = declaration ? declaration.link_declarationInstallation.find((p) => p.idInstallation === idInstallation) : new DtoSamplingPointDecla({})
                        this.setState({
                            installation,
                            point,
                            usages: point.link_usages,
                            usagesCurrentYear: point.link_usagesCurrent || [],
                            readMode: declaration.statusCode >= 4 || survey.statusCode === 3,
                            declarationLoaded: true,
                        })
                        this.props.setTitle([
                            {
                                title: `Point ${installation.code || ''}${isLargeWindow() && installation.location ? ` - ${installation.location}` : ''}`,
                                subTitle: real ? i18n.currentUsages : i18n.prevUsages,
                                href: `/enquete/point/${installation.id}`,
                            },
                        ])
                    })
                })
            } else {
                this.props.push('/home')
            }
        })
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    onSaveUsage = (usages) => {
        const { declaration, point, real } = this.state
        const newPoints = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        if (real) {
            newPoints.push({
                ...point,
                link_usagesCurrent: usages,
            })
        } else {
            newPoints.push({
                ...point,
                link_usages: usages,
            })
        }
        const updateDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: newPoints,
        }
        this.setState({
            usages,
            declaration: updateDeclaration,
            declarationLoaded: false
        })
        this.props.updateDeclaration(updateDeclaration).then(() => {
            this.setDeclaration()
        })
        this.toggleState('openModalUsage')
    }

    onCancelUsage = () => {
        this.toggleState('openModalUsage')
    }

    getListUsages = (current, agri) => {
        const { usagesCategories, cultures } = this.props
        const {
            usagesCurrentYear,
            usages,
            readMode,
            real,
        } = this.state
        const usagesSelected = current ? usagesCurrentYear : usages
        const filteredUsages = []
        usagesSelected.forEach((u) => {
            const usageCat = usagesCategories.find((uCat) => uCat.idUsage === u.idUsage)
            if (usageCat && agri ? usageCat.category === 1 : usageCat.category !== 1) {
                filteredUsages.push(u)
            }
        })

        if (filteredUsages.length) {
            let total = 0
            filteredUsages.forEach((u) => {
                total = total + (isNaN(u.requestedYearVolume) ? 0 : u.requestedYearVolume)
            })
            return (
                <Grid item xs={12}>
                    <StyledCard
                        title={!real ? `${agri ? i18n.agri : i18n.nonAgri} : ${formatMilliers(total) || 0} m3` : agri ? i18n.agri : i18n.nonAgri}
                        color='white'
                        styleTitle={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '12 8' }}
                        styleContent={{ padding: '0 8' }}
                        hideIcon
                    >
                        <Grid
                            container
                            direction='column'
                            justifyContent='center'
                        >
                            {filteredUsages.map((u, i) => {
                                const culture = cultures.find((c) => c.id === u.idCulture)
                                const usageCat = usagesCategories.find((uCat) => uCat.idUsage === u.idUsage)
                                return (
                                    <Grid
                                        key={u.id}
                                        container
                                        direction='column'
                                        justifyContent='center'
                                        style={{ borderBottom: i === filteredUsages.length - 1 ? 'none' : 'solid black 1px', padding: '8 0' }}
                                        className={readMode ? '' : 'clickable'}
                                        onClick={readMode ? () => { } : () =>
                                            this.setState({
                                                usageToUpdate: u,
                                                openModalUsage: true,
                                            })
                                        }
                                    >
                                        <>
                                            {agri ? (
                                                <Grid item style={{ fontWeight: 'bold' }}>
                                                    {`${usageCat.description} ${usageCat.category === 1 && culture ? `/ ${culture.name}` : ''}`}
                                                </Grid>
                                            ) : (
                                                <Grid item style={{ fontWeight: 'bold' }}>{usageCat.description}</Grid>
                                            )}
                                            {!real && (
                                                <Grid item>
                                                    {`${usageCat.isCulture ? i18n.indicativeSimu : i18n.askedLabel} : ${formatMilliers(u.requestedYearVolume) || 0} m3${hasValue(u.area) ? ` - ${u.area} ha` : ''}`}
                                                </Grid>
                                            )}
                                            {real && (hasValue(u.requestedYearVolume) || hasValue(u.area)) && (
                                                <Grid item>
                                                    {hasValue(u.requestedYearVolume) ?
                                                        `Volume : ${formatMilliers(u.requestedYearVolume) || 0} m3${hasValue(u.area) ? ` - ${u.area} ha` : ''}`
                                                        : hasValue(u.area) ? `${u.area} ha` : ''}
                                                </Grid>
                                            )}
                                        </>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </StyledCard>
                </Grid>
            )
        }
        return ''
    }

    onChangeUsage = (usage) => {
        this.setState({ usageToUpdate: usage, openModalUsage: true })
    }

    onValideUsage = () => {
        const { idInstallation } = this.state
        this.props.push(`/enquete/point/${idInstallation}`)
    }

    onClickNoConso = () => {
        const { declaration } = this.props
        const { point, idInstallation } = this.state
        const { materiels } = this.getMateriels()
        const pointToRemove = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation)
        const points = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        points.push({
            ...pointToRemove,
            link_usagesCurrent: [],
        })
        const date = moment().valueOf()
        const matsIds = materiels.map((m) => m.id)
        const chroniclesByMat = groupBy(declaration.link_chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX && matsIds.includes(c.idMat)), 'idMat')
        const indexs = compact(keys(chroniclesByMat).map((matId) => {
            const lastIndex = orderBy(chroniclesByMat[matId], 'measureDate', 'desc')[0]
            if (lastIndex && lastIndex.measureDate < date) {
                return { matId, value: orderBy(chroniclesByMat[matId], 'measureDate', 'desc')[0]?.value }
            }
            return undefined
        }))
        const newIndexs = indexs.map((i) => ({
            matType: 'divers',
            idMat: Number(i.matId),
            measureType: CHRONICLES_CONSTANTS.TYPE_INDEX,
            measureDate: date,
            value: i.value,
            new: true,
        }))
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: points,
            link_chronicles: [
                ...declaration.link_chronicles,
                ...newIndexs,
            ],
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.props.push(`/enquete/point/${idInstallation}`)
        })
    }

    editModalIndex = (type, id) => {
        this.setState({ selectedMaterial: id, consoType: type, openModalIndex: true })
    }

    getChroniclesByType = (chroniclesByType, type, materiel, readingCoefficient = 1) => {
        const { readMode } = this.state
        const { matEventsTypes, survey: { year } } = this.props
        if (chroniclesByType.length) {
            const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            const props = {
                onClick: readMode ? () => { } : () => this.editModalIndex(type, materiel.id),
            }
            return (
                <>
                    <ChronicleItem item className='italic' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                    </ChronicleItem>
                    <ChronicleItem item className='italic' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.endDate : ''}
                    </ChronicleItem>
                    <ChronicleItem item className='italic' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                    </ChronicleItem>
                    <ChronicleItem item className='italic-label' xs={3}>
                        {`${type === TYPE_ESTIM ? 'Cumul' : i18n.evolution} (m3)`}
                    </ChronicleItem>
                    {orderedChronicles.map((chronicle, i) => {
                        if (chronicle.event) {
                            const onClickEvent = {
                                onClick: () => {
                                    this.setState({ openModalEvent: true, selectedEvent: chronicle })
                                },
                            }
                            return (
                                <>
                                    <ChronicleItem item xs={3} {...onClickEvent}>
                                        {new Date(chronicle.measureDate).getFullYear() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                    </ChronicleItem>
                                    <HelpTooltip title={chronicle.comment} arrow>
                                        <ChronicleItem item xs={3} {...onClickEvent}><Icon>warning</Icon></ChronicleItem>
                                    </HelpTooltip>
                                    <ChronicleItem item xs={3} {...onClickEvent}>
                                        {(matEventsTypes.find(({ id }) => id === chronicle.idType) || {}).name || ''}
                                    </ChronicleItem>
                                    <ChronicleItem item xs={3} />
                                </>
                            )
                        }
                        const valueEvol = i !== orderedChronicles.length - 1 ? getEvolValue(type, orderedChronicles, chronicle, i) : null
                        return (
                            <>
                                <ChronicleItem item xs={3} className={i === 0 ? 'bold' : ''} {...props}>
                                    {new Date(chronicle.measureDate).getFullYear() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                </ChronicleItem>
                                <ChronicleItem item xs={3} className={i === 0 ? 'bold' : ''} {...props}>
                                    {chronicle.endDate ? (new Date(chronicle.endDate).getFullYear() === year ? getMiniDateNoYear(chronicle.endDate) : getMiniDate(chronicle.endDate)) : ''}
                                </ChronicleItem>
                                <ChronicleItem item xs={3} className={i === 0 ? 'bold' : ''} {...props}>
                                    {formatMilliers(chronicle.value) || 0}
                                </ChronicleItem>
                                <ChronicleItem item xs={3} className={i === 0 ? 'bold' : ''} style={valueEvol < 0 ? { color: 'orange' } : {}}>
                                    {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                                </ChronicleItem>
                            </>
                        )
                    })}
                    <ChronicleItem item xs={11}>
                        <Divider />
                    </ChronicleItem>
                </>
            )
        }
        return null
    }

    getChronicles = () => {
        const { declaration, variousMateriels, matEventsExploitation, survey, exploitation } = this.props
        const { readMode, idInstallation } = this.state
        const { pumpsIds, countersIds } = this.getMateriels()
        const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === idInstallation) || {}
        const allIds = [...pumpsIds, ...countersIds]
        const eventsMat = matEventsExploitation.filter(({ idMateriel }) => allIds.includes(idMateriel))
        const pointEvents = eventsMat.filter(({ eventDate }) => [survey.year - 1, survey.year].includes(new Date(eventDate).getFullYear()))
        const pointEventsFormatted = pointEvents.map((e) => {
            return {
                ...e,
                idMat: e.idMateriel,
                measureDate: e.eventDate,
                event: true,
            }
        })
        const chroniclesToShow = declaration.link_chronicles.filter((chronicle) => allIds.includes(chronicle.idMat))
        const pointChronicles = chroniclesToShow.filter(({ measureDate }) => (!linkPoint.startDate || measureDate >= linkPoint.startDate) && (!linkPoint.endDate || measureDate < linkPoint.endDate))
        const groupedByMat = Object.values(groupBy([...pointChronicles, ...pointEventsFormatted], 'idMat'))
        return compact(groupedByMat.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (hasValue(variousMat.administrator) && variousMat.administrator !== exploitation.operatorCode) {
                return null
            }
            const indexChronicles = chronicles.filter((c) => c.measureType === TYPE_INDEX || c.event)
            const estimateChronicles = chronicles.filter((c) => c.measureType === TYPE_ESTIM)
            const readingCoefficient = variousMat?.counter?.readingCoefficient
            return (
                <Grid
                    item
                    xs={12}
                    container
                    direction='row'
                    justifyContent='center'
                    className={readMode ? '' : 'clickable'}
                >
                    <ChronicleItem
                        item
                        xs={11}
                        className='bold'
                        style={{ textAlign: 'start' }}
                        onClick={readMode ? () => { } : () => this.editModalIndex(chronicles[0].measureType, variousMat.id)}
                    >
                        {variousMat.reference}{readingCoefficient && ` - ${i18n.coefLectureShort} ${readingCoefficient}`}{variousMat?.counter?.informative && ` (${i18n.informative})`}
                    </ChronicleItem>
                    {this.getChroniclesByType(indexChronicles, TYPE_INDEX, variousMat, readingCoefficient)}
                    {this.getChroniclesByType(estimateChronicles, TYPE_ESTIM, variousMat)}
                </Grid>
            )
        }))
    }

    getButtonConso = () => {
        const { pumpsIds, countersIds } = this.getMateriels()
        if (!pumpsIds.length) {
            return <p>Aucun équipement de pompage n'est affecté sur  ce point. Vous ne pouvez pas saisir de consommation.</p>
        } else if (!countersIds.length) {
            return (
                <>
                    <p>Aucun dispositif de comptage n'est affecté sur ce point. Vous ne pourrez saisir qu'une estimation en m3.</p>
                    <Grid item xs={12}>
                        <MainButton little onClick={() => this.toggleState('openModalIndex')}>
                            {i18n.addConso}
                        </MainButton>
                    </Grid>
                </>
            )
        }
        return (
            <Grid item xs={12}>
                <MainButton little onClick={() => this.toggleState('openModalIndex')}>
                    {i18n.addConso}
                </MainButton>
            </Grid>
        )
    }

    onDeleteEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        this.props.deleteMatEvent(event.id, () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)).then(() => {
            this.setState({ dataLoaded: true })
        })
    }

    onValidateEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ openModalEvent: false, selectedEvent: null, dataLoaded: false })
        if (event.id) {
            this.props.updateMatEvent(event,
                () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)
            ).then(() => {
                this.setState({ dataLoaded: true })
            })
        } else {
            this.props.createMatEvent(event).then(() => {
                this.props.fetchMatEventsByExploitation(exploitation.idExploitation).then(() => {
                    this.setState({ dataLoaded: true })
                })
            })
        }
    }

    getContent = () => {
        const { survey } = this.props
        const { readMode, real } = this.state
        const titleConsos = `${i18n.consos} ${survey.year ? survey.year - 1 : ''}`

        if (!real) {
            return (
                <Grid item xs={12}>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        className='padding-top-1'
                    >
                        {this.getListUsages(false, true)}
                        {this.getListUsages(false, false)}
                    </Grid>
                </Grid>
            )
        }
        return (
            <>
                {(getSetting(survey.link_params, 'consos') || '1') !== '0' && (
                    <Grid item xs={12} className='padding-top-2' style={{ padding: '0 10' }}>
                        <Accordeon title={titleConsos} expanded>
                            <Grid
                                container
                                direction='row'
                                justifyContent='center'
                                style={{ padding: '8px' }}
                            >
                                {this.getChronicles()}
                                {!readMode && this.getButtonConso()}
                                {!readMode && (
                                    <Grid item xs={12}>
                                        <MainButton reverse little onClick={this.onClickNoConso}>
                                            Aucun prélèvement
                                        </MainButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Accordeon>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        className='padding-top-1'
                    >
                        {this.getListUsages(true, true)}
                        {this.getListUsages(true, false)}
                    </Grid>
                </Grid>
            </>
        )
    }

    onCancelIndex = () => {
        this.setState({
            consoType: null,
            selectedMaterial: null,
            openModalIndex: false,
        })
    }

    onSaveIndex = (link_chronicles) => {
        const { declaration } = this.props
        const updateDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_chronicles,
        }
        this.setState({
            declarationLoaded: false,
            consoType: null,
            selectedMaterial: null,
            openModalIndex: false,
        })
        this.props.updateDeclaration(updateDeclaration).then(() => {
            this.setDeclaration()
        })
    }

    render() {
        const {
            openModalUsage,
            openModalIndex,
            openModalEvent,
            usageToUpdate,
            usages,
            usagesCurrentYear,
            point,
            dataLoaded,
            declarationLoaded,
            selectedMaterial,
            consoType,
            readMode,
            selectedEvent,
            real,
        } = this.state
        const { variousMateriels, variousMatSituations, survey } = this.props
        if (declarationLoaded) {
            const { pumpsIds, countersIds, materiels } = this.getMateriels()
            const exploitationMatIds = materiels.map(({ id }) => id)
            const showButtonAddUse = (real && (getSetting(survey.link_params, 'usagesReal') || '1') !== '0') ||
                (!real && (getSetting(survey.link_params, 'usagesPrevi') || '1') !== '0')
            return (
                <>
                    <Grid container alignItems='center' style={{ paddingBottom: !showButtonAddUse ? 120 : 160 }}>
                        {dataLoaded && declarationLoaded && variousMatSituations.length && variousMateriels.length ? (
                            this.getContent()
                        ) :
                            <Grid item xs={12}>
                                <LinearProgress />
                            </Grid>
                        }
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-evenly'
                        alignItems='center'
                        className='borderTop-Grey'
                        style={{
                            position: 'fixed',
                            bottom: '0',
                            backgroundColor: 'white',
                            zIndex: '1000',
                            padding: '5px 10px',
                            minHeight: 70,
                        }}
                        sx={{
                            lg: { height: '70px' },
                            md: { height: !showButtonAddUse ? '120px' : '160px' },
                            xs: { height: !showButtonAddUse ? '120px' : '160px' },
                        }}
                    >
                        {!readMode && showButtonAddUse && (
                            <Grid item xs={12} md={3}>
                                <MainButton onClick={() => this.setState({ openModalUsage: !openModalUsage, usageToUpdate: false })}>
                                    {!real ? i18n.addPrevUse : i18n.addUse}
                                </MainButton>
                            </Grid>
                        )}
                        <Grid item xs={12} md={3}>
                            <OrangeButton onClick={() => this.setState({ openModalEvent: true })}>
                                <Icon style={{ marginRight: 8 }}>warning</Icon>
                                {i18n.reportIncident}
                            </OrangeButton>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MainButton onClick={this.onValideUsage}>
                                {readMode ? i18n.returnPreviousStep : (!real ? i18n.validPrevUses : i18n.validUses)}
                            </MainButton>
                        </Grid>
                    </Grid>
                    {openModalEvent && (
                        <ModalMatEvent
                            open={openModalEvent}
                            event={selectedEvent}
                            materiels={materiels}
                            onCancel={() => this.setState({ openModalEvent: false, selectedEvent: null })}
                            onDelete={this.onDeleteEvent}
                            onValidate={this.onValidateEvent}
                        />
                    )}
                    {openModalUsage && (
                        <ModalUsage
                            open={openModalUsage}
                            point={point}
                            usage={usageToUpdate}
                            usages={real ? usagesCurrentYear : usages}
                            onSave={this.onSaveUsage}
                            onDelete={this.onSaveUsage}
                            onCancel={this.onCancelUsage}
                            currentYear={real}
                            countersId={countersIds}
                        />
                    )}
                    {openModalIndex && (
                        <ModalIndex
                            open={openModalIndex}
                            point={point}
                            pumpsId={uniq(pumpsIds.filter((id) => exploitationMatIds.includes(id)))}
                            countersId={uniq(countersIds.filter((id) => exploitationMatIds.includes(id)))}
                            selectedMaterial={selectedMaterial}
                            consoType={consoType}
                            onSave={this.onSaveIndex}
                            onCancel={this.onCancelIndex}
                        />
                    )}
                </>
            )
        }
        return <LinearProgress />
    }
}

PointUsages.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    usagesCategories: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageCategory)),
    fetchCultures: PropTypes.func,
    fetchCulturesFamilies: PropTypes.func,
    fetchModesIrrigations: PropTypes.func,
    fetchInstallation: PropTypes.func,
    updateDeclaration: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    fetchUsages: PropTypes.func,
    fetchCmsByCategory: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchSurvey: PropTypes.func,
    createMatEvent: PropTypes.func,
    updateMatEvent: PropTypes.func,
    deleteMatEvent: PropTypes.func,
    fetchMatEventsByExploitation: PropTypes.func,
    resetInstallation: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    survey: PropTypes.instanceOf(DtoEnquete),
    matEventsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEvent)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
}

const mapStateToProps = (store) => {
    return {
        cultures: store.ReferencialReducer.cultures,
        usagesCategories: store.ReferencialReducer.usagesCategories,
        installations: store.InstallationsReducer.installations,
        installation: store.InstallationsReducer.installation,
        declaration: store.AgriReducer.declaration,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        exploitation: store.AgriReducer.exploitation,
        survey: store.AgriReducer.survey,
        matEventsExploitation: store.MaterielReducer.matEventsExploitation,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
    }
}

const mapDispatchToProps = {
    fetchCultures: ReferencialAction.fetchCultures,
    fetchCulturesFamilies: ReferencialAction.fetchCulturesFamilies,
    fetchUsages: ReferencialAction.fetchUsages,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchSurvey: AgriAction.fetchSurvey,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    updateDeclaration: AgriAction.updateDeclaration,
    fetchInstallation: InstallationsAction.fetchInstallation,
    setTitle: HomeAction.setTitle,
    fetchCmsByCategory: CmsAction.fetchCmsByCategory,
    createMatEvent: MaterielAction.createMatEvent,
    updateMatEvent: MaterielAction.updateMatEvent,
    deleteMatEvent: MaterielAction.deleteMatEvent,
    fetchMatEventsByExploitation: MaterielAction.fetchMatEventsByExploitation,
    resetInstallation: InstallationsAction.resetInstallation,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointUsages)
