import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoUser from '../account/dto/DtoUser'
import DtoAccountStatistic from '../account/dto/DtoAccountStatistic'
import packageJson from '../../../../package.json'
import defaultLogo from '../../../ressources/static/media/iryqua.png'
import { veryLightGrey, mainColor, menuColor, veryLightColor, darkRed } from './styled/theme'
import { StyledBadgeDesktop } from './styled/badges'
import { AccountCircle, Assignment, AttachFile, BookmarkBorder, Folder, Home, LogoutRounded, Mail } from '@mui/icons-material'
import i18n from 'simple-react-i18n'
import { compact, isNil, orderBy, template, } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { hasValue } from '../../../utils/NumberUtil'
import { ReactComponent as PointsLogo } from '../../../ressources/static/svg/Niveau7.svg'
import { push } from 'connected-react-router'
import HomeAction from '../../offline/actions/HomeAction'
import { getSetting, getSettingInt, sieauTooltip } from '../../../utils/FormUtils'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import moment from 'moment'
import { AGRI, AGRI_RSEAU, CHRONICLES_CONSTANTS, DECLARATION_STATUS } from '../agri/constants/AgriConstants'
import { orderPointsList } from '../../../utils/ObjectUtils'
import { getStateLabel } from '../../../utils/AgriUtils'
import { POINT_STATUS_OBJ } from '../referencials/installations/constants/InstallationsConstants'
import AccountAction from '../account/actions/AccountAction'
import AgriAction from '../agri/actions/AgriAction'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import CmsAction from '../cms/actions/CmsAction'
import CityAction from '../referencials/city/actions/CityAction'
import aquasysLogoPath from '../../../ressources/pictures/favicon.png'
import { getI18nTitleDataLength } from '../../../utils/StringUtil'

const AppDesktop = () => {
    const {
        exploitation,
        accountUser,
        actualities,
        accountStatistics,
        declaration,
        listOfMessages,
        variousMateriels,
        installations,
        applicationSettings,
        accountHabilitations,
        survey,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        accountUser: store.AccountReducer.accountUser,
        actualities: store.CmsReducer.actualities,
        accountStatistics: store.AccountReducer.accountStatistics,
        declaration: store.AgriReducer.declaration,
        listOfMessages: store.ContactReducer.listOfMessages,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        installations: store.InstallationsReducer.installations,
        applicationSettings: store.HomeReducer.applicationSettings,
        accountHabilitations: store.AccountReducer.accountHabilitations,
        survey: store.AgriReducer.survey,
    }), shallowEqual)

    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', s => s?.split(',')?.map(id => parseInt(id)))

    const date = useMemo(() => moment(), [])

    const isAdmin = useMemo(() => accountUser.isAdmin === '1' || accountUser.metadata === '1', [accountUser])
    const surveyEndDate = useMemo(() => isAdmin ? survey?.adminEndDate : survey?.endDate, [survey, isAdmin])

    const logoUrl = localStorage.getItem('IRYQUA_logoUrl') || defaultLogo

    const dispatch = useDispatch()

    const hasHabilitation = hab => accountHabilitations.some(h => h.habilitation === hab)

    const navigate = (path) => {
        dispatch(push(path))
        window.scrollTo(0, 0)
    }

    const getAllMessages = () => {
        dispatch(ContactAction.getMessagesOf(getLogin()))
    }

    useEffect(() => {
        dispatch(AccountAction.fetchUser(getLogin()))
        dispatch(AgriAction.fetchExploitation())
        dispatch(HomeAction.fetchApplicationSettings())
        dispatch(ReferencialAction.fetchBookmarks())
        dispatch(ReferencialAction.fetchWatersheds())
        dispatch(ReferencialAction.fetchCultures())
        dispatch(ReferencialAction.fetchCulturesFamilies())
        dispatch(ReferencialAction.fetchCodesSandre())
        dispatch(MaterielAction.fetchMatEventsTypes())
        dispatch(ReferencialAction.fetchTanksTypes())
        dispatch(ReferencialAction.fetchContributors())
        dispatch(ReferencialAction.fetchManagementUnitsRestrictions())
        dispatch(AgriAction.fetchDroughtSectorsRestrictions())
        dispatch(InstallationsAction.fetchAllVariousMatTypes())
        dispatch(AgriAction.fetchSurveys())
        dispatch(CmsAction.fetchCmsByCategory(5))
        dispatch(CmsAction.fetchCMSEvents())
        dispatch(InstallationsAction.fetchAllVariousMateriels())
        dispatch(InstallationsAction.fetchVariousMatSituations())
        dispatch(ReferencialAction.fetchManagementUnits())
        dispatch(AgriAction.fetchWaterTurnsRestrictions())
        dispatch(AgriAction.fetchWaterTurnsSlots())
        dispatch(CityAction.fetchCities())
        dispatch(CmsAction.fetchCmsActualities())
    }, [])

    const idCmsInfo = useMemo(() => getSettingInt(applicationSettings, 'iryquaCmsInformation'), [applicationSettings])

    useEffect(() => {
        dispatch(HomeAction.fetchCMSLogo(idCmsInfo))
    }, [idCmsInfo])

    useEffect(() => {
        if (exploitation?.idExploitation && !declaration.idDeclaration) {
            dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation))
        }
    }, [exploitation])

    useEffect(() => {
        if (declaration?.idDeclaration && !survey.idSurvey) {
            dispatch(AgriAction.fetchSurvey(declaration.idSurvey))
        }
    }, [declaration])

    useEffect(() => {
        const applicationName = applicationSettings?.find(({ parameter }) => parameter === 'applicationName') || {}
        if (applicationName.value) {
            document.title = applicationName.value
            $('#favicon').attr('href', getSetting(applicationSettings, 'applicationFavicon') || aquasysLogoPath)
        }
    }, [applicationSettings])

    const { idExploitation, operatorCode } = useMemo(() => {
        return {
            idExploitation: exploitation.idExploitation,
            operatorCode: exploitation.operatorCode,
        }
    }, [exploitation])

    useEffect(() => {
        if (idExploitation) {
            dispatch(AgriAction.fetchWaterTurnsExploitation(idExploitation))
            dispatch(AgriAction.fetchExploitationVolumes(idExploitation))
            dispatch(MaterielAction.fetchMatEventsByExploitation(idExploitation))
            dispatch(ReferencialAction.fetchContributor(operatorCode))
        }
    }, [exploitation])

    useEffect(() => {
        if (hasHabilitation(AGRI) && hasHabilitation(AGRI_RSEAU)) {
            dispatch(AgriAction.fetchRSEauData())
        }
    }, [accountHabilitations])

    useEffect(() => {
        if (hasHabilitation(AGRI) && hasHabilitation(AGRI_RSEAU)) dispatch(AgriAction.fetchRSEauData())
    }, [accountHabilitations])

    useEffect(() => {
        getAllMessages()
        const interval = setInterval(() => {
            getAllMessages()
        }, 30000)

        return () => clearInterval(interval)
    }, [])

    const href = useMemo(() => window.location.href)

    const isActive = (path) => {
        return href.split('#')[1].startsWith(path)
    }

    const getMaterial = (idVariousMaterial) => {
        return variousMateriels.find(m => m.id === idVariousMaterial)
    }

    const getSitusAndIdsVarious = (idInstallation) => {
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation && (m.situationEndDate ? m.situationEndDate > date.valueOf() : true) && (m.situationDate ? m.situationDate < date.valueOf() : true)) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode) && (m.situationEndDate ? m.situationEndDate > date.valueOf() : true) && (m.situationDate ? m.situationDate < date.valueOf() : true)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        return { pumpsIds, countersIds, pointPumps, pointCounters }
    }

    const getWarningCondition = (idInstallation) => {
        const { pumpsIds, countersIds, pointPumps, pointCounters } = getSitusAndIdsVarious(idInstallation)
        const ids = [...pumpsIds, ...countersIds]
        const pointMats = [...pointPumps, ...pointCounters]
        const materialsSeizableIndex = ids?.map(id => getMaterial(id))?.filter(m => materielsTypeForIndex?.includes(m?.materielType))
        if (materialsSeizableIndex?.length > 0) {
            const lastIndex = orderBy(exploitation.link_chronicles.filter((c) => materialsSeizableIndex.some(m => m.id ===c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX), 'measureDate', 'desc')[0]
            if (lastIndex) {
                return { idMatLastChronicle: lastIndex.idMat, days: lastIndex.measureDate, important: true, estim: false }
            }
            const lastAffectIndex = orderBy(pointMats.filter(m => materialsSeizableIndex.some(mat => mat.id === m.idVarious)), 'situationDate', 'desc')[0]
            if (lastAffectIndex) {
                return { days: lastAffectIndex.situationDate, important: false }
            }
        }
        const lastEstim = orderBy(exploitation.link_chronicles.filter((c) => ids.includes(c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM), 'measureDate', 'desc')[0]
        if (lastEstim) {
            return { days: lastEstim.measureDate, important: true, estim: true }
        }
        return { days: null }
    }

    const getPoints = () => (
        orderPointsList(compact(exploitation?.link_samplings?.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const { idMatLastChronicle, days, important, estim } = getWarningCondition(point.id)
                const warning = (days ? date.diff(moment(days), 'days') > 7 : false)
                const stateCode = linkPoint.stateCode
                const pointFormatted = {
                    ...point,
                    days,
                    idMatLastChronicle,
                    warning,
                    important: stateCode !== POINT_STATUS_OBJ.USED ? false : important,
                    estim,
                    stateCode,
                    stateLabel: getStateLabel(stateCode),
                }
                return pointFormatted
            }
            return null
        })))
    )
    const actionOnInstallation = getPoints().filter(p => p.warning && p.important).length

    const hasExploit = hasValue(exploitation?.idExploitation)
    const hasDeclaration = hasValue(declaration?.idDeclaration)
    const lastLogin = orderBy(accountStatistics.filter((s) => (s.eventType === 'LOGIN')), ['statisticDate'], ['desc'])[1]
    const actuNotSeen = useMemo(() => compact(actualities.map((a) => {
        if (lastLogin?.statisticDate < a.updateDate) {
            if (a.status === 1 || accountUser.isAdmin === '1') {
                return a
            }
        }
        return null
    })), [actualities, lastLogin])
    const messageNotSeen = useMemo(() => isNil(listOfMessages) ? compact(listOfMessages.map(m => {
        if (lastLogin?.statisticDate < m.updateDate) {
            if (m.updateLogin !== getLogin()) {
                return m
            }
        }
        return null
    })) : null, [listOfMessages, lastLogin])
    const actuToShow = orderBy(actuNotSeen, 'updateDate')
    const alertDecla = useMemo(() => ({
        day: moment(surveyEndDate).diff(date, 'days'),
        hour: moment(surveyEndDate).diff(date, 'hour') % 24,
    }), [surveyEndDate, date])

    const alertLabel = useMemo(() => {
        if (alertDecla.day) {
            return template(i18n.declarationTimeRemaining)({ time: `${alertDecla.day} ${getI18nTitleDataLength(i18n.day, i18n.days, alertDecla.day)}` })
        } else if (alertDecla.hour) {
            return template(i18n.declarationTimeRemaining)({ time: `${alertDecla.hour} ${getI18nTitleDataLength(i18n.hour, i18n.hours, alertDecla.hour)}` })
        }
        return template(i18n.haveUntilTimeToCompleteDeclaration)({ time: date.format('hh:mm') })
    }, [alertDecla, alertDecla])

    const gridStyle = { padding: '4px 16px', width: '100%', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }
    const iconStyle = { height: '18px', width: 'auto', color: mainColor }
    const labelStyle = { color: mainColor, fontSize: '18px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }
    const tooltipNoDecla = useMemo(() => (!hasDeclaration ? sieauTooltip(i18n.noStatement, null, 'right', true) : ''), [hasDeclaration]) // TODO
    const tooltipAlertDecla = useMemo(() => (hasDeclaration && alertDecla.day <= 7 && alertDecla.day >= 0 ? sieauTooltip(alertLabel, null, 'right', true) : ''), [hasDeclaration]) // TODO

    return (
        <Grid container backgroundColor={veryLightColor} height='100vh' padding='6vh 3vw' position='fixed'>
            <Grid item container direction='column' xs={1.5} height='100%' justifyContent='space-between'>
                <Grid item container direction='column' textAlign='center' width='100%' justifyContent='space-between'>
                    <img src={logoUrl} alt='' width='100%' />
                    <span>v{packageJson.version}</span>
                    <Grid item container direction='column' gap='8px' paddingTop='10vh'>
                        <Grid item container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/home') ? menuColor : 'none' }} onClick={() => navigate('/home')} >

                            <Grid item xs={2} >
                                <StyledBadgeDesktop badgeContent={actuToShow.length} badgeColor={mainColor} >
                                    <Home sx={{ ...iconStyle }} />
                                </StyledBadgeDesktop>
                            </Grid>
                            <Grid item xs={10} sx={labelStyle}>
                                {i18n.home}
                            </Grid>
                        </Grid>
                        <Grid item container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/installations') || isActive('/index') ? menuColor : 'none' }} onClick={hasExploit ? () => navigate('/installations') : () => {}} >
                            <Grid item xs={2} >
                                <StyledBadgeDesktop badgeContent={actionOnInstallation} badgeColor={darkRed} >
                                    <PointsLogo fill={hasExploit ? mainColor : veryLightGrey} style={{ ...iconStyle }} />
                                </StyledBadgeDesktop>
                            </Grid>
                            <Grid item xs={10} sx={{ ...labelStyle, color: hasExploit ? mainColor : veryLightGrey }}>
                                {i18n.installations}
                            </Grid>
                        </Grid>
                        <Grid {...tooltipNoDecla} {...tooltipAlertDecla} item container className={hasDeclaration ? 'clickable' : ''} sx={{ ...gridStyle, backgroundColor: isActive('/declaration') ? menuColor : 'none', width: '100%' }} onClick={hasDeclaration ? () => navigate(declaration.statusCode === DECLARATION_STATUS.NOT_START ? '/declaration/start' : '/declaration') : () => {}} >
                            <Grid item xs={2}>
                                <StyledBadgeDesktop badgeContent={'!'} badgeColor={darkRed} invisible={alertDecla.day > 7 || alertDecla.day < 0}>
                                    <Assignment style={{ ...iconStyle, color: hasDeclaration ? mainColor : veryLightGrey }} />
                                </StyledBadgeDesktop>
                            </Grid>
                            <Grid item xs={10} sx={{ ...labelStyle, color: hasDeclaration ? mainColor : veryLightGrey }}>
                                {i18n.declaration}
                            </Grid>
                        </Grid>
                        <Grid item container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/message') ? menuColor : 'none' }} onClick={() => navigate('/message')} >
                            <Grid item xs={2} >
                                <StyledBadgeDesktop badgeContent={messageNotSeen?.length}>
                                    <Mail sx={{ ...iconStyle }} />
                                </StyledBadgeDesktop>
                            </Grid>
                            <Grid item xs={10} sx={labelStyle}>
                                {i18n.messages}
                            </Grid>
                        </Grid>
                        <Grid item container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/dossier') ? menuColor : 'none' }} onClick={() => navigate('/dossier')}>
                            <Grid item xs={2}>
                                <Folder sx={{ ...iconStyle }} />
                            </Grid>
                            <Grid item xs={10} sx={labelStyle}>
                                {i18n.folder}
                            </Grid>
                        </Grid>
                        <Grid item container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/documents') ? menuColor : 'none' }} onClick={() => navigate('/documents')}>
                            <Grid item xs={2}>
                                <AttachFile sx={{ ...iconStyle }} />
                            </Grid>
                            <Grid item xs={10} sx={labelStyle}>
                                {i18n.documents}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item direction='column' gap='8px' >
                    <Grid item container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/tutoriel') ? menuColor : 'none' }} onClick={() => navigate('/tutoriel')}>
                        <Grid item xs={2}>
                            <BookmarkBorder sx={{ ...iconStyle }} />
                        </Grid>
                        <Grid item xs={10} sx={labelStyle}>
                            {i18n.tuto}
                        </Grid>
                    </Grid>
                    <Grid item container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/myaccount/preferences') ? menuColor : 'none' }} onClick={() => navigate('/myaccount/preferences')}>
                        <Grid item xs={2}>
                            <AccountCircle sx={{ ...iconStyle }} />
                        </Grid>
                        <Grid item xs={10} sx={labelStyle}>
                            {i18n.account}
                        </Grid>
                    </Grid>
                    <Grid item container className='clickable' width='100%' sx={{ ...gridStyle, '&:hover': { backgroundColor: menuColor } }} onClick={() => dispatch(HomeAction.logout())} >
                        <Grid item xs={2}>
                            <LogoutRounded sx={{ ...iconStyle }} />
                        </Grid>
                        <Grid item xs={10} sx={labelStyle}>
                            {i18n.disconnect}
                        </Grid>
                    </Grid><Grid item container borderRadius='20px' height='15px' width='100%' />
                </Grid>
            </Grid>
        </Grid>
    )
}

AppDesktop.propTypes = {
    exploitation: PropTypes.instanceOf(DtoExploitation),
    accountUser: PropTypes.instanceOf(DtoUser),
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStatistic)),
    actualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    match: PropTypes.shape({}),
    children: PropTypes.element
}

export default AppDesktop
