import React from 'react'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { mainColor } from '../styled/theme'

const OfflineCard = () => {
    return (
        <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
            sx={{
                position: 'fixed',
                height: '100vh',
                top: 0,
                left: 0,
                color: mainColor,
                fontSize: '1.5rem',
                textAlign: 'center',
                padding: '0 20px',
            }}
        >
            {i18n.notAvailableOffline}
        </Grid>
    )
}

export default OfflineCard