import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import DtoManagementUnit from '../../referencials/dto/DtoManagementUnit'
import DtoSandreCode from '../../referencials/dto/DtoSandreCode'
import { Grid } from '@mui/material'
import { LightCard } from '../styled/grid'

const AssociatedResourceCard = ({ title, data }) => {
    const {
        codesSandre,
        managementUnits,
    } = useSelector(store => ({
        codesSandre: store.ReferencialReducer.codesSandre,
        managementUnits: store.ReferencialReducer.managementUnits,
    }), shallowEqual)

    const zre = codesSandre.find((c) => c.field === 'AGRI_PREL_ZRE' && c.code === data.codeZRE) || {}
    const ug = managementUnits.find((u) => u.managementCode === data.managementCode) || {}
    const sug = managementUnits.find((u) => u.managementCode === data.subManagementCode) || {}

    return (
        <LightCard
            item
            container
            direction='column'
            justifyContent='space-between'
            className='clickable'
            sx={{ marginBottom: '20px' }}
        >
            <Grid item fontSize={22} className='bold' sx={{ marginBottom: '20px' }}>{title ||i18n.associatedResource}</Grid>
            <Grid container direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
                <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                    <Grid item>{i18n.zre}</Grid>
                    <Grid item className='bold'>{zre?.name || '-'}</Grid>
                </Grid>
                {/* <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                    <Grid item>{i18n.miocene}</Grid>
                    <Grid item className='bold'>{data.miocenezpr ? i18n.yes : i18n.no}</Grid>
                </Grid> */}
                <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                    <Grid item>{`${i18n.ug} - ID `}</Grid>
                    <Grid item className='bold'>{ug?.id || '-'}</Grid>
                </Grid>
                <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                    <Grid item>{i18n.ug}</Grid>
                    <Grid item className='bold'>{ug?.name || '-'}</Grid>
                </Grid>
                <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                    <Grid item>{`S${i18n.ug} - ID `}</Grid>
                    <Grid item className='bold'>{sug?.id || '-'}</Grid>
                </Grid>
                <Grid item container justifyContent='space-between'>
                    <Grid item>{`S${i18n.ug}`}</Grid>
                    <Grid item className='bold'>{sug?.name || '-'}</Grid>
                </Grid>
            </Grid>
        </LightCard>
    )
}

AssociatedResourceCard.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.instanceOf(PropTypes.shape({})),
    style: PropTypes.instanceOf(PropTypes.shape({})),
    styleContainer: PropTypes.instanceOf(PropTypes.shape({})),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

export default AssociatedResourceCard
