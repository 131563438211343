import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, Card } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import AgriAction from '../agri/actions/AgriAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import CmsAction from '../cms/actions/CmsAction'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import { mainColor } from '../components/styled/theme'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import DtoManagementUnitRestriction from '../referencials/dto/DtoManagementUnitRestriction'
import DtoWaterTurnsSlot from '../agri/dto/DtoWaterTurnsSlot'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import { getDate } from '../../../utils/DateUtil'
import { DialogContentMUI, DialogMUI, DialogTitleMUI, DialogActionsMUI } from '../components/styled/dialog'
import DtoDroughtSectorRestriction from '../agri/dto/DtoDroughtSectorRestriction'
import { getSettingInt } from '../../../utils/FormUtils'
import { convertToRGB } from '../../../utils/ColorUtil'
import { SAMPLE_TYPES } from '../agri/constants/AgriConstants'
import LoadingCard from '../components/cards/LoadingCard'
import { Add, Help, Remove, Star, StarBorder } from '@mui/icons-material'
import WaterTurnsWeekCalendar from '../components/calendar/WaterTurnsWeekCalendar'
import { LightCard } from '../components/styled/grid'
import { isOnline } from '../../../utils/StoreUtils'
import { isMobile } from 'react-device-detect'
import { TextButton } from '../components/styled/buttons'

class PointDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: !isOnline(),
            contactsLoaded: !isOnline(),
            idInstallation: parseInt(props.match.params.id),
            year: new Date().getFullYear(),
            showExemption: false,
        }
    }

    componentDidMount() {
        const { exploitation } = this.props
        const { idInstallation } = this.state
        this.props.fetchCmsByCategory(5)
        this.props.fetchManagementUnitsRestrictions()
        this.props.fetchDroughtSectorsRestrictions()
        this.props.fetchContacts().then(() => this.setState({ contactsLoaded: true }))
        this.props.fetchInstallation(idInstallation).then(() => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.fetchDatas(idInstallation))
            } else {
                this.fetchDatas(idInstallation)
            }
        })
        window.scrollTo(0, 0)
    }

    fetchDatas = (idInstallation) => {
        const { exploitation } = this.props
        if (!exploitation.idExploitation) {
            this.props.fetchExploitation().then(() => this.setState({ dataLoaded: true }))
        } else {
            this.setState({ dataLoaded: true })
        }
        this.props.fetchBookmarks().then(() => this.setTitle(idInstallation))
    }

    setTitle = (idInstallation) => {
        const { installation, bookmarks } = this.props
        const fav = bookmarks.find((b) => b.stationType === 7 && b.identifiant === `${idInstallation}`)
        const bookmark = {
            login: getLogin(),
            identifiant: `${idInstallation}`,
            stationType: 7,
        }
        this.props.setTitle([{
            title: installation.name || (installation.code ? `${i18n.point} ${installation.code || ''}` : ''),
            href: `/consommation/point/${installation.id}`,
            icon: fav ? <Star sx={{ color: mainColor }} onClick={() => this.deleteBookmark(fav, installation)} /> : <StarBorder sx={{ color: mainColor }} onClick={() => this.createBookmark(bookmark, installation)} />,
        }])
    }

    deleteBookmark = (fav) => {
        this.props.deleteBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
        })
    }

    createBookmark = (fav) => {
        this.props.createBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
        })
    }

    getModalRestrictions = (openModalRestrictions) => {
        const { waterTurnsRestrictions } = this.props
        return (
            <DialogMUI open={openModalRestrictions} fullScreen={false}>
                <DialogTitleMUI sx={{ backgroundColor: 'white', color: mainColor, padding: '24px', fontSize: '24px' }}>
                    {i18n.waterTurnsRestrictions}
                </DialogTitleMUI>
                <DialogContentMUI>
                    {waterTurnsRestrictions.map((r) => (
                        <Grid container sx={{ padding: '8px 0', color: mainColor }}>
                            <Grid item xs={1} sx={{ backgroundColor: r.color || 'grey', display: 'inline-block', width: '20px', height: '20px', margin: '16px' }}>&nbsp;</Grid>
                            <Grid item xs='auto' container direction='column'>
                                <b>{`${r.label} - ${i18n.level} ${r.level}`}</b>
                                <p>{r.description}</p>
                            </Grid>
                            <Grid item xs={12} sx={{ marginLeft: '16px', marginBottom: '10px' }}>{r.comment}</Grid>
                        </Grid>
                    ))}
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid onClick={() => this.setState({ openModalRestrictions: false })}>{i18n.close}</Grid>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }

    render() {
        const { dataLoaded, contactsLoaded, openModalRestrictions, year, showExemption } = this.state
        const { variousMateriels, variousMatSituations, exploitation, installation, managementUnitsRestrictions, applicationSettings, droughtSectorsRestrictions, waterTurnsRestrictions, waterTurnsSlots, exploitationWaterTurns } = this.props
        if (variousMatSituations.length && variousMateriels.length && dataLoaded && contactsLoaded && exploitation.idExploitation) {
            const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
            const sampleCharacteristics = installation?.link_sampleCharacteristics[0] || {}
            const restriction = (() => {
                if (typeRestriction === 1) {
                    return droughtSectorsRestrictions.find((dsR) => dsR.idSector === sampleCharacteristics.droughtSector && sampleCharacteristics.sampleType === dsR.resourceType) || { idRestriction: -1 }
                }
                return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.subManagementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.managementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || { idRestriction: -1 }
            })()
            const waterTurnRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
            // const restrictionLabel = waterTurnRestriction?.label || i18n.normale
            // const restrictionColor = waterTurnRestriction?.color || '#00AF64'

            const data = exploitationWaterTurns.filter((w) => w.idExploitation === exploitation.idExploitation && w.idInstallation === installation.id && w.year === year)
            if (restriction && waterTurnRestriction) {
                return (
                    <Grid container direction='column' sx={{ padding: '8px 1.5rem', color: mainColor }}>
                        <Card sx={{ backgroundColor: 'white', marginBottom: '24px', borderRadius: '15px' }}>
                            <Grid container direction='column' alignItems='space-between' justifyContent='center' sx={{ backgroundColor: `rgba(${convertToRGB(waterTurnRestriction.color)}, 0.3)`, padding: '16px 16px 8px', borderRadius: '15px' }}>
                                <Grid item className='bold' sx={{ padding: '8px 0', fontSize: '12px' }}>{getDate(restriction.updateDate)}</Grid>
                                <Grid item className='bold' sx={{ fontSize: '16px' }}>{`${waterTurnRestriction.label} - ${waterTurnRestriction.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial} - ${waterTurnRestriction.description}`}</Grid>
                                <Grid item sx={{ fontSize: '16px', paddingTop: '8px' }}>{waterTurnRestriction.comment || ''}</Grid>
                                <Grid item sx={{ fontSize: '16px', paddingTop: '8px' }}>{showExemption ? restriction.exemption || '' : ''}</Grid>
                                {restriction.exemption &&
                                    <Grid item container justifyContent='flex-end'>
                                        <TextButton sx={{ margin: 0 }} onClick={() => this.setState({ showExemption: !showExemption })} noFullWidth startIcon={showExemption ? <Remove /> : <Add />}>
                                            {showExemption ? i18n.showLess : i18n.showMore}
                                        </TextButton>
                                    </Grid>
                                }
                            </Grid>
                        </Card>
                        {isMobile && <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item fontSize={24}>{i18n.waterTurnsCalendar}</Grid>
                            <Help sx={{ color: mainColor }} onClick={() => this.setState({ openModalRestrictions: true })} />
                        </Grid>}
                        {data.map((d) => <WaterTurnsWeekCalendar waterTurn={d} waterTurnsSlots={waterTurnsSlots} waterTurnsRestrictions={waterTurnsRestrictions} />)}
                        {!data?.length && <LightCard className='bold'>{i18n.noWaterTurnDescription}</LightCard>}
                        {this.getModalRestrictions(openModalRestrictions)}
                    </Grid>
                )
            }
            return (
                <Grid container direction='column' sx={{ padding: '8px 1.5rem', color: mainColor }}>
                    <LightCard className='bold'>{i18n.noWaterTurnDescription}</LightCard>
                </Grid>
            )
        }
        return <LoadingCard />
    }
}

PointDashboard.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    fetchInstallation: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchCmsByCategory: PropTypes.func,
    fetchContacts: PropTypes.func,
    deleteBookmark: PropTypes.func,
    createBookmark: PropTypes.func,
    fetchBookmarks: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    bookmarks: PropTypes.arrayOf(PropTypes.shape({})),
    watersheds: PropTypes.arrayOf(PropTypes.shape({})),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    managementUnitsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnitRestriction)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    droughtSectorsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoDroughtSectorRestriction)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    fetchManagementUnitsRestrictions: PropTypes.func,
    fetchDroughtSectorsRestrictions: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        installations: store.InstallationsReducer.installations,
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        cms: store.CmsReducer.cms,
        bookmarks: store.ReferencialReducer.bookmarks,
        watersheds: store.ReferencialReducer.watersheds,
        citiesIndex: store.CityReducer.citiesIndex,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchInstallation: InstallationsAction.fetchInstallation,
    resetInstallation: InstallationsAction.resetInstallation,
    setTitle: HomeAction.setTitle,
    fetchCmsByCategory: CmsAction.fetchCmsByCategory,
    fetchContacts: ContactAction.fetchContacts,
    deleteBookmark: ReferencialAction.deleteBookmark,
    createBookmark: ReferencialAction.createBookmark,
    fetchBookmarks: ReferencialAction.fetchBookmarks,
    fetchManagementUnitsRestrictions: ReferencialAction.fetchManagementUnitsRestrictions,
    fetchDroughtSectorsRestrictions: AgriAction.fetchDroughtSectorsRestrictions,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointDashboard)
