import React from 'react'
import i18n from 'simple-react-i18n'
import { DialogContentMUIDesktop, DialogMUI, DialogTitleMUIv2 } from '../components/styled/dialog'
import LoadingCard from '../components/cards/LoadingCard'
import { Grid } from '@mui/material'
import { mainColor } from '../components/styled/theme'
import { MainButton } from '../components/styled/buttons'
import { useDispatch, useSelector } from 'react-redux'
import AgriAction from '../agri/actions/AgriAction'
import { ReactComponent as DownloadLogo } from '../../../ressources/static/svg/Download.svg'
import PropTypes from 'prop-types'

const ModalDownloadDocument = ({ open, onClose, declaration, surveyLoaded, survey, volumesInNotification }) => {
    const {
        exploitation,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
    }))

    const dispatch = useDispatch()

    return (
        <DialogMUI open={open}
            keepMounted
            onClose={() => onClose()}
        >
            <DialogTitleMUIv2 onClick={() => onClose()}>
                {i18n.exportOf} {declaration?.shortName ?? `${i18n.declaration} ${declaration?.year}`}
            </DialogTitleMUIv2>
            <DialogContentMUIDesktop>
                {!surveyLoaded ? <LoadingCard /> : (
                    <>
                        <Grid item sx={{ color: mainColor }}>{i18n.selectDocument}</Grid>
                        <Grid container justifyContent='center' columnGap={'1rem'}>
                            <MainButton
                                reverse
                                sx={{ width: 'auto' }}
                                onClick={() =>{
                                    dispatch(AgriAction.exportDeclarationFull(declaration.idDeclaration)).then(() => onClose())
                                }}
                            >
                                <DownloadLogo fill={mainColor} style={{ paddingRight: '10px', height: '18px' }} />
                                {i18n.exportPDFSynthesis}
                            </MainButton>
                            {survey.exportNotifications ?
                                <>
                                    {volumesInNotification === 'allocated' && (
                                        <MainButton
                                            reverse
                                            sx={{ width: 'auto' }}
                                            onClick={() => {
                                                dispatch(AgriAction.getNotifEdition(exploitation.idExploitation, declaration.year)).then(() => onClose())
                                            }}
                                        >
                                            <DownloadLogo fill={mainColor} style={{ paddingRight: 10, height: 18 }} />
                                            {i18n.exportPDFAttribution}
                                        </MainButton>
                                    )}
                                    {volumesInNotification === 'authorized' && (
                                        <MainButton
                                            reverse
                                            sx={{ width: 'auto' }}
                                            onClick={() => {
                                                dispatch(AgriAction.getNotifEdition(exploitation.idExploitation, declaration.year)).then(() => onClose())
                                            }}
                                        >
                                            <DownloadLogo fill={mainColor} style={{ paddingRight: 10, height: 18 }} />
                                            {i18n.exportPDFAuthorized}
                                        </MainButton>
                                    )}
                                </>
                                :
                                null
                            }
                        </Grid>
                    </>
                )}
            </DialogContentMUIDesktop>
        </DialogMUI>
    )
}

ModalDownloadDocument.propTypes = {
    open: PropTypes.bool,
    surveyLoaded: PropTypes.bool,
    onClose: PropTypes.func,
    declaration: PropTypes.instanceOf(PropTypes.shape({})),
    survey: PropTypes.instanceOf(PropTypes.shape({})),
    volumesInNotification: PropTypes.string,
}

export default ModalDownloadDocument