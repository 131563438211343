/* eslint-disable max-nested-callbacks */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { compact, groupBy, keys, orderBy, uniqBy } from 'lodash'
import { styled, Grid, Divider, Icon } from '@mui/material'
import moment from 'moment'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../../referencials/materiels/constants/MaterielConstants'
import AgriAction from '../../agri/actions/AgriAction'
import CmsAction from '../../cms/actions/CmsAction'
import ContactAction from '../../contact/actions/ContactAction'
import { formatMilliers } from '../../../../utils/StringUtil'
import { CHRONICLES_CONSTANTS, SURVEY_TYPE } from '../../agri/constants/AgriConstants'
import { accordionSeparatorGrey, mainColor } from '../../components/styled/theme'
import { getEvolValue } from '../../../../utils/AgriUtils'
import { getDate, getMiniDate, getMiniDateNoYear } from '../../../../utils/DateUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import AccordeonDesktop from '../../components/AccordeonDesktop'
import LoadingCard from '../../components/cards/LoadingCard'
import { ReactComponent as PumpLogo } from '../../../../ressources/static/svg/Pump.svg'
import { ReactComponent as CounterLogo } from '../../../../ressources/static/svg/Counter.svg'
import { HighlightOff } from '@mui/icons-material'
import { MainButton } from '../../components/styled/buttons'
import DtoSamplingPointDecla from '../../agri/dto/exploitation/DtoSamplingPointDecla'
import { push } from 'connected-react-router'
import useApplicationSetting from '../../../../utils/customHooks/useApplicationSetting'

const GridItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
    alignSelf: 'center',
})

const PointConsoDecla = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [contactsLoaded, setContactsLoaded] = useState(false)

    const {
        variousMateriels,
        exploitation,
        declaration,
        survey,
    } = useSelector((store) => ({
        variousMateriels: store.InstallationsReducer.variousMateriels,
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        survey: store.AgriReducer.survey,
    }), shallowEqual)

    const date = moment().valueOf()
    const year = survey.surveyType === SURVEY_TYPE.ANNUAL_SURVEY ? survey.year - 1 : survey.year
    const dispatch = useDispatch()
    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', (setting) => setting ? setting.split(',').map((id) => Number(id)) : [])

    const idInstallation = useMemo(() => Number(props.match.params.id), [])
    const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === idInstallation) || {}
    const { pumpsIds, countersIds } = (() => {
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation && (!m.situationEndDate || m.situationEndDate > date) && (!m.situationDate || m.situationDate < date)) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode) && (!m.situationEndDate || m.situationEndDate > moment(`1/01/${year}`, 'DD/MM/YYYY').valueOf()) && (!m.situationDate || m.situationDate < moment(`31/12/${year}`, 'DD/MM/YYYY').valueOf())) || []
        return { pumpsIds: pointPumps.map(({ idVarious }) => idVarious), countersIds: pointCounters.map(({ idVarious }) => idVarious) }
    })()
    const chroniclesMat = exploitation.link_chronicles.filter(({ idMat }) => [...pumpsIds, ...countersIds].includes(idMat))
    const pointChronicles = chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year &&
        (!linkPoint.startDate || measureDate >= linkPoint.startDate) &&
        (!linkPoint.endDate || measureDate < linkPoint.endDate)
    )

    const pointChroniclePrevYear = orderBy(chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year - 1 &&
        (!linkPoint.startDate || measureDate >= linkPoint.startDate) &&
        (!linkPoint.endDate || measureDate < linkPoint.endDate)
    ), ['measureDate', 'endDate'], 'desc')[0]
    const chroniclesToShow = pointChroniclePrevYear ? [...pointChronicles, pointChroniclePrevYear] : [...pointChronicles]
    const groupedByMat = Object.values(groupBy(chroniclesToShow, 'idMat'))


    const fetchVolumes = () => {
        dispatch(AgriAction.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf())).then(() => {
            dispatch(AgriAction.fetchExploitation()).then(() => {
                setDataLoaded(true)
            })
        })
    }

    useEffect(() => {
        dispatch(CmsAction.fetchCmsByCategory(5))
        dispatch(ContactAction.fetchContacts()).then(() => setContactsLoaded(true))
        if (!exploitation.idExploitation) {
            dispatch(AgriAction.fetchExploitation()).then(() => fetchVolumes())
        } else {
            fetchVolumes()
        }
        window.scrollTo(0, 0)
    }, [declaration])

    const getSampledTotal = (chronicles = []) => {
        if (!chronicles.length) return undefined
        if (chronicles.length === 1) return formatMilliers(chronicles[0].value)

        return formatMilliers(chronicles[0].value - chronicles[chronicles.length - 1].value)
    }

    const getChroniclesByType = (chroniclesByType, type, readingCoefficient = 1) => {
        if (chroniclesByType.length) {
            const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            const currentChronicles = orderedChronicles.filter((c) => moment(c.measureDate).year() >= year)
            const pastChronicles = orderedChronicles.filter((c) => moment(c.measureDate).year() < year)
            return {
                nbLines: currentChronicles.length,
                total: getSampledTotal(currentChronicles),
                content: (
                    <>
                        <GridItem item className='bold' xs={3} {...props} style={{ textAlign: 'start' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                        </GridItem>
                        <GridItem item className='bold' xs={3} {...props} style={{ textAlign: 'start' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.endDate : ''}
                        </GridItem>
                        <GridItem item className='bold' xs={3} {...props} style={{ textAlign: 'end' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                        </GridItem>
                        <GridItem item className='bold' xs={3} style={{ textAlign: 'end' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.accumulationM3 : i18n.evolutionM3}
                        </GridItem>
                        <Grid item xs={12} sx={{ borderBottom: `1px solid ${mainColor}`, margin: '10px 0' }} />
                        {currentChronicles.map((chronicle, i) => {
                            const valueEvol = i !== currentChronicles.length - 1 ? getEvolValue(type, currentChronicles, chronicle, i) : null
                            return (
                                <>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props} style={{ textAlign: 'start' }}>
                                        {new Date(chronicle.measureDate).getFullYear() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props} style={{ textAlign: 'start' }}>
                                        {chronicle.endDate ? (new Date(chronicle.endDate).getFullYear() === year ? getMiniDateNoYear(chronicle.endDate) : getMiniDate(chronicle.endDate)) : ''}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props} style={{ textAlign: 'end' }}>
                                        {formatMilliers(chronicle.value) || 0}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} style={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                        {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                                    </GridItem>
                                </>
                            )
                        })}
                        {pastChronicles.length > 0 && <GridItem item xs={12}>...</GridItem>}
                        {pastChronicles.map((chronicle) => (
                            <>
                                <GridItem item xs={3} {...props} style={{ textAlign: 'start' }}>
                                    {getDate(chronicle.measureDate)}
                                </GridItem>
                                <GridItem item xs={3} {...props} style={{ textAlign: 'start' }}>
                                    {chronicle.endDate ? getDate(chronicle.endDate) : ''}
                                </GridItem>
                                <GridItem item xs={3} {...props} style={{ textAlign: 'end' }}>
                                    {`${formatMilliers(chronicle.value) || 0}${type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? 'm3' : ''}`}
                                </GridItem>
                                <GridItem item xs={3}>
                                    {moment(chronicle.measureDate).year() !== year && <Icon>access_time</Icon>}
                                </GridItem>
                            </>
                        ))}
                        <GridItem item xs={11}>
                            <Divider />
                        </GridItem>
                    </>
                )
            }
        }
        return { nbLines: 0, content: null }
    }

    const getChronicles = (groupedChronicles) => (
        compact(groupedChronicles.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (hasValue(variousMat.administrator) && variousMat.administrator !== exploitation.operatorCode) {
                return null
            }
            const indexChronicles = chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX)
            const estimateChronicles = chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM)
            const readingCoefficient = variousMat?.counter?.readingCoefficient || 1
            const indexs = getChroniclesByType(indexChronicles, CHRONICLES_CONSTANTS.TYPE_INDEX, readingCoefficient)
            const estimates = getChroniclesByType(estimateChronicles, CHRONICLES_CONSTANTS.TYPE_ESTIM, variousMat)
            const isNotEstim = materielsTypeForIndex.includes(variousMat.materielType)
            return {
                nbLines: indexs.nbLines + estimates.nbLines,
                title: (
                    <Grid container flexWrap='nowrap' alignItems='center' justifyContent='space-between'>
                        <Grid container item alignItems='center'>
                            {variousMat.pump ? <PumpLogo style={{ paddingRight: 10 }}/> : <CounterLogo style={{ paddingRight: 10 }}/>} {variousMat.pump ? i18n.pump: i18n.counter} {variousMat.reference} {variousMat?.counter?.informative && ` (${i18n.informative})`}
                        </Grid>
                        <Grid item container alignItems='center' justifyContent='flex-end' sx={{ paddingRight: '3vw' }}>
                            {i18n.total} : {isNotEstim && indexs.total ? `${indexs.total} ${i18n.m3}` : `${estimates.total ?? '-'} ${estimates.total ? i18n.m3 : ''}`}
                        </Grid>
                    </Grid>
                ),
                content: (
                    <Grid
                        item
                        container
                        direction='row'
                        justifyContent='center'
                        sx={{ borderTop: `1px solid ${accordionSeparatorGrey}`, padding: '2vh' }}
                    >
                        {indexs.content}
                        {estimates.content}
                    </Grid>
                )
            }
        }))
    )
    const chroniclesValues = getChronicles(groupedByMat)

    const point = declaration ? declaration.link_declarationInstallation.find((p) => p.idInstallation === idInstallation) : new DtoSamplingPointDecla({})

    const materiels = useMemo(() => {
        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === idInstallation)?.link_equipments || []
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode !== 'd')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')
        const deletedCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode === 'd')
        const exploitationPumps = declaration.link_exploitationMateriel.filter((m) => m.siteType === SITU_POINT_PUMP &&
            m.siteCode === idInstallation &&
            !deletedMats.find((mLink) => m.idVarious === mLink.idMat)
        ) || []
        const mergedPumpsIds = uniqBy([
            ...exploitationPumps.map((m) => m.idVarious),
            ...newMats.map((m) => m.idMat)
        ], obj => obj)
        const exploitationCountersId = compact(mergedPumpsIds.flatMap((pumpId) => {
            const pumpSitu = declaration.link_exploitationMateriel.filter((situ) => situ.siteType === SITU_PUMP_COUNTER &&
                situ.siteCode === pumpId &&
                !deletedCounters.find((mLink) => pumpId === mLink.idMat)
            )
            if (pumpSitu) {
                return pumpSitu.map((s) => s.idVarious)
            }
            return null
        }))
        const newCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode !== 'd' && mergedPumpsIds.includes(mLink.idMat))
        const mergedCountersIds = uniqBy([
            ...exploitationCountersId,
            ...newCounters.map((m) => m.idMatAttachment)
        ], obj => obj)

        return (variousMateriels.filter((m) => [...mergedPumpsIds, ...mergedCountersIds].includes(m.id) &&
        (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode)
        ))
    }, [declaration])

    const onClickNoConso = () => {
        const pointToRemove = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation)
        const points = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        points.push({
            ...pointToRemove,
            link_usagesCurrent: [],
        })
        const matsIds = materiels.map((m) => m.id)
        const chroniclesByMat = groupBy(declaration.link_chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX && matsIds.includes(c.idMat)), 'idMat')
        const indexs = compact(keys(chroniclesByMat).map((matId) => {
            const lastIndex = orderBy(chroniclesByMat[matId], 'measureDate', 'desc')[0]
            if (lastIndex && lastIndex.measureDate < date) {
                return { matId, value: orderBy(chroniclesByMat[matId], 'measureDate', 'desc')[0]?.value }
            }
            return undefined
        }))
        const newIndexs = indexs.map((i) => ({
            matType: 'divers',
            idMat: Number(i.matId),
            measureType: CHRONICLES_CONSTANTS.TYPE_INDEX,
            measureDate: date,
            value: i.value,
            new: true,
        }))
        const newDeclaration = {
            ...declaration,
            link_declarationInstallation: points,
            link_chronicles: [
                ...declaration.link_chronicles,
                ...newIndexs,
            ],
        }
        dispatch(AgriAction.updateDeclaration(newDeclaration, () => setDataLoaded(false)))
    }

    if (variousMateriels.length && dataLoaded && contactsLoaded && exploitation.idExploitation) {
        return (
            <Grid container alignItems='center' sx={{ columnGap: '1vw', rowGap: '2vh' }} justifyContent='flex-end'>
                {
                    chroniclesValues.length ?
                        <Grid item container >
                            {chroniclesValues.map((c) => (
                                <AccordeonDesktop key={c.title} title={c.title} color='white' item xs={12} sx={{ width: '100%' }}>
                                    {c.content}
                                </AccordeonDesktop>
                            ))}
                        </Grid>
                        :
                        <Grid item container alignItems='center' justifyContent='center'>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}><HighlightOff sx={{ fontSize: '96px' }}/></Grid>
                            <Grid item xs={8} sx={{ fontSize: '20px', lineHeight: '20px', letterSpacing: '0.25px', textAlign: 'center' }}>{i18n.pointNotUsed}</Grid>
                        </Grid>
                }
                <MainButton noFullWidth sx={{ width: 'auto', margin: 0 }} onClick={() => dispatch(push(`/index/${props.match.params.id}/materiels/`))} >{i18n.addIndex}</MainButton>
                <MainButton noFullWidth sx={{ width: 'auto', margin: 0 }} reverse onClick={() => onClickNoConso()} >{i18n.noSampling}</MainButton>
            </Grid>
        )
    }
    return <Grid container alignItems='center' justifyContent='center'><LoadingCard /></Grid>
}

PointConsoDecla.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
}

export default PointConsoDecla