import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { Grid, styled } from '@mui/material'
import DtoVariousMatType from '../../referencials/installations/dto/DtoVariousMatType'
import DtoSandreCode from '../../referencials/dto/DtoSandreCode'
import { hasValue } from '../../../../utils/NumberUtil'
import EditableCard from './EditableCard'
import StyledCard from './StyledCard'
import DtoVariousMatCounter from '../../referencials/installations/dto/DtoVariousMatCounter'
import { ReactComponent as EditLogo } from '../../../../ressources/static/svg/Editer.svg'


const Container = styled(Grid)({
    margin: '8 0',
})

class EquipmentCardOld extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
        }
    }

    onCancelEquipment = () => {
        const { data } = this.props
        this.setState({ data })
        this.props.onCancel()
    }

    onSaveEquipment = () => {
        const { data } = this.state
        this.props.onSave(data)
    }

    getContent = (editMode, data) => {
        const { codesSandre, variousMatTypes } = this.props
        if (editMode) {
            return <Fragment/>
        }
        const pumpNature = variousMatTypes.find((t) => t.materielType === data.materielType)
        const pumpCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === data.mobilityCode)
        const pumpType = codesSandre.find((c) => c.field === 'POMPES.TYPE' && c.code === data.pumpType)
        return (
            <>
                <Container>{i18n.name} : {data.name || ''}</Container>
                <Container>{i18n.nature} : {pumpNature ? pumpNature.name : ''}</Container>
                <Container>{i18n.categ} : {pumpCategory ? pumpCategory.name : ''}</Container>
                <Container>{i18n.type} : {pumpType ? pumpType.name : ''}</Container>
                <Container>{i18n.tauxAffectation} : {hasValue(data.assignmentRate) ? `${data.assignmentRate}%` : ''}</Container>
                <Container>{i18n.sharedEquipment} : {data.isShared ? i18n.yes : i18n.no}</Container>
                <Container>{i18n.forRent} : {data.rent ? i18n.yes : i18n.no}</Container>
                <Container>{i18n.debitMax} : {data.maxFlow || ''}</Container>
                <Container>{i18n.debitFonctionnement} : {data.operatingFlow || ''}</Container>
                {hasValue(data.comment) && <Container>{i18n.comment} : {data.comment || ''}</Container>}
            </>
        )
    }

    render() {
        const { title, editTitle, editMode, hideIcon, data, accordeon, expanded, newStyle, codesSandre, variousMatTypes } = this.props
        if (newStyle) {
            const pumpNature = variousMatTypes.find((t) => t.materielType === data.materielType)
            const pumpCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === data.mobilityCode)
            const pumpType = codesSandre.find((c) => c.field === 'POMPES.TYPE' && c.code === data.pumpType)
            const style = { padding: 10, borderTop: '1px solid black' }
            return (
                <StyledCard
                    subTitle={(
                        <Grid container justifyContent='space-between' alignItems='center'>
                            {title}
                            {!hideIcon && <EditLogo fill='black' className='clickable' style={{ height: 30 }} onClick={this.props.toggleEditMode} />}
                        </Grid>
                    )}
                    styleContainer={{ padding: 0 }}
                    styleContent={{ padding: 0 }}
                    styleSubTitle={{ borderTop: '1px solid black', display: 'flex', justifyContent: 'space-between' }}
                >
                    <Grid container style={{ padding: 10 }}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.name}</Grid>
                        <Grid item xs={6} className='bold'>{data?.name || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.nature}</Grid>
                        <Grid item xs={6} className='bold'>{pumpNature?.name || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.categ}</Grid>
                        <Grid item xs={6} className='bold'>{pumpCategory?.name || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.type}</Grid>
                        <Grid item xs={6} className='bold'>{pumpType?.name || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.tauxAffectation}</Grid>
                        <Grid item xs={6} className='bold'>{hasValue(data?.assignmentRate) ? `${data.assignmentRate}%` : '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.sharedEquipment}</Grid>
                        <Grid item xs={6} className='bold'>{data?.isShared ? i18n.yes : i18n.no}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.forRent}</Grid>
                        <Grid item xs={6} className='bold'>{data?.rent ? i18n.yes : i18n.no}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.debitMax}</Grid>
                        <Grid item xs={6} className='bold'>{data?.maxFlow || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.debitFonctionnement}</Grid>
                        <Grid item xs={6} className='bold'>{data?.operatingFlow || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.comment}</Grid>
                        <Grid item xs={6} className='bold'>{data?.comment || '-'}</Grid>
                    </Grid>
                </StyledCard>
            )
        }
        return (
            <EditableCard
                title={title}
                editTitle={editTitle}
                editMode={editMode}
                onCancel={this.onCancelEquipment}
                onSave={this.onSaveEquipment}
                toggleEditMode={this.props.toggleEditMode}
                hideIcon={hideIcon}
                accordeon={accordeon}
                expanded={expanded}
            >
                {this.getContent(editMode, data)}
            </EditableCard>
        )
    }
}

EquipmentCardOld.propTypes = {
    data: PropTypes.instanceOf(DtoVariousMatCounter).isRequired,
    title: PropTypes.string.isRequired,
    editTitle: PropTypes.string,
    editMode: PropTypes.bool,
    selectType: PropTypes.bool,
    hideIcon: PropTypes.bool,
    accordeon: PropTypes.bool,
    expanded: PropTypes.bool,
    newStyle: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    variousMatTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatType)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

EquipmentCardOld.defaultProps = {
    editable: false,
}

const mapStateToProps = (store) => {
    return {
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
    }
}

export default connect(mapStateToProps)(EquipmentCardOld)
