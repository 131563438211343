/* eslint-disable max-nested-callbacks */
/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { LightCard } from '../styled/grid'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DtoSamplingPointDecla from '../../agri/dto/exploitation/DtoSamplingPointDecla'
import ReferencialAction from '../../referencials/actions/ReferencialAction'
import LoadingCard from './LoadingCard'
import Table from '../Table'
import { Add, EditOutlined, HighlightOff } from '@mui/icons-material'
import { DialogContentMUIDesktop, DialogContentTextMUI, DialogMUI, DialogTitleMUIv2 } from '../styled/dialog'
import { MainButton } from '../styled/buttons'
import AgriAction from '../../agri/actions/AgriAction'
import ModalUsageDesktop from '../../enquete/components/modals/ModalUsageDesktop'
import { compact, sumBy, uniqBy } from 'lodash'
import useProgressDispatch from '../../../../utils/customHooks/useProgressDispatch'
import { DECLA_LAST_STEP, SURVEY_TYPE, USAGES_CATEGORY } from '../../agri/constants/AgriConstants'
import { getSetting } from '../../../../utils/FormUtils'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../../referencials/materiels/constants/MaterielConstants'
import { getI18nTitleDataLength } from '../../../../utils/StringUtil'
import useSandreList from '../../../../utils/customHooks/useSandreList'

const PlannedUsesCardDecla = ({ match }) => {
    const [dataLoaded, setDataLoaded] = useState(true)
    const [openDelModal, setOpenDelModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [updateUsage, setUpdateUsage] = useState(false)
    const [currentUsage, setCurrentUsage] = useState({})
    const [defaultCategory, setDefaultCategory] = useState()

    const {
        survey,
        usagesCategories,
        declaration,
        cultures,
    } = useSelector(store => ({
        survey: store.AgriReducer.survey,
        usagesCategories: store.ReferencialReducer.usagesCategories,
        declaration: store.AgriReducer.declaration,
        cultures: store.ReferencialReducer.cultures,
    }), shallowEqual())

    const dispatch = useDispatch()

    const { isLoaded } = useProgressDispatch(() => ([
        dispatch(AgriAction.fetchModesIrrigations()),
        dispatch(ReferencialAction.fetchUsages()),
    ]), [])

    const point = declaration ? declaration.link_declarationInstallation.find((p) => p.idInstallation === Number(match.params.id)) : new DtoSamplingPointDecla({})

    const getListUsages = (agri) => {
        const usagesSelected = point?.link_usagesCurrent
        const filteredUsages = usagesSelected?.filter((u) => {
            const usageCat = usagesCategories.find((uCat) => uCat.idUsage === u.idUsage)
            return usageCat && agri ? usageCat?.category === USAGES_CATEGORY.AGRI : usageCat?.category === USAGES_CATEGORY.NOT_AGRI
        })

        if (filteredUsages?.length) {
            const total = sumBy(filteredUsages, 'requestedYearVolume')
            return (
                {
                    agri,
                    nbUsages: filteredUsages.length,
                    usages: filteredUsages.map((u) => ({ culture: cultures.find((c) => c.id === u.idCulture), ...u })),
                    total,
                }
            )
        }
        return {}
    }

    const countersIds = useMemo(() => {
        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === point?.idInstallation)?.link_equipments || []
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode !== 'd')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')
        const deletedCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode === 'd')
        const exploitationPumps = declaration.link_exploitationMateriel.filter((m) => m.siteType === SITU_POINT_PUMP &&
            m.siteCode === point?.idInstallation &&
            !deletedMats.find((mLink) => m.idVarious === mLink.idMat)
        ) || []
        const mergedPumpsIds = uniqBy([
            ...exploitationPumps.map((m) => m.idVarious),
            ...newMats.map((m) => m.idMat)
        ], obj => obj)
        const exploitationCountersId = compact(mergedPumpsIds.flatMap((pumpId) => {
            const pumpSitu = declaration.link_exploitationMateriel.filter((situ) => situ.siteType === SITU_PUMP_COUNTER &&
                situ.siteCode === pumpId &&
                !deletedCounters.find((mLink) => pumpId === mLink.idMat)
            )
            if (pumpSitu) {
                return pumpSitu.map((s) => s.idVarious)
            }
            return null
        }))
        const newCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode !== 'd' && mergedPumpsIds.includes(mLink.idMat))
        const mergedCountersIds = uniqBy([
            ...exploitationCountersId,
            ...newCounters.map((m) => m.idMatAttachment)
        ], obj => obj)
        return mergedCountersIds
    }, [declaration])

    const typesSols = useSandreList('USAGES.TYPES_SOLS')
    const profondeurSols = useSandreList('USAGES.PROFONDEUR_SOLS')

    const getIrrigatedGround = (u) => {
        if (u?.area) {
            if (u?.groundDepth) {
                return (
                    <>
                        <Grid container item>{i18n.surface} : {u?.area}</Grid>
                        <Grid container item>{i18n.profondeurSol} : {profondeurSols.find(c => c.code === u?.groundDepth)?.name ?? '-'}</Grid>
                        <Grid container item>{i18n.typeSol} : {typesSols.find(c => c.code === u?.groundType)?.name ?? '-'}</Grid>
                        <Grid container item>{i18n.nbRotations} : {u?.nbRotation}</Grid>
                    </>
                )
            }
            return (
                <Grid container>
                    <Grid container item>{i18n.surface} : {u?.area}</Grid>
                    <Grid container item>{i18n.nbRotations} : {u?.nbRotation}</Grid>
                </Grid>
            )
        }
        return undefined
    }

    const getRows = () => (
        [getListUsages(true), getListUsages(false)].map((u, i) => (
            {
                [i18n.usageType]: { value: u.agri || i === 0 ? i18n.agri : i18n.nonAgri },
                [i18n.usage]: { value: `${u.nbUsages ?? 0} ${getI18nTitleDataLength(i18n.usage, i18n.usages, u.nbUsages)}`, },
                [i18n.authorized]: { value: u.total, },
                [i18n.irrigatedGround]: { value: ' ' },
                [i18n.edit]: {
                    value: (
                        <MainButton
                            sx={{ margin: 0 }}
                            startIcon={<Add />}
                            onClick={() => {
                                setCurrentUsage({})
                                setDefaultCategory(i === 0 ? USAGES_CATEGORY.AGRI : USAGES_CATEGORY.NOT_AGRI)
                                setUpdateUsage(false)
                                setOpenEditModal(true)
                            }}
                        >
                            {i18n.add}
                        </MainButton>)
                },
                body: u?.usages?.map(usage => {
                    return (
                        {
                            [i18n.usageType]: { value: '' },
                            [i18n.usage]: { value: usagesCategories.find(cat => cat.idUsage === usage.idUsage)?.description },
                            [i18n.authorized]: { value: usage.requestedYearVolume || '-' },
                            [i18n.irrigatedGround]: { value: getIrrigatedGround(usage) || '-' },
                            [i18n.edit]: { value: (
                                <Grid container justifyContent='center' sx={{ columnGap: '0.5vw' }}>
                                    <EditOutlined className='clickable' onClick={() => {
                                        setCurrentUsage(usage)
                                        setUpdateUsage(true)
                                        setOpenEditModal(true)
                                    }} />
                                    <HighlightOff className='clickable' onClick={() => {
                                        setCurrentUsage(usage)
                                        setOpenDelModal(true)
                                    }} />
                                </Grid>
                            ) },
                        }
                    )
                }) ?? []
            }
        )
        )
    )

    const onSaveUsage = (usages) => {
        const newPoints = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        newPoints.push({
            ...point,
            link_usagesCurrent: usages,
        })
        const updateDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= DECLA_LAST_STEP.COMMENTS_STEP ? declaration.lastStep : DECLA_LAST_STEP.COMMENTS_STEP,
            link_declarationInstallation: newPoints,
        }
        setDataLoaded(false)
        dispatch(AgriAction.updateDeclaration(updateDeclaration, () => {
            setDataLoaded(true)
        }))
    }

    return (
        <LightCard
            item
            container
            xs={12}
            sx={{
                padding: '3vh',
                rowGap: '2vh',
            }}
        >
            <Grid item xs={12} fontSize={22} className='bold' >
                {i18n.realsUsesOn} {survey.surveyType === SURVEY_TYPE.ANNUAL_SURVEY ? survey.year - 1 : survey.year}
            </Grid>
            <Grid item xs={12}>
                {
                    isLoaded && dataLoaded ?
                        (getSetting(survey.link_params, 'consos') || '1') !== '0' && (
                            <Grid item xs={12} style={{ backgroundColor: 'white', borderRadius: '20px', padding: '2vh' }}>
                                <Table
                                    bodyColor='white'
                                    transparent
                                    accordion

                                    rows={getRows()}

                                    headers={[
                                        [
                                            { value: i18n.usageType, colSpan: 1, group: 0 },
                                            { value: i18n.usage, colSpan: 1, group: 1 },
                                            { value: i18n.authorized, colSpan: 1, group: 2 },
                                            { value: i18n.irrigatedGround, colSpan: 1, group: 3 },
                                            { colSpan: 1, group: 4 },
                                        ],
                                    ]}
                                    headersLabel={[i18n.usageType, i18n.usage, i18n.authorized, i18n.irrigatedGround, i18n.edit]}
                                />
                            </Grid>
                        )
                        :
                        <Grid container alignItems='center' justifyContent='center'><LoadingCard /></Grid>
                }
            </Grid>
            {
                openDelModal &&
                <DialogMUI
                    open={openDelModal}
                    keepMounted
                    onClose={() => {
                        setOpenDelModal(false)
                    }}
                >
                    <DialogTitleMUIv2 onClick={() => setOpenDelModal(false)}>
                        {i18n.useRemoval}
                    </DialogTitleMUIv2>
                    <DialogContentMUIDesktop>
                        <DialogContentTextMUI>
                            {i18n.wantToRemoveUse}{currentUsage?.description}
                        </DialogContentTextMUI>
                        <Grid item container justifyContent='center' sx={{ columnGap: '1rem' }}>
                            <MainButton
                                reverse
                                sx={{ width: 'auto' }}
                                onClick={() => {
                                    setOpenDelModal(false)
                                    setCurrentUsage({})
                                }}
                            >
                                {i18n.cancel}
                            </MainButton>
                            <MainButton
                                sx={{ width: 'auto' }}
                                onClick={() => {
                                    onSaveUsage(point.link_usagesCurrent.filter((u) => u.idInstallation === Number(match.params.id) && u.idProvisionalUsage !== currentUsage.idProvisionalUsage))
                                    setOpenDelModal(false)
                                    setCurrentUsage({})
                                }}
                            >
                                {i18n.delete}
                            </MainButton>
                        </Grid>
                    </DialogContentMUIDesktop>
                </DialogMUI>
            }
            {
                openEditModal &&
                <ModalUsageDesktop
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    onSave={(usages) => onSaveUsage(usages)}
                    usage={currentUsage}
                    update={updateUsage}
                    currentYear={true}
                    countersId={countersIds}
                    defaultCategory={defaultCategory}
                    isReal
                />
            }
        </LightCard>
    )
}

PlannedUsesCardDecla.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
}

export default PlannedUsesCardDecla