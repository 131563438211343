import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import HomeAction from '../offline/actions/HomeAction'
import { getLoginPassword, getPayload } from '../../utils/ActionUtils'
import { isOnline } from '../../utils/StoreUtils'

const checkAuth = (logout) => {
    const payload = getPayload()
    const logPass = getLoginPassword()
    if (!payload) {
        return false
    } else if (payload && logPass) {
        return true
    } else if (isOnline() && moment().isAfter(moment(JSON.parse(payload).exp))) {
        logout()
        return false
    }
    return false
}

const AuthRoute = (props) => {
    const component = window.location.href.split('#')[1] === '/' ? <Redirect to='/home' /> : <props.component {...props} />
    return checkAuth(props.logout) ? component : <Redirect to='/login' />
}

const mapDispatchToProps = {
    logout: HomeAction.logout,
}

AuthRoute.propTypes = {
    path: PropTypes.string,
    logout: PropTypes.func,
}

export default connect(null, mapDispatchToProps)(AuthRoute)
