import { Grid } from '@mui/material'
import { React } from 'react'
import { screenColor } from './styled/theme'
import { propTypes } from 'react-spinkit'

const MainContainer = ({ children }) => {
    return (
        <Grid item container position='relative' padding='6vh 3vw' marginLeft={'12.75vw'} width={'87.25vw'} height='100%' justifyContent='flex-end'>
            <Grid item container backgroundColor={screenColor} borderRadius='20px' height='100%'>
                { children }
            </Grid>
        </Grid>
    )
}

MainContainer.propTypes = {
    children: propTypes.element,
}

export default MainContainer