import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { hasValue } from '../../../../utils/NumberUtil'
import { EditOutlined } from '@mui/icons-material'
import { LightCard } from '../styled/grid'
import DtoVariousMateriel from '../../referencials/installations/dto/DtoVariousMateriel'

const CounterCard = ({ counter, noLightCard, sx, onClick = () => {} }) => {
    const {
        variousMatTypes,
    } = useSelector(store => ({
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
    }), shallowEqual)

    const matType = useMemo(() => variousMatTypes.find((t) => t.materielType === counter.materielType), [])

    const content = (
        <Grid container direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
            <Grid item container justifyContent='space-between'>
                <Grid item fontSize={22} className='bold' sx={{ marginBottom: '20px' }}>{`${i18n.counter} ${counter.reference}`}</Grid>
                <EditOutlined onClick={onClick} />
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.name}</Grid>
                <Grid item className='bold'>{counter?.name || '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.type}</Grid>
                <Grid item className='bold'>{matType?.name || '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.coefLecture}</Grid>
                <Grid item className='bold'>{counter?.readingCoefficient ?? '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.sharedEquipment}</Grid>
                <Grid item className='bold'>{counter?.isShared ? i18n.yes : i18n.no}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.tauxAffectation}</Grid>
                <Grid item className='bold'>{hasValue(counter.assignmentRate) ? `${counter.assignmentRate}%` : '-'}</Grid>
            </Grid>
        </Grid>
    )

    if (noLightCard) {
        return content
    }
    return (
        <LightCard
            item
            container
            direction='column'
            justifyContent='space-between'
            className='clickable'
            sx={{ marginBottom: '20px', ...sx }}
        >
            {content}
        </LightCard>
    )
}

CounterCard.propTypes = {
    counter: PropTypes.instanceOf(DtoVariousMateriel).isRequired,
    noLightCard: PropTypes.bool,
    sx: PropTypes.shape({}),
    onClick: PropTypes.func,
}

export default CounterCard
