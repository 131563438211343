/* eslint-disable indent */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { compact, uniqBy } from 'lodash'
import { Grid } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import LocalizationCard from '../components/cards/LocalizationCard'
import AssociatedResourceCard from '../components/cards/AssociatedResourceCard'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import OuvrageCard from '../components/cards/OuvrageCard'
import TankCard from '../components/cards/TankCard'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import Accordeon from '../components/Accordeon'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import ContactAction from '../contact/actions/ContactAction'
import DtoContact from '../referencials/dto/DtoContact'
import AgriAction from '../agri/actions/AgriAction'
import { POINT_STATUS } from '../referencials/installations/constants/InstallationsConstants'
import ModalEditPoint from '../points/ModalEditPoint'
import { hasValue } from '../../../utils/NumberUtil'
import ModalEditCounter from '../referencials/materiels/components/ModalEditCounter'
import ModalEditPump from '../referencials/materiels/components/ModalEditPump'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import { mainColor } from '../components/styled/theme'
import LoadingCard from '../components/cards/LoadingCard'
import { LightCard } from '../components/styled/grid'
import { EditOutlined } from '@mui/icons-material'
import { getI18nTitleDataLength } from '../../../utils/StringUtil'
import PumpCard from '../components/cards/PumpCard'
import CounterCard from '../components/cards/CounterCard'

class PointDetailsExploit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            point: props.installation || {},
            pointLoaded: false,
            editMode: false,
        }
    }

    componentDidMount() {
        const { match } = this.props
        this.props.fetchInstallation(match.params.id).then(() => {
            this.setInstallation()
        })
    }

    componentWillUnmount() {
        this.props.resetInstallation()
    }

    setInstallation = () => {
        const { installation, contacts } = this.props
        if (!contacts.length) {
            this.props.fetchContacts().then(() => {
                this.setContributors(installation)
            })
        } else {
            this.setContributors(installation)
        }
        this.props.setTitle([{
            title: i18n?.waterPoint,
            href: '/exploitation',
        }])
        window.scrollTo(0, 0)
    }

    setContributors = (point) => {
        const { exploitation } = this.props
        const linkPoint = exploitation.link_samplings ? exploitation.link_samplings.find((p) => p.idInstallation === point.id) : null
        this.setState({ point, linkPoint, pointLoaded: true })
    }

    getAssociatedResources = () => {
        const {
            point,
            linkPoint,
        } = this.state
        let associatedResources = linkPoint ? linkPoint?.link_samples[0] : {}
        associatedResources = {
            ...associatedResources,
            managementCode: point.managementCode,
            subManagementCode: point.subManagementCode,
        }
        return associatedResources
    }

    getTypePrel = (key) => {
        switch (key) {
            case 1:
                return 'PREL_AGRI.NATURE_PRELEVEMENT_ESO'
            case 2:
                return 'PREL_AGRI.NATURE_PRELEVEMENT_ESU'
            default:
                return 'PREL_AGRI.NATURE_PRELEVEMENT'
        }
    }

    getNature = () => {
        const associatedResources = this.getAssociatedResources()
        if (associatedResources) {
            const { codesSandre } = this.props
            const codeType = this.getTypePrel(associatedResources.sampleType)
            const codeFind = codesSandre.find((c) => c.field === codeType && c.code === associatedResources.sampleNature)
            return codeFind ? codeFind.name : ''
        }
        return ''
    }

    getStatus = () => {
        const { linkPoint } = this.state
        if (linkPoint) {
            const status = POINT_STATUS.find(({ code }) => code === linkPoint.stateCode) || {}
            return status.label || ''
        }
        return ''
    }

    onChangePoint = (obj) => {
        this.setState(({ point }) => ({ point: { ...point, ...obj } }))
    }

    onSavePoint = () => {
        const { point } = this.state
        const { match } = this.props
        this.props.updateInstallation(point).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.setInstallation()
                this.setState({ editMode: false })
            })
        })
    }

    onChangePump = (obj) => {
        this.setState(({ pump }) => ({ pump: { ...pump, ...obj } }))
    }

    onSavePump = () => {
        const { pump } = this.state
        const { match } = this.props
        this.props.updateVariousMateriel(pump).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.setInstallation()
                this.setState({ editPump: false, pump: null })
            })
        })
    }

    onChangeCounter = (obj) => {
        this.setState(({ counter }) => ({ counter: { ...counter, ...obj } }))
    }

    onSaveCounter = () => {
        const { counter } = this.state
        const { match } = this.props
        this.props.updateVariousMateriel(counter).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.setInstallation()
                this.setState({ editCounter: false, counter: null })
            })
        })
    }

    render() {
        const { linkPoint, point, pointLoaded, editMode, editPump, editCounter, pump, counter } = this.state
        const { variousMatSituations, variousMateriels, exploitation } = this.props

        if (!pointLoaded) {
            return <LoadingCard />
        }

        const nbTanks = linkPoint?.link_sampleTanks?.length || 0
        const dataMats = uniqBy(variousMatSituations.filter((m) => m.siteType === 7 && m.siteCode === point.id), v => [v.idVarious, v.siteCode, v.siteCode, v.siteType, v.statusCode].join())
        const nbMats = dataMats.length || 0

        return (
            <Grid
                container
                direction='column'
                alignItems='stretch'
                justifyContent='flex-start'
                sx={{ padding: '8px 1.5rem', color: mainColor }}
            >
                <Grid item fontSize={24} sx={{ marginBottom: '20px' }}>{point?.location}</Grid>
                <LightCard
                    item
                    container
                    direction='column'
                    justifyContent='space-between'
                    className='clickable'
                    sx={{ marginBottom: '20px' }}
                >
                    <Grid item container justifyContent='space-between'>
                        <Grid item fontSize={22} className='bold' sx={{ marginBottom: '20px' }}>{i18n.descriptive}</Grid>
                        {!editMode && <EditOutlined onClick={() => this.setState({ editMode: true })} />}
                    </Grid>
                    <Grid container direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
                        <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                            <Grid item>{i18n.name}</Grid>
                            <Grid item className='bold'>{point?.name || '-'}</Grid>
                        </Grid>
                        <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                            <Grid item>{i18n.code}</Grid>
                            <Grid item className='bold'>{point?.code || '-'}</Grid>
                        </Grid>
                        <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                            <Grid item>{i18n.status}</Grid>
                            <Grid item className='bold'>{this.getStatus()}</Grid>
                        </Grid>
                        <Grid item container justifyContent='space-between'>
                            <Grid item>{i18n.nature}</Grid>
                            <Grid item className='bold'>{this.getNature()}</Grid>
                        </Grid>
                    </Grid>
                </LightCard>
                <LocalizationCard data={point} />
                <AssociatedResourceCard data={this.getAssociatedResources()} />
                {linkPoint?.link_sampleCasings?.map((ouvrage) => (
                    <OuvrageCard
                        title={i18n.struct}
                        ouvrage={ouvrage}
                        newStyle
                        hideIcon
                    />
                ))}
                <div style={{ paddingBottom: '20px' }}>
                    <Accordeon title={`${getI18nTitleDataLength(i18n.waterRetention, i18n.waterRetentions, nbTanks)} (${nbTanks})`}>
                        {linkPoint.link_sampleTanks.map((retenue) => (
                            <div style={{ marginBottom: '20px' }}>
                                <TankCard
                                    retenue={retenue}
                                    noLightCard
                                />
                            </div>
                        ))}
                    </Accordeon>
                </div>
                {!!variousMatSituations.length && !!variousMateriels.length && (
                    <div style={{ paddingBottom: '20px' }}>
                        <Accordeon title={`${getI18nTitleDataLength(i18n.materiel, i18n.materiels, nbMats)} (${nbMats})`}>
                            {compact(dataMats.map((d) => {
                                const pompeSituation = variousMatSituations.find((m) => m.siteType === 8 && m.siteCode === d.idVarious)
                                const pompeMat = variousMateriels.find((m) => m.id === d.idVarious)
                                const pumpRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 1 && m.idElement1 === parseInt(point.id) && m.idElement2 === d.idVarious) || {}
                                if (pompeSituation && pompeMat) {
                                    const pompeInfos = {
                                        ...pompeSituation,
                                        ...pompeMat,
                                        ...pompeMat.pump,
                                        assignmentRate: pumpRep.repartition,
                                    }
                                    const compteur = variousMateriels.find((c) => c.id === pompeSituation.idVarious && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode))
                                    const counterRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 2 && m.idElement1 === d.idVarious && m.idElement2 === pompeSituation.id) || {}
                                    const compteurInfos = {
                                        ...compteur,
                                        ...compteur?.counter,
                                        assignmentRate: counterRep?.repartition,
                                    }
                                    return (
                                        <>
                                            <div style={{ marginBottom: '20px' }}>
                                                <PumpCard
                                                    pump={pompeInfos}
                                                    noLightCard
                                                    onClick={() => this.setState({ editPump: !editPump, pump: pompeInfos })}
                                                />
                                            </div>
                                            {compteur && (
                                                <div style={{ marginBottom: '20px' }}>
                                                    <CounterCard
                                                        counter={compteurInfos}
                                                        noLightCard
                                                        onClick={() => this.setState({ editCounter: !editCounter, counter: compteurInfos })}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                                return null
                            }))}
                        </Accordeon>
                    </div>
                )}
                {editPump && (
                    <ModalEditPump
                        open={editPump}
                        toggleModal={() => this.setState({ editPump: false, pump: null })}
                        onChange={this.onChangePump}
                        onSave={this.onSavePump}
                        pump={pump}
                    />
                )}
                {editCounter && (
                    <ModalEditCounter
                        open={editCounter}
                        toggleModal={() => this.setState({ editCounter: false, counter: null })}
                        onChange={this.onChangeCounter}
                        onSave={this.onSaveCounter}
                        counter={counter}
                    />
                )}
                {editMode && (
                    <ModalEditPoint
                        open={editMode}
                        toggleModal={() => this.setState({ editMode: false })}
                        onChange={this.onChangePoint}
                        onSave={this.onSavePoint}
                        point={point}
                        nature={this.getNature()}
                        stateCode={linkPoint.stateCode}
                    />
                )}
            </Grid>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        contacts: store.ContactReducer.contacts,
        exploitation: store.AgriReducer.exploitation,
        codesSandre: store.ReferencialReducer.codesSandre,
        contributors: store.ReferencialReducer.contributors,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchInstallation: InstallationsAction.fetchInstallation,
    updateInstallation: InstallationsAction.updateInstallation,
    fetchContacts: ContactAction.fetchContacts,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    updateVariousMateriel: MaterielAction.updateVariousMateriel,
    resetInstallation: InstallationsAction.resetInstallation,
    push,
}

PointDetailsExploit.propTypes = {
    installation: PropTypes.instanceOf(DtoInstallation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    setTitle: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    fetchInstallation: PropTypes.func,
    updateInstallation: PropTypes.func,
    updateVariousMateriel: PropTypes.func,
    resetInstallation: PropTypes.func,
    push: PropTypes.func,
    match: PropTypes.instanceOf(PropTypes.shape({})),
    location: PropTypes.instanceOf(PropTypes.shape({})),
    fetchContacts: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointDetailsExploit)
