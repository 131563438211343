import { FULL_RESET } from '../constants/HomeConstants'
import {
    ADD_JOURNAL,
    REMOVE_JOURNAL,
    REMOVE_ALL_JOURNAL,
    ADD_METADATA,
    CLEAR_METADATA,
    JOURNAL_SYNC,
    FORCE_DISCONNECTED,
    UPDATE_JOURNAL,
} from '../constants/OfflineConstant'
import DtoJournal from '../dto/DtoJournal'
import DtoMetadata from '../dto/DtoJournal'
import UUID from 'uuid'

export const store = {
    journal: [],
    metadata: [],
    offlineMode: false,
}

export function OfflineReducer(state = {}, action) {
    switch (action.type) {
        case FORCE_DISCONNECTED:
            return {
                ...state,
                offlineMode: action.forceDisconnected,
            }
        case ADD_JOURNAL:
            const uuid = action.journal.idJournal || UUID.v4()
            return {
                ...state,
                journal: [...state.journal, new DtoJournal(action.journal, uuid)],
            }
        case UPDATE_JOURNAL:
            return {
                ...state,
                journal: [...state.journal.filter(j => j.id !== action.journal.id), action.journal],
            }
        case REMOVE_JOURNAL:
            return {
                ...state,
                journal: state.journal.filter((j) => j.id !== action.journalId),
            }
        case REMOVE_ALL_JOURNAL:
            return {
                ...state,
                journal: [],
            }
        case JOURNAL_SYNC:
            /* To be put back if we decide to change the way things work
            const failedActionsIds = action.actionsFailed.map(({ id }) => id)
            const filteredJournalAfterSync = state.journal.filter(j => failedActionsIds.includes(j.id))
            const newJournal = filteredJournalAfterSync.map(j => new DtoJournal(j, j.id, true, true))
            */
            const failedActionsIds = action.actionsFailed.map(({ id }) => id)
            const newJournal = state.journal.map(j => new DtoJournal(j, j.id, true, failedActionsIds.includes(j.id)))
            return {
                ...state,
                journal: newJournal,
            }
        case ADD_METADATA:
            const metadata = [...state.metadata, new DtoMetadata(action.metadata)]
            return { ...state, metadata }
        case CLEAR_METADATA:
            return { ...state, metadata: [] }
        case FULL_RESET:
            return {
                ...store,
                journal: [],
                metadata: [],
            }


        default:
            return state
    }
}
