import { Grid } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { mainColor, textColor } from '../../components/styled/theme'
import { Add, Close, Done, EditOutlined, KeyboardArrowLeft, Remove } from '@mui/icons-material'
import i18n from 'simple-react-i18n'
import { compact, template } from 'lodash'
import moment from 'moment'
import { MainButton, TextButton } from '../../components/styled/buttons'
import { LightCard } from '../../components/styled/grid'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import InstallationsAction from '../../referencials/installations/actions/InstallationsAction'
import PropTypes from 'prop-types'
import { getStateLabel, getTypePrel } from '../../../../utils/AgriUtils'
import LoadingCard from '../../components/cards/LoadingCard'
import DtoContact from '../../referencials/dto/DtoContact'
import { formatPhone, getI18nTitleDataLength } from '../../../../utils/StringUtil'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../../referencials/materiels/constants/MaterielConstants'
import AccordeonDesktop from '../../components/AccordeonDesktop'
import PumpCardDecla from '../../components/cards/PumpCardDecla'
import { ReactComponent as PumpLogo } from '../../../../ressources/static/svg/Pump.svg'
import { ReactComponent as CounterLogo } from '../../../../ressources/static/svg/Counter.svg'
import CounterCardDecla from '../../components/cards/CounterCardDecla'
import TankCardDecla from '../../components/cards/TankCardDecla'
import ConsoCardDecla from '../../components/cards/ConsoCardDecla'
import PlannedUsesCardDecla from '../../components/cards/PlannedUsesCardDecla'
import { push } from 'react-router-redux'
import { InputMUI } from '../../components/styled/inputs'
import useLocalStorage from '../../../../utils/customHooks/useLocalStorage'
import { DECLA_POINTS_ADVANCEMENT, POINT_ADVANCEMENT_DECLARATION } from '../../agri/constants/AgriConstants'

const PointDetailsDeclaDesktop = (props) => {
    const {
        installation,
        declaration,
        codesSandre,
        exploitation,
        contacts,
        citiesIndex,
        variousMateriels,
    } = useSelector((store) => ({
        installation: store.InstallationsReducer.installation,
        declaration: store.AgriReducer.declaration,
        codesSandre: store.ReferencialReducer.codesSandre,
        exploitation: store.AgriReducer.exploitation,
        contacts: store.ContactReducer.contacts,
        citiesIndex: store.CityReducer.citiesIndex,
        variousMateriels: store.InstallationsReducer.variousMateriels,
    }), shallowEqual)

    const [pointsAdvancement, setPointsAdvancement] = useLocalStorage(DECLA_POINTS_ADVANCEMENT)

    const [editMode, setEditMode] = useState(false)
    const [pointName, setPointName] = useState(installation?.name)
    const [infosExpanded, setInfosExpanded] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [matExpanded, setMatExpanded] = useState()
    const [retExpanded, setRetExpanded] = useState()

    const date = moment().valueOf()
    const year = moment().year()

    const dispatch = useDispatch()

    const handleMatChange = (panel) => {
        if (panel === matExpanded) {
            setMatExpanded()
        } else {
            setMatExpanded(panel)
        }
    }

    const handleRetChange = (panel) => {
        if (panel === retExpanded) {
            setRetExpanded()
        } else {
            setRetExpanded(panel)
        }
    }

    useEffect(() => {
        const point = pointsAdvancement.find(a => a.idPoint === Number(props.match.params.id) && a.idDeclaration === declaration.idDeclaration) || {}
        if (point.status !== POINT_ADVANCEMENT_DECLARATION.ONGOING) {
            const newAdvancements = [
                ...pointsAdvancement.filter(a => a.idPoint !== point.idPoint && a.idDeclaration === declaration.idDeclaration),
                {
                    idDeclaration: declaration.idDeclaration,
                    idPoint: point.idPoint,
                    status: POINT_ADVANCEMENT_DECLARATION.ONGOING
                },
            ]
            setPointsAdvancement(newAdvancements)
        }

        dispatch(InstallationsAction.fetchInstallation(props.match.params.id)).then(() => {
            setDataLoaded(true)
            setPointName(installation?.name)
        })
    }, [])

    const linkPoint = useMemo(() => (
        exploitation.link_samplings ? exploitation.link_samplings.find((p) => p.idInstallation === installation.id) : null
    ), [exploitation, installation])

    const updatedTanks = useMemo(() => (
        linkPoint?.link_sampleTanks?.map(tank => {
            const newTank = declaration.link_declarationInstallation.find(decla => decla.idInstallation === Number(props.match.params.id))
                ?.link_sampleTanks?.find(declaTank => declaTank.idTank === tank.idTank)
            if (newTank) {
                return { ...newTank, id: tank.id }
            }
            return tank
        })
    ), [declaration, linkPoint])

    const contact = useMemo(() => contacts.find(c => c.id === installation?.ownerCode) || new DtoContact({}), [contacts, installation])

    const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === linkPoint?.idInstallation && (!m.situationEndDate || (m.situationEndDate > date)) && (!m.situationDate || (m.situationDate < date))) || []
    const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode) && (!m.situationEndDate || (m.situationEndDate > date)) && (!m.situationDate || (m.situationDate < date))) || []
    const nbTanks = linkPoint?.link_sampleTanks?.length || 0
    const updatedMats = [ ...declaration.link_pumps, ...declaration.link_counters ]
    const dataMats = [...pointPumps, ...pointCounters].map(p => {
        const mat = variousMateriels.find(m => m.id === p.idVarious)
        const formatedMat = mat.pump ? { ...mat, ...mat.pump } : { ...mat, ...mat.counter }
        const newMat = updatedMats.find(m => mat.id === m.id)
        if (newMat) {
            return { ...formatedMat, ...newMat }
        }
        return formatedMat
    }).filter(mat => !mat?.administrator || (mat?.administrator === exploitation?.operatorCode))
    const nbMats = dataMats.length || 0


    const associatedResources = useMemo(() => {
        const res = linkPoint ? linkPoint?.link_samples[0] : {}
        return {
            ...res,
            managementCode: installation.managementCode,
            subManagementCode: installation.subManagementCode,
        }
    }, [linkPoint])

    const getNature = () => {
        const typePrel = getTypePrel(associatedResources.sampleType)
        const codeFind = codesSandre.find((c) => c.field === typePrel && c.code === associatedResources.sampleNature)
        return codeFind ? codeFind.name : '-'
    }

    const getStatus = () => (
        declaration?.link_declarationInstallation?.find(instal => installation.id === instal.idInstallation)?.stateCode ?? 2
    )

    const savePointName = () => {
        if (editMode) {
            dispatch(InstallationsAction.updateInstallation({ ...installation, name: pointName })).then(() => {
                dispatch(InstallationsAction.fetchInstallation(props.match.params.id)).then(() => {
                    setEditMode(!editMode)
                })
            })
        } else {
            setEditMode(!editMode)
        }
    }

    if (!dataLoaded) {
        return <LoadingCard />
    }

    return (
        <Grid
            container
            rowGap='3vh'
            alignContent='start'
            justifyContent='space-between'
            sx={{
                padding: '5vh',
                maxHeight: 'calc(100% - 4vh)',
                overflowY: 'hidden',
                width: '100%',
            }}
        >
            <Grid container item alignItems='center' justifyContent='space-between' color={textColor}>
                <Grid item container xs='auto'>
                    <Grid item
                        sx={{ paddingRight: '1.5vw' }}
                        className='clickable'
                        onClick={() => dispatch(push('/declaration'))}
                    >
                        <KeyboardArrowLeft sx={{ fontSize: '30px' }}/>
                    </Grid>
                    <Grid item sx={{ fontSize: '22px', lineHeight: '28px' }} >
                        {i18n.backToPointsList}
                    </Grid>
                </Grid>
                <MainButton
                    noFullWidth
                    sx={{ width: 'auto', margin: '0' }}
                    onClick={() => dispatch(push(`/declaration/point/${props.match.params.id}/usages`))}
                >
                    {i18n.next}
                </MainButton>
            </Grid>
            <Grid
                item
                container
                sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.15px',
                    color: mainColor
                }}
            >
                {template(i18n.pleaseCheckGivenInfosForEachPoints)({ year })}
            </Grid>
            <Grid item container xs={3.375} alignContent='flex-start' sx={{ overflowY: 'auto', height: 'calc(100% - 8vh)' }}>
                <Grid container item rowGap='2vh'>
                    <LightCard
                        item
                        container
                        rowGap='3vh'
                        xs={12}
                        direction='column'
                        sx={{
                            padding: '3vh'
                        }}
                    >
                        <Grid item container justifyContent='space-between'>
                            <Grid item fontSize={22} className='bold' >{i18n.descriptive}</Grid>
                            {!editMode ?
                                (<EditOutlined
                                    className='clickable'
                                    onClick={() => savePointName()}
                                />)
                                :
                                (
                                    <Grid item>
                                        <Close className='clickable' onClick={() => setEditMode(false)}/>
                                        <Done className='clickable' onClick={() => savePointName()} />
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container direction='column' rowGap='1vh'>
                            <Grid item container justifyContent='space-between' alignItems='center'>
                                <Grid item>{i18n.name}</Grid>
                                {!editMode ?
                                    (<Grid item className='bold'>{pointName || '-'}</Grid>)
                                    :
                                    (<InputMUI
                                        noFullWidth
                                        value={pointName}
                                        onKeyDown={(e) => e.key === 'Enter' ? savePointName() : null}
                                        onChange={(e) => setPointName(e.target.value)}
                                        style={{ width: 'auto' }}
                                    />)
                                }
                            </Grid>
                            <Grid item container justifyContent='space-between'>
                                <Grid item>{i18n.code}</Grid>
                                <Grid item className='bold'>{installation?.code || '-'}</Grid>
                            </Grid>
                            <Grid item container justifyContent='space-between'>
                                <Grid item>{i18n.status}</Grid>
                                <Grid item className='bold'>{getStateLabel(getStatus())}</Grid>
                            </Grid>
                            <Grid item container justifyContent='space-between'>
                                <Grid item>{i18n.nature}</Grid>
                                <Grid item className='bold'>{getNature()}</Grid>
                            </Grid>
                            {
                                infosExpanded ?
                                    (
                                        <>
                                            <Grid item container justifyContent='space-between'>
                                                <Grid item>{i18n.lieuDit}</Grid>
                                                <Grid item className='bold'>{installation?.location || '-'}</Grid>
                                            </Grid>
                                            <Grid item container justifyContent='space-between'>
                                                <Grid item>{i18n.codeParcelle}</Grid>
                                                <Grid item className='bold'>{installation?.link_geo?.length ? `${installation.link_geo[0].parcel} ${installation.link_geo[0].section}` : '-'}</Grid>
                                            </Grid>
                                            <Grid item container justifyContent='space-between'>
                                                <Grid item>{i18n.x}</Grid>
                                                <Grid item className='bold'>{installation?.x || '-'}</Grid>
                                            </Grid>
                                            <Grid item container justifyContent='space-between'>
                                                <Grid item>{i18n.y}</Grid>
                                                <Grid item className='bold'>{installation?.y || '-'}</Grid>
                                            </Grid>
                                            <Grid item fontSize={22} className='bold' sx={{ padding: '1.5vh 0' }}>{i18n.owner}</Grid>
                                            <Grid item container justifyContent='space-between'>
                                                <Grid item>{i18n.name}</Grid>
                                                <Grid item className='bold'>{contact?.name || '-'}</Grid>
                                            </Grid>
                                            <Grid item container justifyContent='space-between'>
                                                <Grid item>{i18n.address}</Grid>
                                                <Grid item className='bold'>
                                                    {
                                                        !contact?.road && !contact?.postalCode && !citiesIndex[contact?.cityCode]?.name ?
                                                            '-'
                                                            :
                                                            contact?.road ? `${contact?.road}` : ''}{contact?.postalCode ? `, ${contact?.postalCode}` : ''}{citiesIndex[contact?.cityCode]?.name ? `, ${citiesIndex[contact?.cityCode]?.name}` : ''
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item container justifyContent='space-between'>
                                                <Grid item>{i18n.phoneTel}</Grid>
                                                <Grid item className='bold'>{formatPhone(contact?.phoneTel) || '-'}</Grid>
                                            </Grid>
                                            <Grid item container justifyContent='space-between'>
                                                <Grid item>{i18n.phoneMobile}</Grid>
                                                <Grid item className='bold'>{formatPhone(contact?.mobile) || '-'}</Grid>
                                            </Grid>
                                        </>
                                    )
                                    :
                                    null
                            }
                        </Grid>
                        {
                            infosExpanded ?
                                (
                                    <Grid container justifyContent='flex-end'>
                                        <TextButton
                                            noFullWidth
                                            startIcon={<Remove />}
                                            sx={{ width: 'auto' }}
                                            onClick={() => {
                                                setInfosExpanded(false)
                                            }}
                                        >
                                            {i18n.showLess}
                                        </TextButton>
                                    </Grid>
                                )
                                :
                                (
                                    <Grid container justifyContent='flex-end'>
                                        <TextButton
                                            noFullWidth
                                            startIcon={<Add />}
                                            sx={{ width: 'auto' }}
                                            onClick={() => {
                                                setInfosExpanded(true)
                                            }}
                                        >
                                            {i18n.showMore}
                                        </TextButton>
                                    </Grid>
                                )
                        }
                    </LightCard>
                    <LightCard
                        item
                        container
                        xs={12}
                        sx={{
                            padding: '3vh 3vh 0'
                        }}
                    >
                        <Grid item fontSize={22} className='bold' >
                            {`${getI18nTitleDataLength(i18n.materiel, i18n.materiels, nbMats)} (${nbMats})`}
                        </Grid>
                        <Grid item xs ={12}>
                            {
                                compact(dataMats.map((mat, i) => {
                                    if (mat?.pump) {
                                        return (
                                            <AccordeonDesktop
                                                expanded={matExpanded === i ?? false}
                                                onChange={() => handleMatChange(i)}
                                                sx={{ width: '100%' }}
                                                title={
                                                    <Grid
                                                        item
                                                        sx={{
                                                            fontSize: '18px',
                                                            lineHeight: '20px',
                                                            letterSpacing: '0.25px',
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        <PumpLogo /> {i18n.pump} {mat.reference}
                                                    </Grid>}
                                            >
                                                <Grid item>
                                                    <PumpCardDecla
                                                        pump={mat}
                                                        noLightCard
                                                        match={props.match}
                                                    />
                                                </Grid>
                                            </AccordeonDesktop>
                                        )
                                    }
                                    return (
                                        <AccordeonDesktop
                                            expanded={matExpanded === i ?? false}
                                            onChange={() => handleMatChange(i)}
                                            sx={{ width: '100%' }}
                                            title={
                                                <Grid
                                                    item
                                                    sx={{
                                                        fontSize: '18px',
                                                        lineHeight: '20px',
                                                        letterSpacing: '0.25px',
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    <CounterLogo /> {i18n.counter} {mat?.reference}
                                                </Grid>
                                            }
                                        >
                                            <Grid item>
                                                <CounterCardDecla
                                                    counter={mat}
                                                    noLightCard
                                                    match={props.match}
                                                />
                                            </Grid>
                                        </AccordeonDesktop>
                                    )
                                }))
                            }
                        </Grid>
                    </LightCard>
                    <LightCard
                        item
                        container
                        xs={12}
                        sx={{
                            padding: !nbTanks ? '3vh' : '3vh 3vh 0'
                        }}
                    >
                        <Grid item fontSize={22} className='bold' >
                            {`${getI18nTitleDataLength(i18n.waterRetention, i18n.waterRetentions, nbTanks)} (${nbTanks})`}
                        </Grid>
                        <Grid item xs={12}>
                            {updatedTanks?.map((retenue, i) => (
                                <AccordeonDesktop expanded={retExpanded === i ?? false}
                                    onChange={() => handleRetChange(i)}
                                    sx={{ width: '100%' }}
                                    title={
                                        <Grid
                                            item
                                            sx={{
                                                fontSize: '18px',
                                                lineHeight: '20px',
                                                letterSpacing: '0.25px',
                                                fontWeight: 400,
                                            }}
                                        >
                                            {i18n.waterRetention} {retenue.idTank}
                                        </Grid>}
                                >
                                    <Grid item>
                                        <TankCardDecla
                                            match={props.match}
                                            retenue={retenue}
                                            noLightCard
                                        />
                                    </Grid>
                                </AccordeonDesktop>
                            ))}
                        </Grid>
                    </LightCard>
                </Grid>
            </Grid>
            <Grid item container xs={8.375} alignContent='flex-start' sx={{ overflowY: 'auto', height: 'calc(100% - 8vh)', rowGap: '2vh' }}>
                <ConsoCardDecla {...props} />
                <PlannedUsesCardDecla {...props} />
            </Grid>
        </Grid>
    )
}

PointDetailsDeclaDesktop.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
}

export default PointDetailsDeclaDesktop