import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Icon, LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import { orderBy } from 'lodash'
import { CMS_PATH } from '../conf/basepath'
import { formatDate } from '../utils/DateUtil'
import CmsAction from './online/cms/actions/CmsAction'
import DtoUser from './online/account/dto/DtoUser'
import DtoCMSEvent from './online/cms/dto/DtoCMSEvent'
import { MainButton } from './online/components/styled/buttons'
import HomeAction from './offline/actions/HomeAction'
import { getMenuHeight } from '../utils/StyleUtils'
import { isOnline } from '../utils/StoreUtils'

const styles = () => ({
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '8px',
        boxShadow: 'none',
    },
    text: {
        fontSize: '16px',
        boxShadow: 'none',
        textAlign: 'justify',
        width: '100%',
        border: 'none',
        font: 'unset',
        resize: 'none',
        color: 'black',
        opacity: 'unset',
    },
})

class Actualities extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cmsLoaded: !isOnline(),
            actuToShow: [],
            actu: 0,
        }
    }

    componentDidMount() {
        this.props.fetchCmsActualities().then(() => {
            const { actualities, accountUser } = this.props
            const actuToShow = orderBy(actualities.filter((a) => a.status === 1 || accountUser.isAdmin === '1'), 'updateDate')
            this.setState({ cmsLoaded: true, actuToShow, actu: actuToShow.length ? actuToShow.length - 1 : 0 })
        })
        this.props.setTitle([
            {
                title: i18n.actualities,
                href: '/actualities',
            },
        ])
    }

    openDocument = (document) => {
        window.open(CMS_PATH + document.name, '_system')
    }

    getIcon = (status) => {
        switch (status) {
            case 2:
                return 'create'
            case 3:
                return 'folderOpen'
            default:
                return 'create'
        }
    }

    getPanel = () => {
        const { actu, actuToShow } = this.state
        const actuality = actuToShow[actu] || {}
        return (
            <Grid item className='overflowY'>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item className='padding-2'>
                        <span id='actu-title' style={{ fontSize: '1.5em' }}>{actuality.title}</span><br />
                        <span id='actu-description' className='italic' style={{ display: 'flex' }}>{formatDate(actuality.updateDate)}{actuality.status !== 1 && <Icon>{this.getIcon(actuality.status)}</Icon>}</span>
                    </Grid>
                    {!!actuality.document.length && (
                        <Icon
                            className='clickable'
                            onClick={() => this.openDocument(actuality.document[0])}
                            fontSize='large'
                        >
                            insert_drive_file
                        </Icon>
                    )}
                </Grid>
                {actuality.subtitle && (
                    <p id='actu-subtitle'>
                        {actuality.subtitle}
                    </p>
                )}
                <div style={{ backgroundColor: 'white', padding: 4, borderRadius: 10, border: '1px solid' }} dangerouslySetInnerHTML={{ __html: actuality.comment || '' }} />
                <p id='actu-footer' className='italic'>{actuality.footerContent}</p>
                {actuality.link && (
                    <p
                        className='blue-text'
                        onClick={() => window.open(actuality.link, '_system')}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {actuality.link}
                        <Icon>launch</Icon>
                    </p>
                )}
            </Grid>
        )
    }

    prevActu = () => {
        this.setState(({ actu }) => ({ actu: actu - 1 }))
    }

    nextActu = () => {
        this.setState(({ actu }) => ({ actu: actu + 1 }))
    }

    render() {
        const { accountUser } = this.props
        const { cmsLoaded } = this.state

        if (cmsLoaded && accountUser.login) {
            const { actu, actuToShow } = this.state
            return (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: 'calc(100% - 20px)', paddingBottom: 60 }}>
                            <Grid
                                container
                                className='overflowY padding-2'
                                direction='row'
                                alignItems='flex-start'
                                style={{
                                    display: 'block',
                                }}
                            >
                                {!!actuToShow.length && this.getPanel()}
                            </Grid>
                        </div>
                    </div>
                    {actuToShow.length > 1 && (
                        <Grid
                            container
                            direction='row'
                            justifyContent='space-around'
                            alignItems='center'
                            spacing={1}
                            style={{
                                position: 'fixed',
                                width: '100%',
                                bottom: getMenuHeight(),
                                height: 60,
                            }}
                        >
                            <Grid item style={{ padding: 0 }} xs={5}>
                                {!!actu && (
                                    <MainButton
                                        onClick={this.prevActu}
                                    >
                                        <Icon>keyboard_arrow_left</Icon>
                                        {i18n.previous}
                                    </MainButton>
                                )}
                            </Grid>
                            <Grid item style={{ padding: 0 }} xs={5}>
                                {actu !== actuToShow.length - 1 && (
                                    <MainButton
                                        onClick={this.nextActu}
                                    >
                                        {i18n.next}
                                        <Icon>keyboard_arrow_right</Icon>
                                    </MainButton>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </>
            )
        }
        return <LinearProgress />
    }
}

const mapStateToProps = (store) => ({
    accountUser: store.AccountReducer.accountUser,
    actualities: store.CmsReducer.actualities,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchCmsActualities: CmsAction.fetchCmsActualities,
}

Actualities.propTypes = {
    fetchCmsActualities: PropTypes.func,
    setTitle: PropTypes.func,
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    accountUser: PropTypes.instanceOf(DtoUser),
    actualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Actualities))
