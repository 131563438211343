import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../../utils/DateUtil'
import DtoCasingExploit from '../../agri/dto/exploitation/DtoCasingExploit'
import DtoCasingDeclaration from '../../agri/dto/enquete/DtoCasingDeclaration'
import { LightCard } from '../styled/grid'

const OuvrageCard = ({ title, ouvrage = {} }) => (
    <LightCard
        item
        container
        direction='column'
        justifyContent='space-between'
        className='clickable'
        sx={{ marginBottom: '20px' }}
    >
        <Grid item fontSize={22} className='bold' sx={{ marginBottom: '20px' }}>{title ||i18n.struct}</Grid>
        <Grid container direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.depth}</Grid>
                <Grid item className='bold'>{ouvrage?.depth || '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.receiptNum}</Grid>
                <Grid item className='bold'>{ouvrage?.receiptNumber || '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between' sx={{ marginBottom: '15px' }}>
                <Grid item>{i18n.receiptDate}</Grid>
                <Grid item className='bold'>{ouvrage?.receiptDate ? getDate(ouvrage.receiptDate) : '-'}</Grid>
            </Grid>
            <Grid item container justifyContent='space-between'>
                <Grid item>{i18n.reserveFlow}</Grid>
                <Grid item className='bold'>{ouvrage?.reserveFlow || '-'}</Grid>
            </Grid>
        </Grid>
    </LightCard>
)

OuvrageCard.propTypes = {
    title: PropTypes.string.isRequired,
    ouvrage: PropTypes.instanceOf(DtoCasingDeclaration || DtoCasingExploit).isRequired,
}

export default OuvrageCard
