import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, Grid, Icon } from '@mui/material'
import i18n from 'simple-react-i18n'
import { MainButton } from '../../../components/styled/buttons'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from '../../../components/styled/dialog'
import { ReactComponent as CloseLogo } from '../../../../../ressources/static/svg/Fermer.svg'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { push } from 'react-router-redux'

export default function ModalWarnNoConso({ open, toggleModal, onValidateDeclaration, instsWithoutIndex = [] }) {
    const {
        installations,
    } = useSelector(store => ({
        installations: store.InstallationsReducer.installations,
    }), shallowEqual)

    const dispatch = useDispatch()

    const handleClose = () => {
        onValidateDeclaration()
        toggleModal()
    }

    return (
        <Dialog
            open={open}
            maxWidth='md'
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <b style={{ padding: 10, fontSize: 21, display: 'flex', alignItems: 'center' }}><Icon className='padding-right-1'>warning</Icon>Attention !</b>
                    <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={toggleModal} />
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <p id='modal-text'>
                    Vous déclarez de(s) point(s) prélèvement "utilisé(s)" et/ou "ajourné(s)", sans avoir saisi la partie "Consommations" concernant l'année écoulée, volumes comme usages réels. Merci de bien vouloir vérifier à nouveau cette partie, avant de valider votre déclaration.
                </p>
                <Grid container alignItems='center' justifyContent='space-evenly'>
                    {instsWithoutIndex.map((id) => {
                        const inst = installations.find((i) => i.id === id)
                        return (
                            <>
                                <Grid item xs={7} className='bold'>{`${i18n.point} ${inst.code}${inst.name ? ` - ${inst.name}` : ''} - ${inst.location}  - ${inst.parcel} ${inst.section}`}</Grid>
                                <Grid item xs={4}>
                                    <MainButton reverse onClick={() => dispatch(push(`/enquete/point/${id}/usages/reel`))}>{i18n.edit}</MainButton>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
                <p id='modal-description' className='italic'>Pour tout renseignement, veuillez contacter votre administrateur.</p>
            </DialogContentMUI>
            <DialogActionsMUI>
                <MainButton onClick={handleClose}>
                    Ignorer et valider ma déclaration
                </MainButton>
            </DialogActionsMUI>
        </Dialog>
    )
}

ModalWarnNoConso.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onValidateDeclaration: PropTypes.func.isRequired,
    instsWithoutIndex: PropTypes.arrayOf(PropTypes.number),
}
