import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Dialog, Grid } from '@mui/material'
import { orderBy, compact } from 'lodash'
import AgriAction from '../agri/actions/AgriAction'
import FileAction from '../referencials/documents/actions/FileAction'
import { DialogContentMUI, DialogTitleMUI } from '../components/styled/dialog'
import { MainButton } from '../components/styled/buttons'
import { mainColor, veryLightColor } from '../components/styled/theme'
import { ReactComponent as DownloadLogo } from '../../../ressources/static/svg/Download.svg'
import { ReactComponent as CloseLogo } from '../../../ressources/static/svg/Fermer.svg'
import { sieauTooltip } from '../../../utils/FormUtils'
import { getFileNatureLabel, getTypeSandreCode } from '../../../utils/FileUtils'
import { getSandreList } from '../../../utils/StoreUtils'
import LoadingCard from '../components/cards/LoadingCard'
import { EXPLOITATION_STATION_TYPE } from '../agri/constants/AgriConstants'

const MyDocuments = () => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [openModalExport, setOpenModalExport] = useState(false)
    const [idDeclaration, setIdDeclaration] = useState(null)
    const [year, setYear] = useState(null)
    const [title, setTitle] = useState(null)
    const [exportNotifications, setExportNotifications] = useState(false)

    const {
        accountUser,
        exploitation,
        declarationsExploitation,
        surveys,
        codesSandre,
        pictures,
        files,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        exploitation: store.AgriReducer.exploitation,
        declarationsExploitation: store.AgriReducer.declarationsExploitation,
        surveys: store.AgriReducer.surveys,
        codesSandre: store.ReferencialReducer.codesSandre,
        pictures: store.FileReducer.pictures,
        files: store.FileReducer.files
    }), shallowEqual)

    const dispatch = useDispatch()

    const getDeclarationsAndDocs = () => {
        dispatch(FileAction.fetchPictures(exploitation.codification.replaceAll(' ', ''), EXPLOITATION_STATION_TYPE))
        dispatch(FileAction.fetchFiles(exploitation.codification.replaceAll(' ', ''), EXPLOITATION_STATION_TYPE))
        dispatch(AgriAction.fetchDeclarationsByExploitationId(exploitation.idExploitation)).then(() => {
            setDataLoaded(true)
        })
    }

    useEffect(() => {
        if (exploitation.idExploitation) {
            getDeclarationsAndDocs(exploitation)
        } else {
            dispatch(AgriAction.fetchExploitation()).then(() => {
                getDeclarationsAndDocs(exploitation)
                dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation))
            })
        }
    }, [])

    const showDeclarationItem = (documentsAndDeclaration) => {
        return compact(documentsAndDeclaration.map((d, i) => {
            const surveyDeclaration = surveys.find((s) => s.idSurvey === d.idSurvey) || {}
            if (([1, 3].includes(surveyDeclaration.statusCode) && (d.statusCode >= 3)) || accountUser.isAdmin === '1') {
                const docTitle = d.doc ? d.shortName : `${i18n.declaration} ${surveyDeclaration.year}`
                return (
                    <Grid
                        key={d.idDeclaration}
                        item
                        container
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{ backgroundColor: i % 2 ? veryLightColor: 'white', padding: '16px 8px' }}
                    >
                        <Grid item xs={11} container direction='column'>
                            {d.doc ? docTitle : (
                                <>
                                    <Grid item sx={{ paddingBottom: '8px' }}>{docTitle}</Grid>
                                    <Grid item fontSize={12}>{surveyDeclaration.name}</Grid>
                                </>
                            )}
                        </Grid>
                        <DownloadLogo
                            className='clickable'
                            {...sieauTooltip(i18n.download)}
                            style={{ height: 24 }}
                            onClick={() => {
                                if (d.doc) {
                                    window.open(d.url, '_system')
                                } else {
                                    setOpenModalExport(true)
                                    setIdDeclaration(d.idDeclaration)
                                    setYear(surveyDeclaration.year)
                                    setExportNotifications(surveyDeclaration.exportNotifications)
                                    setTitle(docTitle)
                                }
                            }}
                            fill={mainColor}
                        />
                    </Grid>
                )
            }
            return null
        }))
    }

    const getModalExport = () => {
        return (
            <Dialog open={openModalExport}>
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <b style={{ padding: 10, fontSize: 21 }}>{title}</b>
                    <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={() => setOpenModalExport(false) && setTitle() && setIdDeclaration() && setYear()} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <MainButton
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                        onClick={() => dispatch(AgriAction.exportDeclarationFull(idDeclaration))}
                    >
                        {i18n.exportPDFSynthesis}
                        <DownloadLogo fill='white' style={{ paddingLeft: 10, height: 25 }} />
                    </MainButton>
                    {exportNotifications && (
                        <MainButton
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                            onClick={() => dispatch(AgriAction.getNotifEdition(exploitation.idExploitation, year))}
                        >
                            {i18n.exportPDFAttribution}
                            <DownloadLogo fill='white' style={{ paddingLeft: 10, height: 25 }} />
                        </MainButton>
                    )}
                </DialogContentMUI>
            </Dialog>
        )
    }

    const documents = [...pictures, ...files].filter((d) => d.active === '1').map(fp => {
        return { ...fp, type: getFileNatureLabel(fp.name, getSandreList(codesSandre, getTypeSandreCode(fp.fileType))), doc: true, updateDate: fp.date }
    })
    const documentsAndDeclaration = orderBy([...documents, ...declarationsExploitation], 'updateDate', 'desc')

    if (!dataLoaded) {
        return <LoadingCard />
    }
    return (
        <Grid
            container
            alignItems='stretch'
            justifyContent='flex-start'
            direction='column'
            sx={{ padding: '0 1.5rem 8px 1.5rem', color: mainColor }}
        >
            <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='flex-start'
                sx={{ marginBottom: '15px', color: mainColor }}
            >
                <Grid
                    item
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ padding: '16px 8px', borderBottom: `1px solid ${mainColor}` }}
                    fontSize={14}
                    className='bold'
                >
                    <Grid item>{i18n.documentName}</Grid>
                    <Grid item>{i18n.download}</Grid>
                </Grid>
                {documentsAndDeclaration.length ? showDeclarationItem(documentsAndDeclaration) : (
                    <Grid item fontSize={16} sx={{ paddingTop: '20px' }}>
                        {i18n.noDocument}
                    </Grid>
                )}
            </Grid>
            {openModalExport && getModalExport()}
        </Grid>
    )
}

export default MyDocuments
