import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid, Icon, styled, Divider } from '@mui/material'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import { formatPhone, formatSiret } from '../../../utils/StringUtil'
import ContactAction from '../contact/actions/ContactAction'
import DtoContact from '../referencials/dto/DtoContact'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { MainButton } from '../components/styled/buttons'
import DtoUser from '../account/dto/DtoUser'
import { hasValue } from '../../../utils/NumberUtil'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import { mainColor, veryLightColor } from '../components/styled/theme'
import StyledCard from '../components/cards/StyledCard'
import { compact, isEmpty } from 'lodash'
import { orderPointsList } from '../../../utils/ObjectUtils'
import { getI18nTitleDataLength } from '../../../utils/StringUtil'
import LoadingCard from '../components/cards/LoadingCard'
import { FolderOutlined } from '@mui/icons-material'
import MyDocuments from '../documents/MyDocuments'
import HomeAction from '../../offline/actions/HomeAction'
import { getStateLabel } from '../../../utils/AgriUtils'
import { LightCard } from '../components/styled/grid'

const IconRow = styled(Icon)({
    marginRight: '5px',
})

const Container = styled(Grid)({
    margin: '8 8',
})

class MyExploitation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            points: [],
            dataLoaded: false,
            pointsLoaded: false,
            preleveur: new DtoIntervenant({}),
            contactsExploitation: [],
        }
    }

    componentDidMount() {
        const { exploitation } = this.props
        this.props.setTitle([{
            title: i18n.folder,
            href: '/exploitation'
        }])
        if (exploitation?.idExploitation) {
            this.getData()
        } else {
            this.props.fetchExploitation().then(() => this.getData())
        }
    }

    getData = () => {
        const { exploitation, installations, contacts, contributors } = this.props
        if (hasValue(exploitation?.idExploitation)) {
            this.props.fetchDeclarationByExploitationId(exploitation.idExploitation)
            if (!installations.length) {
                this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
                    this.setPoints(exploitation)
                })
            } else {
                this.setPoints(exploitation)
            }
            if (!contacts.length) {
                this.props.fetchContacts().then(() => {
                    this.setContacts(exploitation)
                })
            } else {
                this.setContacts(exploitation)
            }
            if (!contributors.length) {
                this.props.fetchContributors().then(() => {
                    this.setPreleveur(exploitation)
                })
            } else {
                this.setPreleveur(exploitation)
            }
        } else {
            this.setState({ dataLoaded: true })
        }
    }

    setPreleveur = (exploitation) => {
        const { contributors } = this.props
        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode)
        this.setState({
            preleveur: preleveur ? preleveur : new DtoIntervenant({}),
            dataLoaded: true,
        })
    }

    setPoints = (exploitation) => {
        const { installations, citiesIndex } = this.props
        const points = orderPointsList(compact(exploitation.link_samplings.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                return {
                    ...point,
                    cityName: city.name,
                    stateCode: linkPoint.stateCode,
                    stateLabel: getStateLabel(linkPoint.stateCode),
                }
            }
            return null
        })))
        this.setState({
            points,
            pointsLoaded: true
        })
    }

    setContacts = (exploitation) => {
        const { contacts } = this.props
        const contactsExploitation = []
        exploitation.link_contributors.forEach((c) => {
            const contributorFind = contacts.find(
                (contact) => contact.id === c.contactCode,
            )
            if (contributorFind) {
                contactsExploitation.push({
                    ...contributorFind,
                    contributorType: c.contributorType,
                })
            }
        })
        this.setState({ contactsExploitation })
    }

    getListContacts = () => {
        const { contactsExploitation, preleveur } = this.state
        const { codesSandre, contacts } = this.props
        const typesContributors = [{ code: -1, name: i18n.legalRepresentative }, ...codesSandre.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT')]
        let allContacts = [...contactsExploitation]
        if (preleveur) {
            const legalRepresentative = contacts.find((c) => c.id === preleveur.legalRepresentative)
            if (legalRepresentative && !allContacts.find((c) => c.contributorType === -1 || c.id === legalRepresentative.id)) {
                allContacts.unshift({
                    ...legalRepresentative,
                    contributorType: -1,
                })
            }
        }
        return allContacts.map((c) => {
            const type = typesContributors.find((t) => t.code === c.contributorType)
            const city = this.getCity()
            return (
                <Grid item xs={12} md={3} style={{ padding: '0 10 10 10' }}>
                    <StyledCard subTitle={type ? type.name : 'Inconnu'} styleContainer={{ padding: 0, borderTop: '1px solid black' }} styleContent={{ padding: 0 }}>
                        {isEmpty(c) ? (
                            <p>{i18n.noInformation}</p>
                        ) : (
                            <>
                                <Container>{c.name}</Container>
                                <Container>{c.address}</Container>
                                <Container>{c.addressComplement}</Container>
                                <Container>{`${c.postalCode || ''} ${city && city.name ? city.name || '' : c.city || ''}`}</Container>
                                <Divider style={{ borderColor: 'black' }} />
                                {c.phoneTel && (
                                    <Container container alignItems='center'>
                                        <IconRow>phone</IconRow>
                                        <span>{c.phoneTel ? formatPhone(c.phoneTel) : ''}</span>
                                    </Container>
                                )}
                                {c.mobile && (
                                    <Container container alignItems='center'>
                                        <IconRow>smartphone</IconRow>
                                        <span>{c.mobile ? formatPhone(c.mobile) : ''}</span>
                                    </Container>
                                )}
                                {c.desktopTel && (
                                    <Container container alignItems='center'>
                                        <IconRow>print</IconRow>
                                        <span>{c.desktopTel ? formatPhone(c.desktopTel) : ''}</span>
                                    </Container>
                                )}
                                {c.email && (
                                    <Container container alignItems='center'>
                                        <IconRow>mail_outlined</IconRow>
                                        <span>{c.email}</span>
                                    </Container>
                                )}
                            </>
                        )}
                    </StyledCard>
                </Grid>
            )
        })
    }

    getCity = () => {
        const { citiesIndex } = this.props
        const { intervenant } = this.state
        const city = citiesIndex[intervenant?.cityCode]
        return city || {}
    }

    render() {
        const { dataLoaded, pointsLoaded, preleveur, points } = this.state
        const { accountUser, exploitation } = this.props

        if (!dataLoaded) {
            return <LoadingCard />
        }
        const city = this.getCity()
        return (
            <Grid
                container
                alignItems='stretch'
                justifyContent='flex-start'
                direction='column'
                sx={{ padding: '0 1.5rem 8px 1.5rem', color: mainColor }}
            >
                {(accountUser.isAdmin === '1' || accountUser.metadata === '1') && (
                    <MainButton onClick={() => this.props.push('/admin')} startIcon={<FolderOutlined />} sx={{ marginBottom: '16px' }}>
                        {i18n.selectFolder}
                    </MainButton>
                )}
                {hasValue(exploitation?.idExploitation) && (
                    <>
                        <LightCard item container direction='column' sx={{ marginBottom: '16px' }}>
                            <Grid item fontSize={24} sx={{ marginBottom: '16px' }}>{i18n.samplerInfos}</Grid>
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                justifyContent='space-between'
                                spacing={2}
                                fontSize={14}
                            >
                                <Grid item xs={3.5}>{i18n.id}</Grid>
                                <Grid item xs={8.5} className='bold'>{preleveur.internalIdentifier || preleveur.name}</Grid>
                                <Grid item xs={3.5}>{i18n.siret}</Grid>
                                <Grid item xs={8.5} className='bold'>{formatSiret(preleveur.siret)}</Grid>
                                <Grid item xs={3.5}>{i18n.address}</Grid>
                                <Grid item xs={8.5} className='bold'>{`${preleveur?.road ?? ''} ${preleveur?.addressComplement ?? ''}, ${preleveur?.postalCode ?? ''} ${city?.name ?? ''}`}</Grid>
                                <Grid item xs={3.5}>{i18n.courriel}</Grid>
                                <Grid item xs={8.5} className='bold'>{preleveur.email}</Grid>
                                <Grid item xs={3.5}>{i18n.phoneTel}</Grid>
                                <Grid item xs={8.5} className='bold'>{formatPhone(preleveur.phoneTel)}</Grid>
                            </Grid>
                        </LightCard>
                        <MyDocuments />
                        {pointsLoaded && (
                            <LightCard item container direction='column' sx={{ marginBottom: '16px' }}>
                                <Grid item fontSize={24} sx={{ padding: '16px 16px 8px 0' }}>{i18n.pointsPrelevement}</Grid>
                                <Grid item fontSize={14} sx={{ padding: '0 16px 16px 0' }}>{`${points.length} ${getI18nTitleDataLength(i18n.pointPrelevement, i18n.pointsPrelevement, points.length)}`}</Grid>
                                <Grid
                                    item
                                    container
                                    justifyContent='space-between'
                                    alignItems='center'
                                    sx={{ padding: '16px 8px', borderBottom: `1px solid ${mainColor}` }}
                                    fontSize={14}
                                    className='bold'
                                >
                                    <Grid item xs={9}>{i18n.pointName}</Grid>
                                    <Grid item xs={3} container justifyContent='center'>{i18n.status}</Grid>
                                </Grid>
                                {points.map((p, i) => (
                                    <Grid
                                        key={p.id}
                                        item
                                        container
                                        justifyContent='space-between'
                                        alignItems='center'
                                        sx={{ backgroundColor: i % 2 ? veryLightColor: 'white', padding: '16px 8px' }}
                                        onClick={() => this.props.push(`/exploitation/point/${p.id}`)}
                                    >
                                        <Grid item xs={9}>{p.name || `${i18n.point} ${p.code}`}</Grid>
                                        <Grid item xs={3} container justifyContent='center'>{p.stateLabel}</Grid>
                                    </Grid>
                                ))}
                            </LightCard>
                        )}
                    </>
                )}
            </Grid>
        )
    }
}

MyExploitation.propTypes = {
    fetchContact: PropTypes.func,
    fetchContacts: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    setTitle: PropTypes.func,
    push: PropTypes.func,
    operator: PropTypes.instanceOf(DtoIntervenant),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    accountUser: PropTypes.instanceOf(DtoUser),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
}

const mapStateToProps = (store) => {
    return {
        contacts: store.ContactReducer.contacts,
        operator: store.AgriReducer.operator,
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        codesSandre: store.ReferencialReducer.codesSandre,
        contributors: store.ReferencialReducer.contributors,
        citiesIndex: store.CityReducer.citiesIndex,
        accountUser: store.AccountReducer.accountUser,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
    }
}

const mapDispatchToProps = {
    fetchContacts: ContactAction.fetchContacts,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    fetchContributors: ReferencialAction.fetchContributors,
    setTitle: HomeAction.setTitle,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyExploitation)
