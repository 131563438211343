/* eslint-disable indent */
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import React, { forwardRef } from 'react'
import { darkTextColor, mainColor, textColor, veryLightColor } from './theme'
import { Close } from '@mui/icons-material'

const Transition = forwardRef((props, ref) => {
    return <Slide direction='up' ref={ref} {...props} />
})

export const DialogMUI = styled(({ PaperProps, ...props }) => (
    <Dialog
        fullScreen={useMediaQuery(useTheme().breakpoints.down('md'))}
        TransitionComponent={Transition}
        PaperProps= {{
            style: {
                maxHeight: '70vh',
                minWidth: '25vw',
                padding: '2.5vh 2.5vw',
                justifyContent: 'center',
                alignItems: 'center',
                border: `solid 1px ${mainColor}`,
                backgroundColor: veryLightColor,
                borderRadius: '12px',
                boxShadow: 'none',
                rowGap: '1.5vh',
                ...PaperProps?.style,
            }
        }}
        {...props}
    />
))({})

export const DialogTitleMUI = styled((other) => (
    <DialogTitle
        {...other}
    />))(() => ({
        paddingBottom: '8px',
        fontSize: '22px',
        lineHeight: '28px',
        color: darkTextColor
    }))


export const DialogTitleMUIv2 = styled(({ children, onClick, ...other }) => (
    <DialogTitle
        {...other}
    >
        <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>{children}</Grid>
            <Close item className='clickable' onClick={onClick} />
        </Grid>
    </DialogTitle>))(() => ({
        width: '100%',
        padding: '0',
        fontSize: '22px',
        lineHeight: '28px',
        color: mainColor,
        fontWeight: 'regular',
        backgroundColor: 'transparent',
    }))

export const DialogContentMUI = styled((other) => (
    <DialogContent
        {...other}
    />))({
        width: '100%',
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        gap: '1vh',
        alignItems: 'center',
        justifyContent: 'center'
    })

    export const DialogContentMUIDesktop = styled((other) => (
        <DialogContent
            {...other}
        />))({
            width: '100%',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
            rowGap: '1rem'
        })

export const DialogContentTextMUI = styled((other) => (
    <DialogContent
        {...other}
    />))({
        fontSize: '14px',
        lineHeight: '20px',
        lineSpacing: '0.25px',
        color: textColor,
        padding: 0,
    })

export const DialogActionsMUI = styled((other) => (
    <DialogActions
        {...other}
    />))({
        fontWeight: 'bold',
        color: mainColor,
        padding: '0px 24px 24px 16px',
    })