module.exports = {
    FORCE_DISCONNECTED: 'FORCE_DISCONNECTED',
    ADD_JOURNAL: 'ADD_JOURNAL',
    UPDATE_JOURNAL: 'UPDATE_JOURNAL',
    JOURNAL_SYNC: 'JOURNAL_SYNC',
    REMOVE_JOURNAL: 'REMOVE_JOURNAL',
    REMOVE_ALL_JOURNAL: 'REMOVE_ALL_JOURNAL',
    ADD_METADATA: 'ADD_METADATA',
    CLEAR_METADATA: 'CLEAR_METADATA',
    DELAYED: 'delayed',
    whitelist: [
        'AgriReducer',
        'AccountReducer',
        'ContactReducer',
        'CmsReducer',
        'HomeReducer',
        'MaterielReducer',
        'ReferencialReducer',
        'InstallationsReducer',
        'OfflineReducer',
    ],
    // Offline Action Type

    UPDATE_EXPLOITATION: 'UPDATE_EXPLOITATION',
    CREATE_MAT_EVENT: 'CREATE_MAT_EVENT',
}
