import { useEffect, useState } from 'react'
import OfflineAction from '../../pages/offline/actions/OfflineAction'
import { useDispatch, useSelector } from 'react-redux'

const useOnline = () => {
    const [online, setOnline] = useState(typeof window !== 'undefined' ? window.navigator.onLine : false)

    const { offlineMode } = useSelector(store => ({
        offlineMode: store.OfflineReducer.offlineMode
    }))

    const dispatch = useDispatch()
    useEffect(() => {
    // create event handler
        const handleStatusChange = () => {
            setOnline(navigator.onLine)
            dispatch(OfflineAction.setForceDisconnected(!navigator.onLine))
        }

        // listen for online and ofline event
        window.addEventListener('online', handleStatusChange)
        window.addEventListener('offline', handleStatusChange)

        // clean up to avoid memory-leak
        return () => {
            window.removeEventListener('online', handleStatusChange)
            window.removeEventListener('offline', handleStatusChange)
        }
    }, [])

    useEffect(() => {
        if (offlineMode === online) {
            dispatch(OfflineAction.setForceDisconnected(!navigator.onLine))
        }
    }, [])
    return online
}

export default useOnline