import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid } from '@mui/material'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import { formatPhone, formatSiret } from '../../../utils/StringUtil'
import ContactAction from '../contact/actions/ContactAction'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { MainButton } from '../components/styled/buttons'
import { hasValue } from '../../../utils/NumberUtil'
import { darkTextColor, textColor } from '../components/styled/theme'
import { compact } from 'lodash'
import { orderPointsList } from '../../../utils/ObjectUtils'
import { getI18nTitleDataLength } from '../../../utils/StringUtil'
import LoadingCard from '../components/cards/LoadingCard'
import { Circle, FolderOutlined } from '@mui/icons-material'
import { getStateLabel } from '../../../utils/AgriUtils'
import { LightCard } from '../components/styled/grid'
import { push } from 'connected-react-router'
import Table from '../components/Table'
import { POINT_STATUS } from '../referencials/installations/constants/InstallationsConstants'
import useProgressDispatch from '../../../utils/customHooks/useProgressDispatch'

const MyExploitationDesktop = () => {
    const {
        contacts,
        exploitation,
        installations,
        contributors,
        citiesIndex,
        accountUser,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        contributors: store.ReferencialReducer.contributors,
        citiesIndex: store.CityReducer.citiesIndex,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const dispatch = useDispatch()

    const { isLoaded } = useProgressDispatch(() => {
        const promiseDeclarationByExploitationId = hasValue(exploitation?.idExploitation ? [
            dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation))
        ] : [])

        const promiseContacts = !contacts.length ? [
            dispatch(ContactAction.fetchContacts())
        ] : []

        const promiseContributors = !contributors.length ? [
            dispatch(ReferencialAction.fetchContributors())
        ] : []

        return [
            dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation)),
            ...promiseDeclarationByExploitationId,
            ...promiseContacts,
            ...promiseContributors
        ]
    })

    const points = useMemo(() => (
        orderPointsList(compact(exploitation?.link_samplings?.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                return ({
                    ...point,
                    cityName: city.name,
                    stateCode: linkPoint.stateCode,
                    stateLabel: getStateLabel(linkPoint.stateCode),
                })
            }
            return null
        })))
    ), [exploitation, installations, citiesIndex])

    /* const contactsExploitation = useMemo(() => (
        exploitation.link_contributors.map((c) => {
            const contributorFind = contacts.find(
                (contact) => contact.id === c.contactCode,
            )
            if (contributorFind) {
                return {
                        ...contributorFind,
                        contributorType: c.contributorType,
                    }
            }
            return null
        })
    ), [exploitation, contacts]) */

    const preleveur = useMemo(() => (
        contributors.find((c) => c.id === exploitation.operatorCode) || new DtoIntervenant({})
    ), [contributors, exploitation])

    if (!isLoaded || Object.keys(citiesIndex).length === 0) {
        return <LoadingCard />
    }

    const getStatusColor = (point) => (
        POINT_STATUS.find(status => status.code === point.stateCode)?.color
    )

    const getRows = () => (
        points.map((point) => ({
            [i18n.pointName]: { value: point.name },
            [i18n.codification]: { value: point.code },
            [i18n.location]: { value: `${point?.cityName ? `${point.cityName}, ` : ''}${`${point.townCode}` ?? ''}` },
            [i18n.status]: { value: <Circle sx={{ color: getStatusColor(point), fontSize: '20px' }}/> },
            onClickValue: point
        }))
    )

    return (
        <Grid
            container
            justifyContent='flex-start'
            rowGap='4.5vh'
            sx={{
                padding: '5vh',
                maxHeight: 'calc(100% - 4vh)',
                overflowY: 'hidden',
                width: '100%',
                color: textColor,
            }}
        >
            <Grid item xs={12} container alignItems='center' flexWrap='nowrap' justifyContent='space-between' >
                <Grid item sx={{ fontSize: '22px', lineHeight: '28px' }}>{i18n.folder}</Grid>
                {(accountUser.isAdmin === '1' || accountUser.metadata === '1') && (
                    <MainButton
                        noFullWidth
                        onClick={() => dispatch(push('/admin'))}
                        startIcon={<FolderOutlined sx={{ fontSize: '16px' }} />}
                        sx={{
                            margin: '0',
                            minHeight: '28px',
                            width: 'auto',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                    >
                        {i18n.selectFolder}
                    </MainButton>
                )}
            </Grid>

            {hasValue(exploitation?.idExploitation) && (
                <Grid item container rowGap='1.5vh' sx={{ overflowY: 'hidden', height: '100%' }}>
                    <LightCard item xs={6} container sx={{ height: 'auto' }}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                fontSize: '22px',
                                lineHeight: '28px',
                                color: darkTextColor,
                                paddingBottom: '16px'
                            }}
                        >
                            {i18n.samplerInfos}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                            rowGap='8px'
                            fontSize={14}
                        >
                            <Grid item xs={3.5}>{i18n.id}</Grid>
                            <Grid item xs={8.5} sx={{ fontWeight: 'medium' }}>{preleveur.internalIdentifier || preleveur.name}</Grid>
                            <Grid item xs={3.5}>{i18n.siret}</Grid>
                            <Grid item xs={8.5} sx={{ fontWeight: 'medium' }}>{formatSiret(preleveur.siret)}</Grid>
                            <Grid item xs={3.5}>{i18n.address}</Grid>
                            <Grid item xs={8.5} sx={{ fontWeight: 'medium' }}>{`${preleveur?.road ?? ''} ${preleveur?.addressComplement ?? ''}, ${preleveur?.postalCode ?? ''} `}</Grid>{/* ${city?.name ?? ''} */}
                            <Grid item xs={3.5}>{i18n.courriel}</Grid>
                            <Grid item xs={8.5} sx={{ fontWeight: 'medium' }}>{preleveur.email}</Grid>
                            <Grid item xs={3.5}>{i18n.phoneFixe}</Grid>
                            <Grid item xs={8.5} sx={{ fontWeight: 'medium' }}>{formatPhone(preleveur.phoneTel)}</Grid>
                            <Grid item xs={3.5}>{i18n.phoneMobile}</Grid>
                            <Grid item xs={8.5} sx={{ fontWeight: 'medium' }}>{formatPhone(preleveur.mobile)}</Grid>
                        </Grid>
                    </LightCard>
                    {points.length > 0 && (
                        <LightCard item xs={12} container rowGap='3vh' alignContent='flex-start' sx={{ overflowY: 'hidden', height: 'calc(100% - 34vh)' }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    fontSize: '22px',
                                    lineHeight: '28px',
                                    color: textColor,
                                }}
                            >
                                {`${points.length} ${getI18nTitleDataLength(i18n.pointPrelevement, i18n.pointsPrelevement, points.length)}`}
                            </Grid>
                            <Table
                                rowClickable
                                onClickRow={(row) => {
                                    dispatch(push(`/dossier/point/${row.onClickValue.id}`))
                                }}
                                rows={getRows()}
                                headers={[[
                                    { value: i18n.pointName, colSpan: 1 },
                                    { value: i18n.codification, colSpan: 1 },
                                    { value: i18n.location, colSpan: 1 },
                                    { value: i18n.status, colSpan: 1 },
                                ]]}
                                headersLabel={[
                                    i18n.pointName,
                                    i18n.codification,
                                    i18n.location,
                                    i18n.status,
                                ]}
                                sx={{ overflowY: 'auto', height: '100%' }}
                            />
                        </LightCard>
                    )}
                </Grid>
            )}
        </Grid>
    )
}

export default MyExploitationDesktop