import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import NavBar from './NavBar'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { hasValue } from '../../../utils/NumberUtil'
import { mainColor, menuColor } from './styled/theme'
import { ReactComponent as PointsLogo } from '../../../ressources/static/svg/Niveau7.svg'
import { compact, orderBy } from 'lodash'
import DtoUser from '../account/dto/DtoUser'
import DtoAccountStatistic from '../account/dto/DtoAccountStatistic'
import { StyledBadge } from './styled/badges'
import { LAST_TIME_HOME } from '../../offline/constants/HomeConstants'
import { isIOS } from 'react-device-detect'
import { Assignment, AttachFile, Home, Mail } from '@mui/icons-material'
import { getMenuHeight } from '../../../utils/StyleUtils'
import useOnline from '../../../utils/customHooks/useOnline'

const menuItemStyle = { padding: isIOS ? '5px 5px 10px 5px' : '10px 5px', color: mainColor }
const iconStyle = { height: '32px', width: '100%', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }
const labelStyle = { fontSize: '12px', height: '25px', paddingTop: '5px', fontWeight: 'bold' }

const App = ({}) => {
    const {
        exploitation,
        accountUser,
        actualities,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        accountUser: store.AccountReducer.accountUser,
        actualities: store.CmsReducer.actualities,
    }), shallowEqual)

    const dispatch = useDispatch()

    const navigate = (path) => {
        dispatch(push(path))
        window.scrollTo(0, 0)
    }

    const href = useMemo(() => window.location.href)

    // useEffect(() => {
    //     if (href.split('#')[1] === '/') {
    //         navigate('/home')
    //     }
    // }, [href])

    const isActive = (path) => {
        return href.split('#')[1].startsWith(path)
    }

    const hasExploit = hasValue(exploitation?.idExploitation)
    // const orderedStats = orderBy(accountStatistics.filter((s) => s.eventType === 'LOGIN'), 'statisticDate', 'desc')
    // const lastConnection = orderedStats.length >= 2 ? orderedStats[1] : orderedStats[0]
    const lastTimeHome = Number(localStorage.getItem(LAST_TIME_HOME))
    const actuNotSeen = compact(actualities.map((c) => {
        if (lastTimeHome < c.updateDate) {
            if (c.status === 1 || accountUser.isAdmin === '1') {
                return c
            }
        }
        return null
    }))
    const actuToShow = orderBy(actuNotSeen, 'updateDate')

    useOnline()

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <NavBar />
            <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='flex-start'
                style={{
                    zIndex: '1000',
                    position: 'fixed',
                    bottom: '0',
                    height: getMenuHeight(),
                    backgroundColor: 'white',
                    padding: '0 10',
                }}
            >
                <Grid item xs={12/5} container direction='column' alignItems='center' className='clickable' sx={menuItemStyle} onClick={() => navigate('/home')}>
                    <Grid item sx={{ ...iconStyle, backgroundColor: isActive('/home') ? menuColor : 'white' }}>
                        <StyledBadge badgeContent={actuToShow.length} badgeColor={mainColor}>
                            <Home style={{ fontSize: 30 }} />
                        </StyledBadge>
                    </Grid>
                    <Grid item sx={labelStyle}>
                        {i18n.home}
                    </Grid>
                </Grid>
                <Grid item xs={12/5} container direction='column' alignItems='center' className='clickable' sx={menuItemStyle} onClick={hasExploit ? () => navigate('/consommation') : () => {}}>
                    <Grid item sx={{ ...iconStyle, backgroundColor: isActive('/consommation') ? menuColor : 'white' }}>
                        <PointsLogo fill={hasExploit ? mainColor : 'grey'} style={{ height: 25 }} />
                    </Grid>
                    <Grid item sx={labelStyle}>
                        {i18n.installations}
                    </Grid>
                </Grid>
                <Grid item xs={12/5} container direction='column' alignItems='center' className='clickable' sx={menuItemStyle} onClick={hasExploit ? () => navigate('/index') : () => {}}>
                    <Grid item sx={{ ...iconStyle, backgroundColor: isActive('/index') ? menuColor : 'white' }}>
                        <Assignment style={{ color: hasExploit ? mainColor : 'grey', fontSize: 30 }} />
                    </Grid>
                    <Grid item sx={labelStyle}>
                        {i18n.index}
                    </Grid>
                </Grid>
                <Grid item xs={12/5} container direction='column' alignItems='center' className='clickable' sx={menuItemStyle} onClick={() => navigate('/contact')}>
                    <Grid item sx={{ ...iconStyle, backgroundColor: isActive('/contact') ? menuColor : 'white' }}>
                        <StyledBadge badgeContent={null} badgeColor={isActive('/contact') ? 'white' : mainColor}>
                            <Mail style={{ fontSize: 25 }} />
                        </StyledBadge>
                    </Grid>
                    <Grid item sx={labelStyle}>
                        {i18n.messaging}
                    </Grid>
                </Grid>
                <Grid item xs={12/5} container direction='column' alignItems='center' className='clickable' sx={menuItemStyle} onClick={() => navigate('/documents')}>
                    <Grid item sx={{ ...iconStyle, backgroundColor: isActive('/documents') ? menuColor : 'white' }}>
                        <AttachFile style={{ fontSize: 30 }} />
                    </Grid>
                    <Grid item sx={labelStyle}>
                        {i18n.documents}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

App.propTypes = {
    exploitation: PropTypes.instanceOf(DtoExploitation),
    accountUser: PropTypes.instanceOf(DtoUser),
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStatistic)),
    actualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    match: PropTypes.shape({}),
}

export default App
